import { css } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import { Text } from 'rebass';

import {
  space,
  colors,
  fontStacks,
  fontSizes,
  borderRadius,
  textStyles
} from 'utils/rebass-theme';

export const ActiveParent: any = css`
  color: ${colors.floomBlue};
`;

export const Accordion: any = styled.ul`
  overflow: hidden;
  list-style: none;
  height: ${({ isSectionOpen }: any): string => isSectionOpen ? 'auto' : '0'};
`;

export const AccordionItem: any = styled.li`
  margin-bottom: ${space[1]}px;
`;

export const NavLink = (props: any): any => css`
  line-height: 1.5;
  font-size: 14px;
  font-family: ${fontStacks.secondary};
  font-weight: 700;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-bottom: ${space[1]}px;
  text-decoration: none;
  border-radius: ${borderRadius.medium};
  transition: background .2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};

  :hover {
    background: ${props.isDisabled ? 'none' : colors.paleGrey};
  }
`;

export const NavLinkParent: StyledComponent<any, any, any> = styled.a`
  ${(props: any): any => NavLink(props)};
`;

export const NavLinkTextWrapper: StyledComponent<any, any, any> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NavLinkText: StyledComponent<any, any, any> = styled.p`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  line-height: 1.1;
  bottom: 2px;
  flow-grow: 2;
`;

export const NavLinkLabel: StyledComponent<any, any, any> = styled.div`
  ${textStyles.label};
  text-transform: uppercase;
  background: ${colors.floomMidnightBlue};
  color: ${colors.uiFills};
  margin-left: 15px;
  padding: 5px 8px;
  border-radius: ${borderRadius.large};
`;

export const NavLinkChild: StyledComponent<any, any, any> = styled(Text)`
  ${(props: any): any => NavLink(props)};
  padding: 5px;
  text-indent: 55px;
  font-weight: 400;
  color: ${(props: any): any => props.isDisabled ? colors.middleGrey : colors.floomMidnightBlue};
`;

export const NavLinkNotification: StyledComponent<any, any, any> = styled.div`
  background: ${(props: any): any => {
    switch (props.type) {
      case 'info':
        return colors.floomBlue;

      case 'warning':
        return colors.floomRed;

      default:
        return colors.floomBlue;
    }
  }
};
  margin-right: 12px;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
`;

export const NavLinkDisabled = css`
  color: ${colors.shade40};
  cursor: not-allowed;

  &:hover {
    background: none;
  }
`;

export const NavLinkActive = css`
  background: ${colors.paleGrey};
  pointer-events: none;
  color: ${colors.floomBlue};
  font-weight: 700;
`;

export const NavLinkToggleActive = css`
  background: ${colors.paleGrey};
  color: ${colors.floomBlue};
  font-weight: 700;
`;

export const Icon = css`
  margin-right: 21px;
  width: ${fontSizes.medium}px;
  min-width: ${fontSizes.medium}px;
  path {
    fill: ${colors.floomMidnightBlue};
  }
`;

export const IconDisabled = css`
  path {
    fill: ${colors.shade40};
  }
`;

export const IconActive = css`
  path {
    fill: ${colors.floomBlue};
  }
`;

export const IconAddFeature = css`
  width: ${fontSizes.medium}px;
  margin-left: auto;

  path {
    fill: ${colors.shade40};
  }
`;
