import { Component, Fragment } from 'react';

import { css } from '@emotion/react';
import { phoneString } from 'lib/services/validation/extensions/phone';
import { reqPostalCodeString } from 'lib/services/validation/extensions/postalcode';
import _startCase from 'lodash.startcase';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import * as Yup from 'yup';

import {
  Currency,
  RijnsDeliveryType,
  WholesaleDeliveryConfigUpdateInput
} from 'generated-types.d';

import {
  LocalisationService,
  PermissionsService,
  ValidationService
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { publishEvent } from 'utils/event';
import { colors } from 'utils/rebass-theme';

import { FieldEditModalData } from 'features/modal-dialogue/components/modals/field-edit-modal/field-edit-modal.types';
import WholesaleServices from 'features/wholesale/services';
import { DELIVERY_COLUMN_HEADINGS } from 'features/wholesale/wholesale.constants';

import CheckboxList from 'components/checkbox-list';
import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType } from 'components/entity-list/table-layout-entity.types';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import Icon from 'components/icon';

import * as Types from './wholesale-delivery-item.types';

class WholesaleDeliveryItem extends Component<Types.WholesaleDeliveryItemProps, Types.WholesaleDeliveryItemState> {
  state: Types.WholesaleDeliveryItemState = {
    editableConfig: {
      deliveryInstructions: this.props.config.deliveryInstructions || '',
      active: this.props.config.active || false,
      businessName: this.props.config.address?.businessName || '',
      recipientFullName: this.props.config.address?.recipientFullName || '',
      address1: this.props.config.address?.address1 || '',
      address2: this.props.config.address?.address2 || '',
      city: this.props.config.address?.city || '',
      postalCode: this.props.config.address?.postalCode || '',
      phone: this.props.config.address?.phone || '',
      region: this.props.config.address?.region || '',
      rijnsDeliveryType: this.props.config.rijnsDeliveryType || 'Standard'
    },
    selectedHubs: this.props.config.hubs?.map(hub => hub!) || [],
    isDirty: false,
    isSaving: false,
    validationErrors: []
  };

  deliveryValidationSchema: Yup.Schema<any> = Yup.object().shape({
    deliveryInstructions: Yup
      .string()
      .trim()
      .notRequired(),
    active: Yup
      .boolean(),
    businessName: Yup
      .string()
      .trim()
      .notRequired(),
    recipientFullName: Yup
      .string()
      .trim()
      .required(params => ValidationService.generateYupMessageSchema(params, 'City is required')),
    address1: Yup
      .string()
      .trim()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Address line 1 is required')),
    address2: Yup
      .string()
      .trim()
      .notRequired(),
    city: Yup
      .string()
      .trim()
      .required(params => ValidationService.generateYupMessageSchema(params, 'City is required')),
    postalCode: reqPostalCodeString('postalCode', this.props.config.merchant?.currency === Currency.Usd),
    phone: phoneString('phone', this.getCountryCode()),
    region: Yup
      .string()
      .trim()
      .notRequired(),
    rijnsDeliveryType: Yup
      .string()
      .notRequired()
  });

  private getCountryCode(): string {
    return this.props.config.merchant?.currency === Currency.Usd ? 'US' : 'GB';
  }

  private handleChange = (value: string | boolean, key: Types.EditableConfigPropKeys): void => {
    const state: Partial<Types.WholesaleDeliveryItemState> = {
      editableConfig: {
        ...this.state.editableConfig,
        [key]: value
      }
    };

    if (!this.state.isDirty) {
      state.isDirty = true;
    }

    this.setState(state as Types.WholesaleDeliveryItemState);
  };

  private triggerModal = (): any => {
    this.setState({ validationErrors: [] });

    this.props.modalStore!.triggerModal<FieldEditModalData>({
      modalType: 'fieldEdit',
      data: {
        title: 'Edit delivery location',
        isDirty: (): boolean => this.state.isDirty,
        config: this.DELIVERY_INSTRUCTIONS_CONFIG,
        confirmButtonAction: this.handleUpdate
      }
    });
  };

  private handleUpdate = async (): Promise<void> => new Promise(async (resolve, reject): Promise<any> => {
    try {
      const validatedInputs: Types.EditableConfig = await this.validateInputs();

      const config = await WholesaleServices.WholesaleSettingsService.updateWholesaleDeliveryConfig(
        this.props.config,
        this.buildWholesaleUpdateInput(validatedInputs)
      );

      this.props.wholesaleSettingsStore!.setDeliveryConfig(this.props.config.id, config);
      this.props.toasterStore!.popSuccessToast('Delivery config', 'save');
      this.setState({ editableConfig: { ...validatedInputs } });
      resolve();
    } catch (e) {
      this.props.toasterStore!.popToast('We\'re having trouble saving the delivery config.', ToastType.Error);
      reject();
    }
  });

  private validateInputs = async (): Promise<Types.EditableConfig> => {
    try {
      const validatedInputs = await ValidationService.validateAll(this.deliveryValidationSchema, this.state.editableConfig);

      return validatedInputs;
    } catch (error) {
      this.setState({ validationErrors: error.errors as Yup.TestMessageParams[] });
      throw Error('validation failed');
    }
  };

  private handleBlurValidateFormat = async (value: string | boolean, key: Types.EditableConfigPropKeys): Promise<void> => {
    try {
      const validatedFormattedValue = await ValidationService.validateField(this.deliveryValidationSchema, value, key);
      this.handleChange(validatedFormattedValue, key);
      publishEvent('updateModal');
    } catch (error) {
      this.setState({ validationErrors: error.errors });
      publishEvent('updateModal');
    }
  }

  private findError = (key: any): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(this.state.validationErrors, key);
  };

  private resetErrorForField  = (key: any): void => {
    const errors = this.state.validationErrors?.filter(error => error.path !== key);
    this.setState({ validationErrors: errors });
    publishEvent('updateModal');
  };

  private toggleActiveStatus = async (): Promise<void> => new Promise(async (resolve, reject): Promise<any> => {
    if (!PermissionsService.isInternalRole()) return;

    try {
      this.setState({ isSaving: true });

      const config = await WholesaleServices.WholesaleSettingsService.updateWholesaleDeliveryConfig(
        this.props.config,
        {
          active: !this.props.config.active
        }
      );

      this.handleChange(!!config.active, 'active');
      this.props.wholesaleSettingsStore!.setDeliveryConfig(this.props.config.id, config);
      this.props.toasterStore!.popSuccessToast('Delivery config', 'save');
      resolve();
    } catch (e) {
      this.props.toasterStore!.popToast('We\'re having trouble saving the delivery config.', ToastType.Error);
      reject();
    }

    this.setState({ isSaving: false });
  });

  private buildWholesaleUpdateInput = (validatedInputs: Types.EditableConfig): WholesaleDeliveryConfigUpdateInput => {
    let updateInput: WholesaleDeliveryConfigUpdateInput = {
      deliveryInstructions: this.state.editableConfig.deliveryInstructions.toString().trim() as string
    };

    if (PermissionsService.isInternalRole()) {
      const uniqueHubIds = Array.from(new Set(this.state.selectedHubs.map(hub => hub.id)));

      updateInput = {
        ...updateInput,
        rijnsDeliveryType: validatedInputs.rijnsDeliveryType as RijnsDeliveryType,
        active: validatedInputs.active as boolean,
        hubs: {
          set: uniqueHubIds.map(id => ({ id }))
        },
        address: {
          update: {
            businessName: validatedInputs.businessName as string,
            recipientFullName: validatedInputs.recipientFullName as string,
            address1: validatedInputs.address1 as string,
            address2: validatedInputs.address2 as string,
            city: validatedInputs.city as string,
            postalCode: validatedInputs.postalCode as string,
            phone: validatedInputs.phone as string,
            region: validatedInputs.region as string
          }
        }
      };
    }

    return updateInput;
  };

  public DELIVERY_INSTRUCTIONS_CONFIG = (): FormBuilderConfig => {
    return {
      sections: [
        {
          width: '100',
          paddingBottom: '0',
          fields: [
            {
              key: 'deliveryInstructionsLabel',
              fieldType: 'labelSmall',
              copy: 'Delivery instructions',
              validationError: undefined
            },
            {
              key: 'deliveryInstructionsField',
              fieldType: 'textArea',
              value: this.state.editableConfig.deliveryInstructions as string,
              validationError: this.findError('deliveryInstructions'),
              onChange: (value: string): void => this.handleChange(value, 'deliveryInstructions')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressNameLabel',
              fieldType: 'labelSmall',
              copy: 'Name on address',
              validationError: undefined
            },
            {
              key: 'addressNameField',
              fieldType: 'textInput',
              placeholder: 'e.g. John Johnson',
              validationError: this.findError('recipientFullName'),
              value: this.state.editableConfig.recipientFullName as string,
              onChange: (e): void => this.handleChange(e.target.value, 'recipientFullName')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressBusinessNameLabel',
              fieldType: 'labelSmall',
              copy: 'Address business name',
              validationError: undefined
            },
            {
              key: 'addressBusinessNameField',
              fieldType: 'textInput',
              validationError: this.findError('businessName'),
              value: this.state.editableConfig.businessName as string,
              onChange: (e): void => this.handleChange(e.target.value, 'businessName')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressLine1Label',
              fieldType: 'labelSmall',
              copy: 'Address line 1',
              validationError: undefined
            },
            {
              key: 'addressLine1Field',
              fieldType: 'textInput',
              validationError: this.findError('address1'),
              value: this.state.editableConfig.address1 as string,
              onChange: (e): void => this.handleChange(e.target.value, 'address1')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressLine2Label',
              fieldType: 'labelSmall',
              copy: 'Address line 2',
              validationError: undefined
            },
            {
              key: 'addressLine1Field',
              fieldType: 'textInput',
              validationError: this.findError('address2'),
              value: this.state.editableConfig.address2 as string,
              onChange: (e): void => this.handleChange(e.target.value, 'address2')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressCityField',
              fieldType: 'labelSmall',
              copy: 'City',
              validationError: undefined
            },
            {
              key: 'addressCityField',
              fieldType: 'textInput',
              placeholder: 'e.g. London, New York',
              validationError: this.findError('city'),
              value: this.state.editableConfig.city as string,
              onChange: (e): void => this.handleChange(e.target.value, 'city')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressPostalCodeField',
              fieldType: 'labelSmall',
              copy: _startCase(LocalisationService.renderPostalCodeCopy(this.props.config.merchant!.currency)),
              validationError: undefined
            },
            {
              key: 'addressPostalCodeField',
              fieldType: 'textInput',
              validationError: this.findError('postalCode'),
              value: this.state.editableConfig.postalCode as string,
              onChange: (e): void => this.handleChange(e.target.value, 'postalCode'),
              onFocus: (): void => this.resetErrorForField('postalCode'),
              onBlur: (e): Promise<void> => this.handleBlurValidateFormat(e.target.value, 'postalCode')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressRegionField',
              fieldType: 'labelSmall',
              copy: 'Region',
              validationError: undefined
            },
            {
              key: 'addressRegionField',
              fieldType: 'textInput',
              placeholder: 'e.g. Kent, Surrey, NY, LA',
              validationError: this.findError('region'),
              value: this.state.editableConfig.region as string,
              onChange: (e): void => this.handleChange(e.target.value, 'region')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressPhoneLabel',
              fieldType: 'labelSmall',
              copy: 'Contact number',
              validationError: undefined
            },
            {
              key: 'addressPhoneField',
              fieldType: 'textInput',
              type: 'tel',
              placeholder: 'e.g. 07945637282',
              validationError: this.findError('phone'),
              value: this.state.editableConfig.phone as string,
              onFocus: (): void => this.resetErrorForField('phone'),
              onChange: (e): void => this.handleChange(e.target.value, 'phone'),
              onBlur: (e): Promise<void> => this.handleBlurValidateFormat(e.target.value, 'phone')
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole() || !this.props.wholesaleSettingsStore!.supplierDeliveryHubs,
          fields: [
            {
              key: 'deliveryTypeLabel',
              fieldType: 'labelSmall',
              copy: 'Delivery hubs',
              subCopy: 'Choose the hubs that this merchant supports. Only one hub per supplier can be selected',
              validationError: undefined
            },
            {
              key: 'productTypesField',
              fieldType: 'custom',
              customContent: (
                <Box mt="20px">
                  <CheckboxList
                    items={this.props.wholesaleSettingsStore!.supplierDeliveryHubs.map(hub => {
                      return {
                        title: `${hub.title} - ${hub.supplier.name}`,
                        isDisabled: this.state.selectedHubs.some(selectedHub => hub.id !== selectedHub.id && hub.supplier.id === selectedHub.supplier.id),
                        value: hub.id
                      };
                    })}
                    selectedItems={this.state.selectedHubs.map(hub => hub.id)}
                    itemValueField="value"
                    optionTitleField="title"
                    orientation="vertical"
                    onChange={value => {
                      const hubForValue = this.props.wholesaleSettingsStore!.supplierDeliveryHubs.find(deliveruHub => deliveruHub!.id === value);
                      const isSelected = this.state.selectedHubs.some(selectedHub => selectedHub.id === value);

                      this.setState(prevState => ({
                        isDirty: true,
                        selectedHubs: isSelected ? prevState.selectedHubs.filter(hub => hub.id !== value) : [
                          ...prevState.selectedHubs,
                          hubForValue!
                        ]
                      }));
                    }}
                    checkboxStyles={css`
                      margin-bottom: 0;
                    `}
                  />
                </Box>
              )
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          isHidden: !PermissionsService.isInternalRole(),
          fields: [
            {
              key: 'addressActiveState',
              fieldType: 'labelSmall',
              copy: 'Activate address',
              subCopy: 'Only activate this once Hoek have the full address entered here, and they have provided the Hoek code for you to enter above.',
              validationError: undefined
            },
            {
              key: 'addressActiveField',
              fieldType: 'singleCheckbox',
              label: 'Is address active?',
              isSelected: this.state.editableConfig.active as boolean,
              isDisabled: false,
              handleSelect: (): void => {
                this.handleChange(!this.state.editableConfig.active, 'active');
              },
              customStyles: css`
                margin-top: 20px;
              `
            }
          ]
        }
      ]
    };
  };

  private renderAddress = (): JSX.Element => {
    const address = this.props.config.address!;
    const addressString = [
      address.address1.trim(),
      address.city?.trim(),
      address.postalCode?.trim()
    ]
      .filter(Boolean)
      .join(', ');

    if (!addressString.trim()) {
      return (
        <Fragment>
          No address
        </Fragment>
      );
    }

    return (
      <Fragment>
        {address.address1}, {address.city}, <br />{address.postalCode}
      </Fragment>
    );
  };

  render(): JSX.Element {
    const deliveryInstructions = this.props.config.deliveryInstructions;
    const isInternalRole = PermissionsService.isInternalRole();

    return (
      <EntityListRow removeOverflow={true}>
        <Flex
          width="100%"
          p="18px 10px"
          alignItems="center"
        >
          <TableLayoutEntity
            config={[
              {
                cellType: CellType.CheckboxToggle,
                isVisible: isInternalRole,
                fixedWidth: DELIVERY_COLUMN_HEADINGS[0].fixedWidth,
                props: {
                  onChange: this.toggleActiveStatus,
                  isDisabled: this.state.isSaving,
                  switchBgActive: colors.validationText,
                  switchBgInactive: colors.errorText,
                  switchActiveCopy: 'Yes',
                  switchInactiveCopy: 'No',
                  isChecked: this.props.config.active
                }
              },
              {
                cellType: CellType.Text,
                isVisible: isInternalRole,
                flexBasis: DELIVERY_COLUMN_HEADINGS[1].flexBasis,
                flexGrow: DELIVERY_COLUMN_HEADINGS[1].flexGrow,
                props: {
                  text: this.props.config.merchant?.title || '-'
                }
              },
              {
                cellType: CellType.Text,
                isVisible: true,
                flexBasis: DELIVERY_COLUMN_HEADINGS[2].flexBasis,
                flexGrow: DELIVERY_COLUMN_HEADINGS[2].flexGrow,
                props: {
                  text: this.renderAddress()
                }
              },
              {
                cellType: CellType.Text,
                isVisible: true,
                flexBasis: DELIVERY_COLUMN_HEADINGS[3].flexBasis,
                flexGrow: DELIVERY_COLUMN_HEADINGS[3].flexGrow,
                props: {
                  text: !!deliveryInstructions && deliveryInstructions?.length > 80
                    ? `${deliveryInstructions?.slice?.(0, 80)}...`
                    : deliveryInstructions
                }
              },
              {
                cellType: CellType.Custom,
                isVisible: true,
                fixedWidth: DELIVERY_COLUMN_HEADINGS[4].fixedWidth,
                customInnerElement: (
                  <Box ml="auto">
                    <button onClick={this.triggerModal}>
                      <Icon iconName="edit" />
                    </button>
                  </Box>
                )
              }
            ].filter(item => item.isVisible)}
          />
        </Flex>
      </EntityListRow>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore,
  wholesaleSettingsStore: stores.wholesaleSettingsStore
}))(observer(WholesaleDeliveryItem));
