import React, { Component, ReactNode } from 'react';

import { Box } from 'rebass';

import { TimeService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import { Subheading } from '../../order-detail.styles';

import * as Types from './date-ordered.types';

export default class DateOrdered extends Component<Types.DateOrderedProps> {
  render(): ReactNode {
    return(
      <>
        <Subheading>Order placed</Subheading>
        <Box css={textStyles.body}>
          {TimeService.longFormatWithoutDate(this.props.orderedAt, this.props.timezone)}
        </Box>
      </>
    );
  }
}
