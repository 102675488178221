import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles, colors, space } from 'utils/rebass-theme';

export const Divider: any = styled(Box)<any>`
  min-height: 23px;
  margin: ${space[2]}px 0;
  border-bottom: 1px solid ${colors.middleGrey};
`;

export const Label: any = styled.p`
  ${textStyles.label};
  margin-bottom: ${space[2]}px;
  padding: 0 ${space[2]}px;
  text-transform: uppercase;
  color: ${colors.shade40};
`;
