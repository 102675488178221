import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { breakpoints, colors, fontStacks } from 'utils/rebass-theme';

import { stackedSearchBreakpoint } from 'features/wholesale/wholesale.constants';

export const Container = styled(Flex)`
  margin-right: 15px;
  align-items: center;

  @media only screen and (max-width: ${stackedSearchBreakpoint}) {
    margin-right: 10px;
    flex-grow: 1;
  }
`;

export const DropdownContainer = styled(Box)`
  min-width: 140px;
  max-width: 160px;

  @media only screen and (max-width: ${stackedSearchBreakpoint}) {
    flex-grow: 1;
  }
  
  @media only screen and (max-width: ${breakpoints.small}) {
    min-width: 110px;
  }
`;

export const Label = styled(Box)`
  font-family: ${fontStacks.secondary};
  font-size: 14px;
  color: ${colors.floomMidnightBlue};
  margin-right: 10px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    margin-right: 20px;
  }

  @media only screen and (max-width: ${stackedSearchBreakpoint}) {
    font-size: 13px;
    margin-right: 5px;
  }
`;
