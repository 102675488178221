import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import { InjectedMobxStores } from 'stores';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { Pagination } from 'components/algolia';

import {
  Wrapper
} from './wholesale-shop-list-pagination.styles';

export const WholesaleShopListPagination: FC<Pick<InjectedMobxStores,
| 'wholesaleShopStore'
>> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  wholesaleShopStore
}) => {
  const handlePaginate = (): void => {
    if (window.innerWidth <= 880 && wholesaleShopStore!.isDisplayingOrder) {
      wholesaleShopStore!.hideOrderSidebar();
    }

    window?.scrollTo?.({
      top: 0,
      behavior: 'auto'
    });
  };

  return (
    <Wrapper data-testid={TEST_IDS.WholesaleShop.paginationButtons}>
      <Pagination
        handlePaginate={handlePaginate}
      />
    </Wrapper>
  );
}));
