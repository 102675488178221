import {
  FC
} from 'react';

import { css } from '@emotion/react';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { stackedSearchBreakpoint } from 'features/wholesale/wholesale.constants';

import IconButton from 'components/icon-button';

import {
  Wrapper,
  Label
} from './wholesale-shop-filters.styles';
import {
  WholeSaleShopFiltersProps
} from './wholesale-shop-filters.types';

export const WholesaleShopFilters: FC<WholeSaleShopFiltersProps> = ({ openFiltersModal }) => {
  return (
    <Wrapper
      data-testid={TEST_IDS.WholesaleShopFilters.trigger}
      onClick={openFiltersModal}
    >
      <Label>Filters</Label>
      <IconButton
        onClick={() => {}}
        iconName="filter"
        styles={css`
          vertical-align: middle;
          
          @media only screen and (max-width: ${stackedSearchBreakpoint}) {
            height: 20px;
            width: 20px;
          }
        `}
      />
    </Wrapper>
  );
};
