import { action, observable, makeObservable } from 'mobx';
import { TestMessageParams } from 'yup';

import {
  Discount,
  DiscountMetric,
  ProductType,
  ProductCategory,
  Product
} from 'generated-types.d';

import { StringFormat } from 'lib';

import * as Types from './discount-create-edit-store.types';

export default class DiscountCreateEditStore {
  constructor() {
    makeObservable(this, {
      formValidationErrors: observable,
      formValues: observable,
      isLoadingDiscount: observable,
      isLoadingMetadata: observable,
      isLoadingProducts: observable,
      importedDiscount: observable,
      productCategories: observable,
      productTypes: observable,
      selectableProducts: observable,
      applyProductMetadataIDs: action,
      clearFieldsInformation: action,
      generateCode: action,
      populateDiscountForm: action,
      removeProductMetadataID: action,
      resetCreate: action,
      resetStore: action,
      setError: action,
      setErrors: action,
      setProducts: action,
      setProductMetadata: action,
      setDiscountLoading: action,
      setProductMetadataLoading: action,
      setProductsLoading: action,
      setValue: action
    });
  }

  private static setFormDefaults = (): Types.DiscountFormSchema => ({
    code: '',
    isActive: false,
    description: '',
    startsAt: null,
    endsAt: null,
    usageLimit: '',
    perUserLimit: false,
    discountType: Types.DiscountType.Percent,
    amount: '0',
    minBasketValue: '',
    appliesTo: Types.DiscountApplication.EntireOrder,
    selectedProducts: [],
    productTypes: [],
    productCategories: [],
    oncePerOrder: true
  });

  public formValidationErrors: TestMessageParams[] = [];

  public formValues: Types.DiscountFormSchema = DiscountCreateEditStore.setFormDefaults();

  public isLoadingDiscount: boolean = true;

  public isLoadingMetadata: boolean = true;

  public isLoadingProducts: boolean = true;

  public importedDiscount: Discount | null = null;

  public productTypes: ProductType[] = [];

  public productCategories: ProductCategory[] = [];

  public selectableProducts: Product[] = [];

  public resetStore = (): void => {
    this.formValidationErrors = [];
    this.formValues = DiscountCreateEditStore.setFormDefaults();
    this.isLoadingDiscount = true;
    this.isLoadingMetadata = true;
    this.isLoadingProducts = true;
    this.importedDiscount = null;
    this.productTypes = [];
    this.productCategories = [];
    this.selectableProducts = [];
  };

  public resetCreate = (): void => {
    this.formValidationErrors = [];
    this.formValues = DiscountCreateEditStore.setFormDefaults();
    this.selectableProducts = [];
    this.isLoadingProducts = true;
  };

  public populateDiscountForm = (discount: Discount): void => {
    this.importedDiscount = discount;

    this.formValues = {
      code: discount.code || '',
      isActive: !!discount.isActive,
      description: discount.description || '',
      startsAt: discount.startsAt || null,
      endsAt: discount.endsAt || null,
      usageLimit: discount.usageLimit !== null ? `${discount.usageLimit}` : '',
      perUserLimit: !!discount.perUserLimit || false,
      discountType: this.defineDiscountType(discount.amountMetric),
      amount: discount.amount !== null ? `${discount.amount}` : '',
      minBasketValue: discount.minBasketValue !== null ? `${discount.minBasketValue}` : '',
      appliesTo: this.defineDiscountApplication(discount),
      selectedProducts: discount.selectedProducts || [],
      productTypes: discount.productTypes || [],
      productCategories: discount.productCategories || [],
      oncePerOrder: discount.isOncePerOrder === null ? true : discount.isOncePerOrder as boolean
    };
  };

  private defineDiscountApplication = (discount: Discount): Types.DiscountApplication => {
    switch (true) {
      case discount.isFreeShipping:

      default:
        return Types.DiscountApplication.EntireOrder;

      case !!discount.selectedProducts?.length:
        return Types.DiscountApplication.Products;

      case !!discount.productTypes?.length:
        return Types.DiscountApplication.ProductTypes;

      case !!discount.productCategories?.length:
        return Types.DiscountApplication.ProductCategories;
    }
  };

  public clearFieldsInformation = (fields: Array<keyof Types.DiscountFormSchema>): void => {
    for (const index in fields) {
      if (this.formValidationErrors[fields[index]]) {
        switch (true) {
          case fields[index] === 'discountType':
            this.formValues.discountType = Types.DiscountType.Fixed;

          case fields[index] === 'appliesTo':
            this.formValues.appliesTo = Types.DiscountApplication.EntireOrder;

          case typeof this.formValidationErrors[fields[index]] === 'string':
            this.formValues[fields[index] as any] = '';

            break;

          case typeof this.formValidationErrors[fields[index]] === 'boolean':
            this.formValues[fields[index] as any] = false;

            break;

          case Array.isArray(this.formValidationErrors[fields[index]]):
            this.formValues[fields[index] as any] = [];

            break;
        }
      }
    }
  };

  public setValue = (value: Types.DiscountFormSchema[keyof Types.DiscountFormSchema], key: keyof Types.DiscountFormSchema): void => {
    this.formValues[key as any] = value;
    this.removeValidationError(key);
  };

  public removeValidationError = (key: keyof Types.DiscountFormSchema): void => {
    this.formValidationErrors = this.formValidationErrors?.filter(error => error.path !== key);
  };

  public generateCode = (): void => {
    this.formValues.code = StringFormat.makeID(8).toUpperCase();
    this.removeValidationError('code');
  };

  public setErrors = (errors: TestMessageParams[] = []): void => {
    this.formValidationErrors = errors;
  };

  public setError = (error: TestMessageParams): void => {
    if (error) {
      this.formValidationErrors = [...this.formValidationErrors, error];
    }
  };

  public applyProductMetadataIDs = (
    ids: string[],
    formValueKey: keyof Pick<Types.DiscountFormSchema, 'productTypes' | 'productCategories' | 'selectedProducts'>,
    metadataKey: keyof Pick<DiscountCreateEditStore, 'productTypes' | 'productCategories' | 'selectableProducts'>
  ): void => {
    this.formValues = {
      ...this.formValues,
      [formValueKey]: [...this[metadataKey]].filter(metaItem => ids.includes(metaItem.id))
    };
  };

  public removeProductMetadataID = (
    id: string,
    formValueKey: keyof Pick<Types.DiscountFormSchema, 'productTypes' | 'productCategories' | 'selectedProducts'>
  ): void => {
    this.formValues = {
      ...this.formValues,
      [formValueKey]: [...this.formValues[formValueKey]].filter(type => type.id !== id)
    };
  };

  public setProductMetadata = (productTypes: ProductType[], productCategories: ProductCategory[]): void => {
    this.productTypes = productTypes;
    this.productCategories = productCategories;
    this.isLoadingMetadata = false;
  };

  public setProducts = (products: Product[]): void => {
    this.selectableProducts = products;
    this.isLoadingProducts = false;
  };

  public setProductMetadataLoading = (isLoading = !this.isLoadingMetadata): void => {
    this.isLoadingMetadata = isLoading;
  };

  public setDiscountLoading = (isLoading = !this.isLoadingDiscount): void => {
    this.isLoadingDiscount = isLoading;
  };

  public setProductsLoading = (isLoading = !this.isLoadingMetadata): void => {
    this.isLoadingProducts = isLoading;
  };

  private defineDiscountType = (metric: Discount['amountMetric']): Types.DiscountType => {
    switch (metric) {
      case DiscountMetric.Fixed:
        return Types.DiscountType.Fixed;

      case DiscountMetric.Percent:
        return Types.DiscountType.Percent;

      default:
        return Types.DiscountType.FreeShipping;
    }
  };
}
