import { ApolloQueryResult } from '@apollo/client';
import store from 'stores';

import {
  DiscountConnection,
  DiscountCreateInput,
  Discount,
  Channel,
  DiscountMetric,
  Product,
  DiscountUpdateInput,
  DiscountWhereUniqueInput
} from 'generated-types.d';

import { GraphQL, ProductService, PermissionsService } from 'lib';

import DiscountCreateEditStore from 'stores/discount-create-edit/discount-create-edit-store';
import { DiscountType, DiscountFormSchema, DiscountApplication } from 'stores/discount-create-edit/discount-create-edit-store.types';
import DiscountsStore from 'stores/discounts/discounts-store';
import ToasterStore from 'stores/toaster-store/toaster-store';
import UserStore from 'stores/user/user-store';

import { DiscountQueryVars } from 'features/settings/components/discounts/discounts.types';
import { CREATE_DISCOUNT, UPDATE_DISCOUNT } from 'features/settings/graphql/mutators/discount.mutators';
import { DISCOUNTS, SINGLE_DISCOUNT, DISCOUNT_ANALYTICS } from 'features/settings/graphql/queries/discount.queries';

export default class DiscountsService {
  private toasterStore = store.toasterStore as ToasterStore;

  private discountsStore = store.discountsStore as DiscountsStore;

  private discountCreateEditStore = store.discountCreateEditStore as DiscountCreateEditStore;

  private userStore = store.userStore as UserStore;

  public paginateDiscounts = async (): Promise<any> => {
    this.discountsStore.onPaginate();

    const vars = this.buildListVariables();

    return GraphQL.query(DISCOUNTS(vars), vars)
      .then((result: ApolloQueryResult<{ discountsConnection: DiscountConnection }>) => {
        this.discountsStore.handlePaginationResult(result.data.discountsConnection);
        this.discountsStore.setListLoading(false);

        return result.data.discountsConnection;
      }).catch((error: any) => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('discounts', 'retrieve');
        this.discountsStore.setListLoading(false);

        return Promise.reject(error);
      });
  };

  public fetchDiscounts = async (): Promise<any> => {
    const vars = this.buildListVariables();

    return GraphQL.query(DISCOUNTS(vars), vars)
      .then((result: ApolloQueryResult<{ discountsConnection: DiscountConnection }>) => {
        this.discountsStore.setDiscounts(result.data.discountsConnection);

        return result.data.discountsConnection;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('discounts', 'retrieve');

        return Promise.reject(error);
      });
  };

  public fetchDiscount = async (id: string): Promise<any> => {
    const discountWhere: DiscountWhereUniqueInput = { id };

    if (!PermissionsService.isInternalRole()) {
      discountWhere.merchant = {
        id: this.userStore.merchantId
      };
    }

    return GraphQL.query(SINGLE_DISCOUNT, { where: discountWhere })
      .then((result: ApolloQueryResult<{ discount: Discount }>) => {
        if (!!result.data.discount) {
          this.discountCreateEditStore.populateDiscountForm(result.data.discount);
        }

        this.discountCreateEditStore.setDiscountLoading(false);

        return result.data.discount;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.discountCreateEditStore.setDiscountLoading(false);
        this.toasterStore.popErrorToast('discount', 'retrieve');

        return Promise.reject(error);
      });
  };

  public fetchDiscountAnalytics = async (discount: Discount): Promise<any> => {
    const discountWhere: DiscountWhereUniqueInput = {
      id: discount.id,
      merchant: {
        id: discount.merchant!.id
      }
    };

    return GraphQL.query(DISCOUNT_ANALYTICS, { where: discountWhere })
      .then((result: ApolloQueryResult<{ discount: Pick<Discount, 'totalRevenue' | 'amountDiscounted'> }>) => {
        return result.data.discount;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('analytics for this discount', 'retrieve');

        return Promise.reject(error);
      });
  };

  public fetchDiscountProductMetadata = async (): Promise<void> => {
    this.discountCreateEditStore!.setProductMetadataLoading(true);

    try {
      const productTypes = await ProductService.fetchProductTypes();
      const productCategories = await ProductService.fetchProductCategories();

      this.discountCreateEditStore!.setProductMetadata(productTypes, productCategories);
    } catch (error) {
      this.toasterStore.popErrorToast('product types and categories for your discounts', 'retrieve');
    }
  };

  public fetchProductsForMerchant = async (merchantID: string): Promise<Product[]> => {
    this.discountCreateEditStore!.setProductsLoading(true);

    try {
      const productEdges = await ProductService.fetchAllProducts({
        channels_some: {
          channel: Channel.Website
        },
        merchant: {
          id: merchantID
        }
      });

      const products: Product[] = productEdges.map(product => product.node);

      this.discountCreateEditStore!.setProducts(productEdges.map(product => product.node));

      return products;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public createDiscount = async (merchantId: string): Promise<Discount> => {
    const vars: { data: DiscountCreateInput } = { data: this.buildDiscountCreateInput(merchantId) };

    return GraphQL.query(CREATE_DISCOUNT, vars)
      .then((result: ApolloQueryResult<{ createDiscount: Discount }>) => {
        return result.data.createDiscount;
      })
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };

  public updateDiscount = async (): Promise<Discount> => {
    const vars: { data: DiscountUpdateInput; where: DiscountWhereUniqueInput } = {
      data: this.buildDiscountUpdateInput(),
      where: {
        id: this.discountCreateEditStore.importedDiscount!.id
      }
    };

    return GraphQL.query(UPDATE_DISCOUNT, vars)
      .then((result: ApolloQueryResult<{ updateDiscount: Discount }>) => result.data.updateDiscount)
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };

  public toggleIsActive = async (discountId: string, isActive: boolean): Promise<Discount> => {
    const vars: { data: DiscountUpdateInput; where: DiscountWhereUniqueInput } = {
      data: { isActive },
      where: {
        id: discountId
      }
    };

    return GraphQL.query(UPDATE_DISCOUNT, vars)
      .then((result: ApolloQueryResult<{ updateDiscount: Discount }>) => result.data.updateDiscount)
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };

  private buildListVariables = (): DiscountQueryVars => {
    return {
      merchantId: this.userStore.merchantId,
      skip: this.discountsStore.skipCount,
      first: this.discountsStore.listCount,
      search: this.discountsStore.searchValue,
      displayActive: this.discountsStore.isShowingInactiveDiscounts,
      orderBy: this.discountsStore.sortValue
    };
  };

  private buildDiscountUpdateInput = (): DiscountUpdateInput => {
    const { formValues: data } = this.discountCreateEditStore;

    return {
      ...this.buildCoreDiscountData(data),
      productCategories: { set: [] },
      productTypes: { set: [] },
      selectedProducts: { set: [] },
      ...this.buildDiscountApplicationData(data, 'edit') as DiscountUpdateInput
    };
  };

  private buildDiscountCreateInput = (merchantId: string): DiscountCreateInput => {
    const { formValues: data } = this.discountCreateEditStore;

    return {
      ...this.buildCoreDiscountData(data) as DiscountCreateInput,
      ...this.buildDiscountApplicationData(data, 'create') as DiscountCreateInput,
      merchant: {
        connect: {
          id: merchantId
        }
      },
      channels: {
        connect: [{
          channel: Channel.Website
        }]
      }
    };
  };

  private buildDiscountApplicationData = (data: DiscountFormSchema, formType: 'create' | 'edit'): DiscountCreateInput | DiscountUpdateInput => {
    const assignmentProp: ('connect' | 'set') = formType === 'create'
      ? 'connect'
      : 'set';

    switch (data.appliesTo) {
      case DiscountApplication.EntireOrder:

      default:
        return {};

      case DiscountApplication.ProductCategories:
        return {
          productCategories: {
            [assignmentProp]: data.productCategories.map(category => ({
              slug: category.slug
            }))
          }
        };

      case DiscountApplication.ProductTypes:
        return {
          productTypes: {
            [assignmentProp]: data.productTypes.map(type => ({
              slug: type.slug
            }))
          }
        };

      case DiscountApplication.Products:
        return {
          selectedProducts: {
            [assignmentProp]: data.selectedProducts.map(product => ({
              id: product.id
            }))
          }
        };
    }
  };

  private buildCoreDiscountData = (data: DiscountFormSchema): DiscountCreateInput | DiscountUpdateInput => {
    const isFreeShipping = data.discountType === DiscountType.FreeShipping;

    return {
      code: data.code,
      description: data.description,
      isActive: data.isActive,
      isOncePerOrder: isFreeShipping ? null : data.oncePerOrder,
      isFreeShipping: isFreeShipping,
      amount: this.defineAmount(data.discountType),
      amountMetric: this.defineAmountMetric(data.discountType),
      usageLimit: parseInt(data.usageLimit) || null,
      minBasketValue: parseInt(data.minBasketValue),
      startsAt: data.startsAt || undefined,
      endsAt: data.endsAt || undefined,
      perUserLimit: data.perUserLimit ? 1 : 0
    };
  };

  private defineAmount = (discountType: DiscountType): number | null => {
    return discountType === DiscountType.FreeShipping
      ? null
      : parseFloat(this.discountCreateEditStore.formValues.amount);
  };

  private defineAmountMetric = (discountType: DiscountType): DiscountMetric | null => {
    if (discountType === DiscountType.FreeShipping) return null;

    return discountType as any;
  };
}
