import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Configure, Index } from 'react-instantsearch-core';
import { InstantSearch, Stats } from 'react-instantsearch-dom';
import { Box } from 'rebass';

import { PlanType } from 'generated-types.d';

import {
  Analytics,
  LocalisationService,
  Cookies,
  PermissionsService,
  MerchantService
} from 'lib';

import { Container } from 'utils/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

import UserLoginService from 'features/login-signup/services';
import OrderCapBanner from 'features/order-cap-banner';
import OrderPauseBanner from 'features/order-pause-banner';
import { ORDER_LIST_PAGE_LAYOUTS } from 'features/orders/graphql/helpers/order-group.config';
import ProductServices from 'features/products/services';
import TrialBanner from 'features/trial-banner';

import AccountChangeNotification from 'components/account-change-notification';
import NoResultsGeneric from 'components/no-results-generic';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import StyledLink from 'components/styled-link/styled-link';
import WholesaleBanner from 'components/wholesale-banner';
import WithLoading from 'components/with-loading';

import DataCounts from './components/data-counts';
import { CountBox, DataCountWrapper } from './components/data-counts/data-counts.styles';
import { DataCountItem } from './components/data-counts/data-counts.types';
import OverviewHeading from './components/overview-heading';
import OverviewPlanDetails from './components/overview-plan-details';
import OverviewUpgradePlan from './components/overview-upgrade-plan';
import * as Styles from './overview.styles';
import * as Types from './overview.types';
import { OverviewService } from './services';

class Overview extends React.Component<Types.OverviewProps> {
  searchClient: SearchClient | null = null;

  state = {
    hasUpgraded: false
  };

  private PRODUCT_COUNT_CONFIG = (): DataCountItem[] => [
    {
      title: 'Total Active',
      count: this.props.productsStore!.metadata.count.active,
      url: NavPages.ProductsList
    },
    {
      title: 'Out of Stock',
      count: this.props.productsStore!.metadata.count.outOfStock,
      url: NavPages.ProductsList,
      color: colors.floomRed
    },
    {
      title: 'Low Stock',
      count: this.props.productsStore!.metadata.count.lowStock,
      url: NavPages.ProductsList,
      color: colors.floomOrange
    }
  ];

  componentDidMount = (): void => {
    Analytics.page(Analytics.FxPageEvent.Overview);

    this.handleMerchantUpgradeReload();
    this.loadData();
  };

  componentWillUnmount = (): void => {
    this.disableUpgradeNotification();
  };

  private loadData = (): void => {
    OverviewService.fetchLast30DaysOrderRevenue();
    UserLoginService.getMerchantDetails();
    ProductServices.ProductListService.fetchAllProductsMeta();
  };

  private getSearchClient = (token: string): SearchClient => {
    if (!!this.searchClient) return this.searchClient;

    this.searchClient = algoliasearch(process.env.ALGOLIA_APPLICATION_ID!, token);

    return this.searchClient;
  };

  private handleMerchantUpgradeReload = (): void => {
    if (!!Cookies.get('floomx-upgraded')) {
      this.setState({ hasUpgraded: true });
    }
  };

  private floristName = (): string => this.props.merchantStore?.merchant?.title || 'All merchants';

  private renderDataCopy = (): ReactNode => {
    if (!PermissionsService.isInternalRole()) return null;

    return (
      <Box
        css={css`
          ${textStyles.h4}
          font-size: 16px;
        `}
        mt="30px"
        width="100%"
      >
        You are viewing data for {this.floristName()}
      </Box>
    );
  };

  private renderBlockHeading = (
    title: string,
    url?: string,
    linkCopy: string = 'View all'
  ): ReactNode => {
    return (
      <Styles.WidgetHeading>
        <h3>
          {title}
        </h3>
        {!!url && (
          <Link to={url}>
            <StyledLink>{linkCopy}</StyledLink>
          </Link>
        )}
      </Styles.WidgetHeading>
    );
  };

  private shouldRenderUpgradeBlock = (): boolean => {
    const hasActiveSubscription = MerchantService.hasActiveSubscription(this.props.merchantStore!.merchant);
    const upgradeSupportedPlans = [
      PlanType.Lite1,
      PlanType.Lite2,
      PlanType.Standard,
      PlanType.Standard2
    ];

    const hasSupportedPlan = MerchantService.hasPlanType(
      upgradeSupportedPlans,
      this.props.merchantStore!.merchant
    );

    return hasActiveSubscription && hasSupportedPlan;
  };

  private renderUpgradeBlock = (): ReactNode => {
    if (!this.shouldRenderUpgradeBlock()) return null;

    return (
      <>
        <Styles.DashboardGrid breakOn={1290}>
          {this.renderBlockHeading('Plan details')}
          <OverviewPlanDetails />
        </Styles.DashboardGrid>
        <Styles.DashboardGrid
          breakOn={1290}
          id="overview-upgrade-plan"
        >
          {this.renderBlockHeading('FloomX scales with you')}
          <OverviewUpgradePlan />
        </Styles.DashboardGrid>
      </>
    );
  };

  private disableUpgradeNotification = (): void => {
    if (this.state.hasUpgraded) {
      Cookies.remove('floomx-upgraded');
      this.setState({ hasUpgraded: false });
    }
  };

  private renderHadUpgraded = (): ReactNode => {
    if (!this.props.merchantStore?.merchant || !this.state.hasUpgraded) return null;

    return (
      <Box m="20px 0">
        <Notification
          type={NotificationType.Success}
          hasIcon={false}
          hasClose={true}
          onClose={this.disableUpgradeNotification}
          copy={`You have successfully upgraded to FloomX ${LocalisationService.localisePlanName(this.props.merchantStore?.merchant?.plan?.type)}!`}
        />
      </Box>
    );
  };

  private renderOrderPauseBanner = (): ReactNode => {
    if (!MerchantService.hasOrderPauseSupport(this.props.merchantStore!.merchant)) return null;

    return (
      <Box mt="25px">
        <OrderPauseBanner />
      </Box>
    );
  };

  private renderOrderCounts = (): ReactNode => {
    const searchClient = this.getSearchClient(this.props.ordersStore!.searchToken!);
    const timezone = this.props.merchantStore?.merchant?.timezone;

    return (
      <DataCountWrapper>
        <WithLoading
          hasNoResults={!this.props.ordersStore!.isLoadingSearchToken && !this.props.ordersStore!.searchToken}
          isLoading={this.props.ordersStore!.isLoadingSearchToken}
          renderNoResults={(): ReactNode => (
            <NoResultsGeneric
              icon="leaf-no-results"
              heading="Unable to get order data"
              copy="Please refresh your page to try again."
            />
          )}
        >
          <InstantSearch
            indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
            searchClient={searchClient}
          >
            <Index
              indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
              indexId="order-count-today"
            >
              <Configure
                numericFilters={ORDER_LIST_PAGE_LAYOUTS.today.dateRangeFilter(timezone)}
                hitsPerPage={1}
              />
              <CountBox
                as={Link}
                to={`${NavPages.OrdersList}today`}
              >
                <h5>Today</h5>
                <h1>
                  <Stats
                    translations={{
                      stats: (nbHits: number): string => `${nbHits}`
                    }}
                  />
                </h1>
              </CountBox>
            </Index>
            <Index
              indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
              indexId="order-count-tomorrow"
            >
              <Configure
                numericFilters={ORDER_LIST_PAGE_LAYOUTS.tomorrow.dateRangeFilter(timezone)}
                hitsPerPage={1}
              />
              <CountBox
                as={Link}
                to={`${NavPages.OrdersList}tomorrow`}
              >
                <h5>Tomorrow</h5>
                <h1>
                  <Stats
                    translations={{
                      stats: (nbHits: number): string => `${nbHits}`
                    }}
                  />
                </h1>
              </CountBox>
            </Index>
            <Index
              indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
              indexId="order-count-upcoming"
            >
              <Configure
                numericFilters={ORDER_LIST_PAGE_LAYOUTS.upcoming.dateRangeFilter(timezone)}
                hitsPerPage={1}
              />
              <CountBox
                as={Link}
                to={`${NavPages.OrdersList}upcoming`}
              >
                <h5>All Upcoming</h5>
                <h1>
                  <Stats
                    translations={{
                      stats: (nbHits: number): string => `${nbHits}`
                    }}
                  />
                </h1>
              </CountBox>
            </Index>
          </InstantSearch>
        </WithLoading>
      </DataCountWrapper>
    );
  };

  render(): ReactNode {
    return (
      <>
        <div
          css={css`
            height: 100vh;
          `}
        >
          <Styles.OverviewWrapper>
            <Container
              css={css`
                padding: 0 15px;
              `}
            >
              <Box m="0 10px">
                <AccountChangeNotification
                  wrapElement={(children): ReactNode => {
                    return (
                      <Box mt="20px">
                        {children}
                      </Box>
                    );
                  }}
                />
                {this.renderHadUpgraded()}
                {this.renderOrderPauseBanner()}
                <OverviewHeading
                  completedOrdersCost={this.props.overviewStore!.last30DaysOrdersCost}
                  completedOrderCount={this.props.overviewStore!.last30DaysOrderCount}
                />
                {this.renderDataCopy()}
                <OrderCapBanner />
                <TrialBanner />
              </Box>
              <Styles.DashboardGridContainer>
                <Styles.DashboardGrid>
                  {this.renderBlockHeading('Upcoming Orders', `${NavPages.OrdersList}upcoming`)}
                  {this.renderOrderCounts()}
                </Styles.DashboardGrid>
                <Styles.DashboardGrid>
                  {this.renderBlockHeading('Products', NavPages.ProductsList)}
                  <DataCounts
                    items={this.PRODUCT_COUNT_CONFIG()}
                  />
                </Styles.DashboardGrid>

                {this.renderUpgradeBlock()}
              </Styles.DashboardGridContainer>
              <WholesaleBanner />
            </Container>
          </Styles.OverviewWrapper>
        </div>
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  overviewStore: stores.overviewStore,
  productsStore: stores.productsStore,
  ordersStore: stores.ordersStore,
  userStore: stores.userStore,
  merchantStore: stores.merchantStore
}))(observer(Overview));
