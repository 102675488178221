import { ReactNode, Component } from 'react';

import { css } from '@emotion/react';
import { observer, inject } from 'mobx-react';
import { Box } from 'rebass';

import { PermissionsService, NavService } from 'lib';

import { textStyles, colors } from 'utils/rebass-theme';

import CheckboxToggle from 'components/checkbox-toggle';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import * as Styles from './order-pause-banner.styles';
import * as Types from './order-pause-banner.types';

class OrderPauseBanner extends Component<Types.OrderPauseBannerProps> {
  state = {};

  public componentDidMount = (): void => {};

  private shouldDisplayNotification = (): boolean => {
    return this.props.merchantStore!.sameDayOrderPauseStatus !== 0;
  };

  private onEnableSameDayOrders = (): void => {
    this.props.merchantStore!.toggleSameDayOrderPauseStatus(true);
  };

  private viewOrders = (): void => {
    NavService.ordersList('today');
  };

  private sendMessageToCS = (): void => {
    this.props.chatWidgetStore!.enableChatWidget();
    window['zE']?.('webWidget', 'chat:send', 'Hey FloomX team! I would like to re-enable same day orders please :)');
  };

  private renderNotificationCopy = (): ReactNode => {
    switch (this.props.merchantStore!.sameDayOrderPauseStatus) {
      // Auto paused
      case 1:
        return (
          <Styles.NotificationCopy>
            Your same day orders for floom.com have been paused because you have too many unread orders. <button onClick={this.viewOrders}>View orders</button>
          </Styles.NotificationCopy>
        );

      // CS Paused
      case 2:
        return (
          <Styles.NotificationCopy>
            Your same day orders for floom.com have been paused. <button onClick={this.sendMessageToCS}>Contact us</button>
          </Styles.NotificationCopy>
        );

      // Paused by merchant
      case 3:
        return (
          <Styles.NotificationCopy>
            You have paused same day orders for floom.com. <button onClick={this.onEnableSameDayOrders}>Click here</button> to start accepting day orders again
          </Styles.NotificationCopy>
        );

      default:
        break;
    }
  };

  render(): ReactNode {
    if (PermissionsService.isInternalRole()) return null;

    return(
      <Styles.OrderPauseBanner>
        <Styles.OrderPauseCheckbox
          alignItems="center"
        >
          <CheckboxToggle
            onChange={this.props.merchantStore!.toggleSameDayOrderPauseStatus}
            switchBgActive={colors.validationText}
            switchBgInactive={colors.floomOrange}
            activeCopy="Yes"
            inactiveCopy="No"
            controlValue={true}
            isDisabled={!this.props.merchantStore!.canToggleSameDayOrderPauseStatus}
            isChecked={this.props.merchantStore!.isAcceptingSameDayOrderOrders}
            id="overview-same-day-order-pause-toggle"
          />
          <Box
            css={css`
              ${textStyles.body}
              flex: 1;
            `}
            ml="10px"
            as="label"
            for="overview-same-day-order-pause-toggle"
          >
            Accepting same day orders?
          </Box>
        </Styles.OrderPauseCheckbox>
        { this.shouldDisplayNotification() && (
          <Styles.OrderPauseNotification>
            <Notification
              type={NotificationType.Progress}
              copy={this.renderNotificationCopy()}
              textAlign="left"
              hasClose={false}
            />
          </Styles.OrderPauseNotification>
        )}
      </Styles.OrderPauseBanner>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  chatWidgetStore: stores.chatWidgetStore,
  merchantStore: stores.merchantStore
}))(observer(OrderPauseBanner));
