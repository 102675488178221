import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Text } from 'rebass';

import { HTMLService } from 'lib';

import { colors, fontSizes } from 'utils/rebass-theme';

import wholesaleStemIconImage from 'assets/images/wholesale-stems-in-box.svg';

import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import CreateVariationMediaUploader from '../create-variation-tab/create-media-uploader';
import Components from '../create-variation-tab/inner-tab-components/inner-tab-components';

import * as Styles from './collection-product-content.styles';
import * as Types from './collection-product-content.types';

class CreateProductTab extends Component<Types.CreateProductTabProps> {
  private buildForm = (): FormBuilderConfig => {
    const selectedVariation = this.props.productEditStore!.variations.find(variation => variation.id === this.props.productEditStore!.currentVariationId);

    return {
      sections: [
        {
          width: '100',
          fields: [
            {
              key: 'productDescriptionLabel',
              fieldType: 'label',
              copy: 'Description',
              subCopy: 'This is what the customer will see on floom.com',
              validationError: undefined
            },
            {
              key: 'productDescription',
              fieldType: 'copy',
              validationError: undefined,
              copy: (
                <Styles.TextBox
                  dangerouslySetInnerHTML={{
                    __html: HTMLService.newLineToBreak(this.props.productEditStore!.description)
                  }}
                />
              )
            }
          ]
        },
        {
          width: '100',
          isHidden: this.props.productEditStore!.variations.length === 1,
          fields: [
            {
              key: 'productVariationSelection',
              fieldType: 'label',
              copy: 'Variations',
              subCopy: 'Select the variation you would like to see',
              validationError: undefined
            },
            {
              key: 'discountTypeField',
              fieldType: 'radioList',
              itemType: 'card',
              itemTitleField: 'title',
              itemValueField: 'value',
              orientation: 'horizontal',
              items: this.props.productEditStore!.variations.map(variation => {
                return {
                  title: variation.type,
                  value: variation.id
                };
              }),
              selectedItem: this.props.productEditStore!.currentVariationId,
              onChange: (variationId): any => {
                this.props.productEditStore!.setCurrentVariation(variationId);
              }
            }
          ]
        },
        {
          width: '100',
          fields: [
            {
              key: 'productMediaLabel',
              fieldType: 'label',
              copy: `Images for ${selectedVariation!.type}`,
              validationError: undefined
            },
            {
              key: 'productMedia',
              fieldType: 'custom',
              customContent: ((): ReactNode => {
                return (
                  <CreateVariationMediaUploader
                    mediaUploads={selectedVariation!.mediaUploads}
                    isReadOnly={true}
                  />
                );
              })()
            }
          ]
        },
        {
          width: '100',
          isHidden: !this.props.productEditStore!.internalDescription.trim().length,
          fields: [
            {
              key: 'productInternalDescriptionLabel',
              fieldType: 'label',
              copy: 'Recipe requirements',
              subCopy: 'Please refer to the below list of exact stem numbers and types to recreate this product. All stems can be found in our wholesale shop too.',
              validationError: undefined
            },
            {
              key: 'productDescription',
              fieldType: 'copy',
              validationError: undefined,
              copy: (
                <Styles.TextBox
                  dangerouslySetInnerHTML={{
                    __html: HTMLService.newLineToBreak(this.props.productEditStore!.internalDescription)
                  }}
                />
              )
            }
          ]
        },
        {
          width: '100',
          isHidden: !selectedVariation?.selectedComponentConfigs?.length,
          fields: [
            {
              key: 'productMedia',
              fieldType: 'custom',
              customContent: ((): ReactNode => {
                const renderCopy = (): ReactNode => {
                  return (
                    <>
                      <Text
                        fontSize={fontSizes.xSmall}
                        color={colors.shade40}
                        fontWeight="bolder"
                        mb="5px"
                      >
                        Did you know
                      </Text>
                      <Text
                        fontWeight="bold"
                        fontSize={fontSizes.base}
                        css={css`
                          a {
                            text-decoration: underline;
                          }
                        `}
                      >
                        All the beautiful stems incorporated in Floom Collection products
                        are available to purchase directly from our very own wholesale market.
                        Using the FloomX Wholesale shop to purchase your stems will guarantee
                        that you’re able to make a 60%-80% margin across all your sales.
                        <Link to={NavPages.Wholesale}>Head there now</Link>
                      </Text>
                    </>
                  );
                };

                return (
                  <Notification
                    type={NotificationType.Banner}
                    hasIcon={true}
                    hasClose={false}
                    customIcon={wholesaleStemIconImage}
                    textAlign="left"
                    copy={renderCopy()}
                  />
                );
              })()
            }
          ]
        },
        {
          width: '100',
          isHidden: !selectedVariation?.selectedComponentConfigs?.length,
          fields: [
            {
              key: 'productStemConfig',
              fieldType: 'custom',
              customContent: !!selectedVariation && (
                <Components
                  variation={selectedVariation}
                  copy="What you need to build this product"
                  isReadOnly={true}
                />
              )
            }
          ]
        }
      ]
    };
  };

  render(): React.ReactNode {
    return (
      <Styles.Content>
        <FormBuilder
          config={this.buildForm()}
          notification={{
            hasNotification: false,
            notificationProps: {
              copy: '',
              type: NotificationType.Error
            }
          }}
        />
      </Styles.Content>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore
}))(observer(CreateProductTab));
