/**
 * @description - not migrating to a functional react component, as this order layout will
 * drastically change when we support multiple suppliers / orders at one time. We'll effectively
 * re-write this from scratch, so leaving as-is to maintain support with current order journey
 */

import { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import pluralize from 'pluralize';
import { Box, Flex, Text } from 'rebass';

import { WholesaleOrderResolverVersion, WholesaleOrder } from 'generated-types.d';

import { CacheService, WholesaleService } from 'lib';

import { colors, textStyles } from 'utils/rebass-theme';
import { TEST_IDS } from 'utils/test/data-test-ids';

import wholesaleEmptyErrorSvg from 'assets/images/wholesale/wholesale-empty-error.svg';
import wholesaleEmptySvg from 'assets/images/wholesale/wholesale-empty.svg';

import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';
import WholesaleServices from 'features/wholesale/services';
import {
  DEFAULT_TOTAL_FOR_FREE_DELIVERY,
  TOTALS_FOR_FREE_DELIVERY
} from 'features/wholesale/wholesale.constants';
import { AggregatedWholesaleOrder } from 'features/wholesale/wholesale.types';

import StyledLink from 'components/styled-link';
import { TabsRibbon } from 'components/tabs-ribbon/tabs-ribbon';
import { RibbonTab } from 'components/tabs-ribbon/tabs-ribbon.types';
import WithLoading from 'components/with-loading';

import { WholesaleShopOrderSummaryItem } from '../wholesale-shop-order-summary-item/wholesale-shop-order-summary-item';

import {
  Header,
  NoItemsImg,
  NoItemsWrapper,
  Notification,
  Container,
  SubTotalHeading,
  TotalRow,
  SubTotalValue,
  Totals,
  SubCopy,
  BodyScroll,
  BodyInner,
  CutOffHeader,
  TotalHeading,
  TotalValue,
  CutOffCountdown,
  CutOffUnitCount
} from './wholesale-shop-order-summary.styles';
import {
  WholesaleShopOrderSummaryProps,
  WholesaleShopOrderSummaryState
} from './wholesale-shop-order-summary.types';

class WholesaleShopOrderSummaryView extends Component<WholesaleShopOrderSummaryProps, WholesaleShopOrderSummaryState> {
  state = {
    isEditing: false,
    isCancellingItem: false,
    removedItemTitle: '',
    selectedOrderId: null
  };

  cancelTimeout: NodeJS.Timeout | null = null;

  componentWillUnmount = (): void => {
    if (this.state.isEditing) {
      this.resetOrder();
    }

    if (this.cancelTimeout) {
      clearTimeout(this.cancelTimeout);
    }
  };

  private handleClose = (): void => {
    CacheService.storeCookieObject({
      name: 'wholesaleSidebarDismissed',
      data: true
    });

    this.props.wholesaleShopStore!.hideOrderSidebar();
  };

  private toggleEditMode = (): void => {
    this.setState(state => ({
      isEditing: !state.isEditing,
      removedItemTitle: state.isEditing ? '' : state.removedItemTitle
    }));
  };

  private removalErrorCopy = (error: any): string => {
    if (error?.graphQLErrors?.[0]?.code === 77046121083) {
      return 'Sorry, you are now unable to remove this item';
    }

    return 'There\'s been a problem removing this item from your order';
  };

  private handleRemove = async (
    itemId: string,
    title: string,
    orderId: string
  ): Promise<void> => {
    const currentOrder = this.props.wholesaleOrdersStore!.currentOpenOrders.find(order => order.id === orderId);
    const merchantId = currentOrder?.merchant.id;
    const isLastOrderItem = currentOrder?.items?.length === 1;

    if (!merchantId) {
      return Promise.reject('Unable to cancel this order');
    }

    try {
      this.setState({ isCancellingItem: true });

      await WholesaleService.cancelOrderItem({
        args: {
          where: {
            id: merchantId
          },
          data: {
            version: WholesaleOrderResolverVersion.Two,
            item: {
              id: itemId
            },
            order: {
              id: orderId
            }
          }
        }
      });

      await this.resetOrder();
      this.setRemovalConfirmation(title);

      this.setState({
        isCancellingItem: false,
        selectedOrderId: !isLastOrderItem ? orderId : null
      });
    } catch (error) {
      this.setState({ isCancellingItem: false });

      return Promise.reject(this.removalErrorCopy(error));
    }
  };

  private setRemovalConfirmation = (title: string): void => {
    this.setState({
      removedItemTitle: title
    }, () => {
      this.cancelTimeout = setTimeout(() => {
        this.setState({ removedItemTitle: '' });
        this.cancelTimeout = null;
      }, 1000 * 5);
    });
  };

  private resetOrder = async (): Promise<void> => {
    await WholesaleServices.WholesaleOrdersAPIService.fetchCurrentOpenOrders();
  };

  private getOrderTabConfig = (orders: AggregatedWholesaleOrder[]): RibbonTab[] => {
    return orders.map(order => {
      const deliveryConfig = this.props.wholesaleShopStore!.nextAvailableDeliveryDates?.find(delivery => delivery.supplierId === order.supplier.id);
      const deliveryDate = `Delivering ${moment(deliveryConfig?.dateString).format('Do MMM')}`;

      return {
        tabId: order.orderId,
        title: order.supplier.name,
        subtitle: deliveryDate,
        disabled: false
      };
    });
  }

  private selectOrder = (orderId: string): void => {
    this.setState({ selectedOrderId: orderId });
  }

  private isOrderEmpty = (orders: WholesaleOrder[]): boolean => {
    return !orders || orders.every(order => !order?.items?.length);
  }

  private renderHeader = (): ReactNode => {
    const { isEditing } = this.state;

    return (
      <Header>
        <Text fontSize="16px">
          {isEditing ? 'Editing items' : 'Purchased items'}
        </Text>
        <Flex alignItems="center">
          { !this.isOrderEmpty(this.props.wholesaleOrdersStore!.currentOpenOrders) && (
            <Box
              data-testid={this.state.isEditing ? TEST_IDS.WholesaleShop.basket.view : TEST_IDS.WholesaleShop.basket.edit}
              ml="20px"
              as="button"
              onClick={this.toggleEditMode}
            >
              <StyledLink>
                {this.state.isEditing ? 'View' : 'Edit'}
              </StyledLink>
            </Box>
          )}
          <Box
            ml="15px"
            as="button"
            onClick={this.handleClose}
          >
            <StyledLink>
              Close
            </StyledLink>
          </Box>
        </Flex>
      </Header>
    );
  };

  private renderItems = (wholesaleAggregate: AggregatedWholesaleOrder): ReactNode => {
    return wholesaleAggregate.order.map(item => {
      return (
        <WholesaleShopOrderSummaryItem
          key={item.lineItems[0].id + this.state.isEditing}
          item={item}
          currency={wholesaleAggregate.currency}
          onRemove={this.handleRemove}
          isEditing={this.state.isEditing}
          isDisabled={this.state.isCancellingItem}
          orderId={wholesaleAggregate.orderId}
          supplier={wholesaleAggregate?.supplier || null}
        />
      );
    });
  };

  private renderTotals = (wholesaleOrder: AggregatedWholesaleOrder): ReactNode => {
    const order = this.props.wholesaleOrdersStore!.currentOpenOrders?.find(item => item?.supplier?.id === wholesaleOrder.supplier.id);
    const orderPrice = order?.totalPrice || 0;
    const deliveryPrice = order?.deliveryDetails?.[0]?.costValue || 0;
    const taxAmount = WholesaleCheckoutHelpers.orderTaxValue(order!);
    const isFreeShipping = deliveryPrice === 0;
    const totalForFreeDelivery = TOTALS_FOR_FREE_DELIVERY.find(totals => {
      return totals.supplier === wholesaleOrder.supplier.slug;
    })?.totalForFreeDelivery || DEFAULT_TOTAL_FOR_FREE_DELIVERY;

    return (
      <Fragment>
        <Totals>
          <TotalRow pt="10px">
            <SubTotalHeading>
              Subtotal
            </SubTotalHeading>
            <SubTotalValue>
              {WholesaleCheckoutHelpers.renderWholesalePrice(orderPrice, wholesaleOrder.currency)}
            </SubTotalValue>
          </TotalRow>
          <TotalRow pt="10px">
            <SubTotalHeading>
              Delivery charge
            </SubTotalHeading>
            <SubTotalValue
              color={isFreeShipping ? 'validationText' : 'floomMidnightBlue'}
            >
              {((): string => {
                switch (true) {
                  case isFreeShipping:
                    return 'FREE';

                  default:
                    return WholesaleCheckoutHelpers.renderWholesalePrice(deliveryPrice, wholesaleOrder.currency);
                }
              })()}
            </SubTotalValue>
          </TotalRow>
          <TotalRow>
            <SubCopy>
              { isFreeShipping
                ? 'You have qualified for free shipping'
                : `${WholesaleCheckoutHelpers.renderWholesalePrice(totalForFreeDelivery - orderPrice, wholesaleOrder.currency)} more for free delivery`
              }
            </SubCopy>
          </TotalRow>
          { !!order?.tax && (
            <TotalRow pt="10px">
              <SubTotalHeading>
                {order.tax.name || 'Tax'} {order.tax.inclusive ? '(included)' : ''}
              </SubTotalHeading>
              <SubTotalValue>
                {WholesaleCheckoutHelpers.renderWholesalePrice(taxAmount, wholesaleOrder.currency)}
              </SubTotalValue>
            </TotalRow>
          )}
          <TotalRow
            hasDivider={true}
            p="15px 0"
          >
            <TotalHeading>
              Total
            </TotalHeading>
            <TotalValue>
              {WholesaleCheckoutHelpers.renderWholesalePrice(orderPrice + deliveryPrice + taxAmount, wholesaleOrder.currency)}
            </TotalValue>
          </TotalRow>
          {this.renderRemoval()}
        </Totals>
      </Fragment>
    );
  };

  private renderCountdown = (selectedOrder: AggregatedWholesaleOrder): ReactNode => {
    const countdownProps = this.props.wholesaleShopStore!.deliveryCountdownProps.find(props => props.supplierId === selectedOrder.supplier.id);
    const shouldDisplayCountdown = countdownProps
      && !!this.props.wholesaleShopStore?.nextAvailableDeliveryDates!.some(deliveryDate => deliveryDate.dateString);

    if (!shouldDisplayCountdown) return null;

    const itemCount = selectedOrder.order.length;
    const unitCount = selectedOrder.order.reduce((acc, curr) => {
      return acc + curr.lineItems.reduce((acc2, curr2) => {
        return acc2 + curr2.quantity;
      }, 0);
    }, 0);

    return (
      <CutOffHeader>
        <CutOffCountdown colour={countdownProps?.color}>Cut off in: {countdownProps?.copy}</CutOffCountdown>
        <CutOffUnitCount>{itemCount} {pluralize('item', itemCount)} | {unitCount} {pluralize('unit', unitCount)}</CutOffUnitCount>
      </CutOffHeader>
    );
  };

  private renderRemoval = (): ReactNode => {
    const shouldDisplayMessage = this.state.isEditing && !!this.state.removedItemTitle?.length;

    if (!shouldDisplayMessage) return null;

    return (
      <Notification colour={colors.validationBg}>
        You have successfully removed <strong>{this.state.removedItemTitle}</strong>.
      </Notification>
    );
  };

  private renderBody = (currentOpenOrdersItems: AggregatedWholesaleOrder[]): ReactNode => {
    const tabs = this.getOrderTabConfig(currentOpenOrdersItems);
    const selectedTabSupplierId = this.state.selectedOrderId ? this.state.selectedOrderId : currentOpenOrdersItems?.[0]?.orderId;
    const selectedOrder = currentOpenOrdersItems.find(order => order.orderId === selectedTabSupplierId);

    if (!selectedOrder) return null;

    return (
      <TabsRibbon
        tabs={tabs}
        active={selectedTabSupplierId}
        onSelect={this.selectOrder}
      >
        <BodyScroll key={selectedOrder.orderId}>
          {this.renderCountdown(selectedOrder)}
          <BodyInner>
            {this.renderItems(selectedOrder)}
            {this.renderTotals(selectedOrder)}
          </BodyInner>
        </BodyScroll>
      </TabsRibbon>
    );
  };

  private renderNoItems = (): ReactNode => {
    const config = ((): { uri: string; heading: string; copy: string } => {
      if (this.props.wholesaleOrdersStore?.hasError) {
        return {
          heading: 'Oops, we\'re having trouble finding your order currently',
          copy: 'Try refreshing the page',
          uri: wholesaleEmptyErrorSvg
        };
      }

      return {
        heading: 'You haven\'t ordered any items',
        copy: '',
        uri: wholesaleEmptySvg
      };
    })();

    return (
      <Fragment>
        <Box m="10px 10px 0">
          {this.renderRemoval()}
        </Box>
        <NoItemsWrapper>
          <NoItemsImg src={config.uri} />
          <Box mt="20px">
            <Box css={textStyles.h4}>
              {config.heading}
            </Box>
            <Box
              css={textStyles.body}
              mt="2px"
              color="shade60"
            >
              {config.copy}
            </Box>
          </Box>
        </NoItemsWrapper>
      </Fragment>
    );
  };

  render(): ReactNode {
    const { openOrderLoading, currentOpenOrdersItems } = this.props.wholesaleOrdersStore!;

    return (
      <Container>
        {this.renderHeader()}
        <WithLoading
          testId={TEST_IDS.WholesaleShop.basket.loading.active}
          isLoading={openOrderLoading}
          hasNoResults={!openOrderLoading && currentOpenOrdersItems?.every(item => !item.order.length)}
          renderNoResults={this.renderNoItems}
          loaderSize="small"
          marginTop="30px"
        >
          {this.renderBody(currentOpenOrdersItems)}
        </WithLoading>
      </Container>
    );
  }
}

export const WholesaleShopOrderSummary = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  wholesalePaymentStore: stores.wholesalePaymentStore,
  wholesaleShopStore: stores.wholesaleShopStore,
  merchantStore: stores.merchantStore,
  navigationStore: stores.navigationStore
}))(observer(WholesaleShopOrderSummaryView));
