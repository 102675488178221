import {
  FC,
  createContext,
  useContext,
  useMemo,
  Fragment
} from 'react';

import css from '@emotion/css';
import { CatalogItemHit } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Currency, WholesaleOrderLineItem } from 'generated-types.d';

import { CurrencyService } from 'lib';

import { fontSizes } from 'utils/rebass-theme';
import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';
import { TEST_IDS } from 'utils/test/data-test-ids';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { listItemImage } from 'features/lists/lists.helpers';

import Lozenge from 'components/lozenge';

import {
  Content,
  ImageContainer,
  LozengeContainerDesktop,
  LozengeContainerMobile,
  Image as ImageStyle,
  ImageDesktop as ImageStyleDesktop,
  Metadata,
  PriceWrapper,
  Title,
  SubTitle,
  Wrapper,
  Price,
  PricePrefix
} from './wholesale-shop-list-item.styles';
import { WholesaleShopListItemProps } from './wholesale-shop-list-item.types';

const ItemContext = createContext<{
  item?: CatalogItemHit;
}>({});

const imageUrls = (item: CatalogItemHit, supplierId: string | undefined): string[] => {
  const images = !supplierId ? item?.images : item?.images?.filter(image => image.supplier === supplierId);

  // If supplier specific image is not available, use the top level one
  if (!images.length) {
    const placeholderImage = item?.images.filter(image => image.supplier === '');
    images.push(...placeholderImage);
  }

  return images?.reduce((acc: string[], curr): string[] => [...acc, ...curr.fileName.map((name: string) => `${curr.url}/${name}`)], []);
};

const getPrice = (hit: CatalogItemHit, supplierId: string | undefined): number => {
  if (!hit.livePriceRange) {
    return hit.priceRange.min;
  }

  const fallback = hit.livePriceRange.min;
  const selectedLivePriceRange = supplierId ?
    hit.supplierLivePriceRanges.find(priceRange => priceRange.supplierId === supplierId) :
    hit.livePriceRange;

  return selectedLivePriceRange?.min || fallback;
};

const PriceRange: FC<{ supplierId: string | undefined }> = ({ supplierId }) => {
  const { item } = useContext(ItemContext);

  return (
    <PriceWrapper>
      <PricePrefix>
        From
      </PricePrefix>
      <Price data-testid={TEST_IDS.WholesaleShop.catalogListItemPrice} >
        {CurrencyService.formatPrice(getPrice(item!, supplierId), Currency.Gbp)}
      </Price>
    </PriceWrapper>
  );
};

const Image: FC<{ screen?: 'desktop'; supplierId?: string }> = ({ screen, supplierId }) => {
  const { item } = useContext(ItemContext);
  const imageURLs = imageUrls(item!, supplierId);
  const imageURL = listItemImage(imageURLs, true);

  if (screen === 'desktop') {
    return (
      <ImageStyleDesktop
        className="lazyload"
        data-bg={imageURL}
        key={imageURL}
      />
    );
  }

  return (
    <ImageStyle
      className="lazyload"
      data-bg={imageURL}
      key={imageURL}
    />
  );
};

const ItemTitle: FC<{ isFriendlyNameActive: boolean; item: CatalogItemHit }> = ({ isFriendlyNameActive, item }) => {
  if (isFriendlyNameActive) {
    return (
      <Fragment>
        <Title>
          {item?.primaryName || item?.title}
        </Title>
        {item.secondaryName && (
          <SubTitle>
            {item.secondaryName}
          </SubTitle>
        )}
      </Fragment>
    );
  }

  return (
    <Title>
      {item?.title}
    </Title>
  );
};

export const WholesaleShopListItem: FC<WholesaleShopListItemProps> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  catalogItemStore: stores.catalogItemStore,
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  wholesaleOrdersStore,
  catalogItemStore,
  wholesaleShopStore,
  item,
  index
}) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();
  const isItemInCurrentOrder = useMemo<boolean>(() => {
    const orderItems = wholesaleOrdersStore!.currentOpenOrders?.reduce<WholesaleOrderLineItem[]>((acc, curr) => [...acc, ...(curr?.items || [])], []);

    return orderItems.some(orderItem => orderItem.snapshot.catalog_item_id === item.objectID);
  }, [
    wholesaleOrdersStore!.currentOpenOrders,
    catalogItemStore!.catalogItem?.id
  ]);

  return (
    <ItemContext.Provider value={{ item }}>
      <Wrapper key={item.objectID}>
        <Image supplierId={wholesaleShopStore!.selectedSupplier?.id} />
        <ImageContainer data-testid={`${TEST_IDS.WholesaleShop.catalogListItemImage}-${index + 1}`}>
          <Image
            screen="desktop"
            supplierId={wholesaleShopStore!.selectedSupplier?.id}
          />
          {isItemInCurrentOrder && (
            <LozengeContainerDesktop>
              <Lozenge
                copy="Purchased"
                bg={ColourOption.green}
                styles={css`
                  display: block;
                  font-size: 11px;
                  padding: 4px 7px 6px;
                `}
              />
            </LozengeContainerDesktop>
          )}
        </ImageContainer>
        <Content data-testid={`${TEST_IDS.WholesaleShop.catalogListItemDetails}-${index + 1}`}>
          {isItemInCurrentOrder && (
            <LozengeContainerMobile>
              <Lozenge
                copy="Purchased"
                bg={ColourOption.green}
                styles={css`
                  display: inline-block;
                  font-size: ${fontSizes.xSmall}px;
                  margin-bottom: 5px;
                  padding-bottom: 6px;
                `}
              />
            </LozengeContainerMobile>
          )}
          <Box mb={3}>
            <ItemTitle
              item={item}
              isFriendlyNameActive={wsFriendlyNames.isActive}
            />
          </Box>
          <Metadata isFrienlyNameActive={wsFriendlyNames.isActive}>
            <PriceRange supplierId={wholesaleShopStore!.selectedSupplier?.id} />
          </Metadata>
        </Content>
      </Wrapper>
    </ItemContext.Provider>
  );
}));
