import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, fontSizes, textStyles } from 'utils/rebass-theme';

export const Tabs = styled(Box)`
  display: inline-flex;
  width: 100%;
  background-color: ${colors.background};
  border-bottom: 1px solid ${colors.lighterGray};
  border-top: 1px solid ${colors.lighterGray};
`;

export const Title = styled(Box)`
  text-align: left;
  ${textStyles.subhead};
  color: ${(props): any => props.disabled ? colors.middleGrey : ''};
`;

export const SubTitle = styled(Box)`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.xSmall}px;
`;

export const TabItem = styled(Box)<{
  disabled: boolean;
  background: string;
  tabsLength: number;
  isActive: boolean;
}>`
  position: relative;
  padding: 10px 20px;
  border-right: 1px solid ${colors.lighterGray};
  transition: background-color .2s ease-in-out;
  background: ${props => props.disabled ? colors.background : ''};
  cursor: ${props => !props.isActive && !props.disabled ? 'pointer' : 'default'};

  + ${(): any => TabItem} {
    &:last-of-type {
      border-right: none;
    }
  }

  ${props => props.tabsLength && `
    flex: 0 1 ${props.tabsLength > 1 ? (100 / props.tabsLength) : 50}%;
  `}

  ${props => props.isActive && `
    background-color: ${colors.uiFills};
    margin-bottom: -1px;
  `}
`;
