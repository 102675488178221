import React, { Component, ReactNode } from 'react';

import moment from 'moment';
import { Box } from 'rebass';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';
import { textStyles } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';
import { ORDER_PERMISSIONS } from 'features/orders/orders.constants';

import FieldDatePicker from 'components/field-date-picker';

import { Subheading } from '../../order-detail.styles';

import * as Types from './delivery-date.types';

export default class DeliveryDate extends Component<Types.DeliveryDateProps> {
  render(): ReactNode {
    return(
      <>
        <Subheading>Delivery date</Subheading>
        <Box css={textStyles.body}>
          {OrderHelpers.setDeliveryDate(this.props.order)}
        </Box>
        <Box mt="10px">
          <FloomXRestrictedComponent
            restrictedRoles={ORDER_PERMISSIONS.deliverAt.restrictedRoles}
            shouldOverrideRoles={this.props.shouldOverrideRoles('deliverAt')}
          >
            <FieldDatePicker
              onChange={(day: Date): void => {
                this.props.onUpdate(moment(day).toISOString());
              }}
              value={this.props.order.deliverAt}
              inputType="button"
              disabledDays={{
                before: new Date()
              }}
            />
          </FloomXRestrictedComponent>
        </Box>
      </>
    );
  }
}
