import styled from '@emotion/styled';
import { Box } from 'rebass';

import { mq } from 'utils/css-mixins/css-mixins';
import { textStyles, colors } from 'utils/rebass-theme';

import { SectionHeadingSize } from './section-heading.types';

export const SectionHeading: any = styled(Box)<{ size: SectionHeadingSize }>`
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin: ${({ hasSpacing: hs }: any): any => hs ? '40px 0 30px 0' : 0};

  ${({ size }): any => {
    switch (size) {
      case 'normal': return textStyles.title;

      case 'small': return textStyles.subhead;
    }
  }};

  ${mq.greaterThan('medium')} {
    margin: ${({ hasSpacing: hs }: any): any => hs ? '50px 0 30px 0' : 0};
    border-bottom: 1px solid ${colors.middleGrey};
    padding-bottom: 12px;
  }
`;

export const GroupCount = styled.span`
  color: ${colors.middleGrey};
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
`;
