/* eslint-disable camelcase */

import { UserRole, Channel, ProductVariationType } from 'generated-types.d';

export enum FxPageEvent {
  ProductsList = 'Products list',
  ProductEdit = 'Product edit',
  MerchantHolidays = 'Merchant Holidays',
  Login = 'Login',
  InsightsComingSoon = 'Insights coming soon',
  LearningComingSoon = 'Learning coming soon',
  WholesaleComingSoon = 'Wholesale coming soon',
  Overview = 'Overview',
  Settings = 'Settings',
  SettingsMailchimp = 'Settings - Mailchimp confirmation',
  SettingsCustomSite = 'Settings - custom site',
  SettingsCustomSiteSettings = 'Settings - custom site settings',
  SettingsPayments = 'Settings - payments',
  SettingsAccount = 'Settings - account details',
  SettingsDelivery = 'Settings - delivery config',
  SettingsDeliveryCoverage = 'Settings - delivery coverage areas',
  SettingsMerchantsList = 'SettingsMerchantsList',
  Learning = 'Learning',
  Orders = 'Orders home',
  OrdersList = 'Orders list',
  OrdersDetail = 'Orders detail',
  WholesaleOrdersDetail = 'Wholesale orders detail',
  WholesalePreOrderDetail = 'Wholesale pre-order detail',
  OrdersSearch = 'Orders search',
  AddonsList = 'Add-ons list',
  AddonsCreate = 'Add-ons create',
  AddonsEdit = 'Add-ons edit'
}

export enum FxEventName {
  ClickNavigation = 'Click navigation',
  TermsViewed = 'Terms viewed',
  TermsAccepted = 'Terms accepted',
  SignedIn = 'Signed in',
  SignedOut = 'Signed out',
  ProductEditAvailability = 'Product edit availability',
  ProductCreateStep = 'Product create step',
  ProductEditSave = 'Product edit save and leave',
  ProductEditStock = 'Product edited stock',
  OrderViewList = 'Orders view list',
  OrderViewDetail = 'Orders view detail',
  OrderStatusUpdate = 'Order edited status',
  OrderSearch = 'Order search',
  DeliveryConfigEdit = 'Delivery edited delivery config',
  SettingsViewSection = 'Settings view section',
  SettingsOverview = 'Settings view overview',
  SettingsMerchantHolidays = 'Settings view merchant holidays',
  SettingsMerchantsList = 'Settings view merchants list',
  SettingsMerchantSearch = 'Merchant list search',
  SettingsDiscountSearch = 'Discount list search',
  ToggleMerchantFeature = 'Merchant feature toggled',
  OnboardingComplete = 'Onboarding complete',
  CreateListItemOnList = 'Create list item on list'
}

export type SegmentEvent =
  | SegmentEventType.OnboardingComplete
  | SegmentEventType.ClickNavEvent
  | SegmentEventType.TermsViewedEvent
  | SegmentEventType.TermsAcceptedEvent
  | SegmentEventType.SignedInEvent
  | SegmentEventType.SignedOutEvent
  | SegmentEventType.ProductCreateStep
  | SegmentEventType.ProductEditAvailability
  | SegmentEventType.ProductEditStock
  | SegmentEventType.OrderViewList
  | SegmentEventType.OrderEditStatus
  | SegmentEventType.OrderSearch
  | SegmentEventType.DeliveryConfigEdit
  | SegmentEventType.SettingsViewSection
  | SegmentEventType.SettingsOverview
  | SegmentEventType.SettingsMerchantHolidays
  | SegmentEventType.SettingsMerchantsList
  | SegmentEventType.ToggleMerchantFeature
  | SegmentEventType.CreateListItemOnList
  | SegmentEventType.SettingsMerchantSearch
  | SegmentEventType.SettingsDiscountSearch;

export type UserType = 'Merchant' | 'Internal';

export type ProductCreateTitle =
  | 'Click create'
  | 'Choose type'
  | 'Product'
  | 'Recipe'
  | 'Add variation'
  | 'Addon'
  | 'Delivery'
  | 'Save';

export enum ProductAvailabilityEntry {
  EditPage = 'Product edit page',
  List = 'Product list',
  Grid = 'Product grid'
}

export enum ProductStockEntry {
  EditPage = 'Product edit page',
  List = 'Product list',
  Grid = 'Product grid'
}

export interface OrderGlobalProperties {
  order_no: string;
  order_id: string;
  channel: Channel;
  same_day?: boolean;
  delivery_date: string;
}

export enum OrderEditEntry {
  OrderList = 'Order list',
  OrderDetail = 'Order detail'
}

export enum OrderListEntry {
  Nav = 'Navigation',
  Overview = 'Orders overview',
  AppNav = 'App navigation'
}

export enum OrderSearchEntry {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Upcoming = 'All upcoming',
  Past = 'Past orders',
  OrdersOverview = 'Orders overview',
  Search = 'Search all orders'
}

export enum SettingsSection {
  Account = 'Account Details',
  Delivery = 'Delivery',
  DeliveryCoverage = 'Delivery Coverage Areas',
  Payment = 'Payment',
  CompanyInfo = 'Company info',
  MyPlan = 'My plan',
  BussinessLocations = 'Bussiness locations',
  MyTeam = 'My team',
  ProductSettings = 'Product settings',
  OrderSettings = 'Order settings',
  Insights = 'Insights',
  WholesaleSettings = 'Wholesale settings',
  CustomSite = 'Custom site',
  CustomSiteSettings = 'Custom site settings',
  Floom = 'Floom',
  POS = 'POS'
}

export enum SettingsEntryPoint {
  WebOverview = 'Web overview',
  AppOverview = 'App overview'
}

export enum SettingsOverviewEntry {
  WebNav = 'Web navigation',
  AppToolbar = 	'App toolbar'
}

export enum TermsEntryPoint {
  Settings = 'settings',
  Onboarding = 'onboarding',
  MerchantCreation = 'merchant-creation',
  MerchantDetail = 'merchant-detail'
}

export declare namespace SegmentEventType {
  export interface OnboardingComplete {
    event_version: 1;
  }

  export interface ProductCreateStep {
    event_version: 2;
    creation_session_id: string;
    step: ProductCreateTitle;
    product_type: string;
  }

  export interface ProductEditAvailability {
    event_version: 2;
    available: boolean;
    entry_point: ProductAvailabilityEntry;
    in_app: boolean;
  }

  export interface ProductEditStock {
    event_version: 2;
    entry_point: ProductStockEntry;
    in_app: boolean;
    stock_level: number | { [key in ProductVariationType]: number };
    set_to_out_of_stock: boolean;
    variation_type: string;
  }

  export interface OrderViewList {
    event_version: 1;
    entry_point: OrderListEntry;
    list: OrderSearchEntry;
    in_app: boolean;
  }

  export interface OrderEditStatus extends OrderGlobalProperties {
    event_version: 1;
    entry_point: OrderEditEntry;
    in_app: boolean;
    from_status: string;
    to_status: string;
    minutes_since_order_created: number;
  }

  export interface OrderViewDetail extends OrderGlobalProperties {
    event_version: 1;
    list?: OrderSearchEntry;
    in_app: boolean;
  }

  export interface OrderSearch {
    event_version: 1;
    entry_point: OrderSearchEntry;
    query: string;
    in_app: boolean;
  }

  export interface DeliveryConfigEdit {
    event_version: 1;
    enabled_all: boolean;
    disabled_all: boolean;
  }

  export interface ClickNavEvent {
    item: string;
    section: string;
    is_sub_item: boolean;
  }

  export interface TermsViewedEvent {
    event_version: 1;
    entry_point: TermsEntryPoint;
    terms_last_edited: string;
    terms_version: number;
  }

  export interface TermsAcceptedEvent {
    event_version: 2;
    terms_last_edited: string;
    terms_version: number;
    merchant_name: string;
    merchant_email: string;
    merchant_id: string;
  }

  export interface SignedInEvent {
    event_version: 1;
    username: string;
    context: {
      group_id: string;
    };
    merchant_title: string;
    user_type: UserType;
    role: UserRole;
  }

  export interface SignedOutEvent {
    event_version: 1;
    username: string;
    context: {
      group_id: string;
    };
    merchant_title: string;
    user_type: UserType;
    role: UserRole;
  }

  export interface ClickNavEvent {
    event_version: 1;
    item: string;
    section: string;
    is_sub_item: boolean;
  }

  export interface SettingsViewSection {
    event_version: 1;
    in_app: boolean;
    entry_point: SettingsEntryPoint;
    section: SettingsSection;
  }

  export interface SettingsOverview {
    event_version: 1;
    in_app: boolean;
    entry_point: SettingsOverviewEntry;
  }

  export interface SettingsMerchantHolidays {
    event_version: 1;
    in_app: boolean;
    entry_point: SettingsOverviewEntry;
  }

  export interface SettingsMerchantsList {
    event_version: 1;
    in_app: boolean;
    entry_point: SettingsOverviewEntry;
    user_role: UserRole;
  }

  export interface SettingsMerchantSearch {
    event_version: 1;
    entry_point: 'Merchant list';
    query: string;
    in_app: boolean;
  }

  export interface SettingsDiscountSearch {
    event_version: 1;
    entry_point: 'Discount list';
    query: string;
    in_app: boolean;
  }

  export interface ToggleMerchantFeature {
    event_version: 1;
    entry_point: 'Merchant detail';
    query: string;
    in_app: boolean;
  }

  export interface CreateListItemOnList {
    event_version: 1;
    entry_point: 'List details';
    interacted_with: 'Catalog button' | 'Custom button' | 'Tab create';
    query: string;
    in_app: boolean;
  }
}
