import { FC, Fragment } from 'react';

import {
  SubTitle,
  TabItem,
  Tabs,
  Title
} from './tabs-ribbon.styles';
import {
  TabsRibbonProps
} from './tabs-ribbon.types';

export const TabsRibbon: FC<TabsRibbonProps> = ({
  tabs,
  content,
  active,
  children,
  onSelect
}) => {
  return (
    <Fragment>
      <Tabs>
        {tabs.map(({
          disabled,
          subtitle,
          tabId,
          title
        }) => (
          <TabItem
            key={tabId}
            disabled={disabled}
            onClick={(): void | boolean => !disabled && onSelect(tabId)}
            isActive={tabId === active}
            tabsLength={tabs.length}
          >
            <Title disabled={disabled}>
              {title}
            </Title>
            {!!subtitle && (
              <SubTitle>
                {subtitle}
              </SubTitle>
            )}
          </TabItem>
        ))}
      </Tabs>
      {content || children}
    </Fragment>
  );
};
