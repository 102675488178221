import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Content: any = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchBox: any = styled.div`
  padding: 20px;
`;

export const Items: any = styled(Flex)`
  justify-content: flex-start;
  flex-direction: column;
  flex-flow: row wrap;
  max-height: 330px;
  background: ${colors.uiFills};
  overflow-x: hidden;
  border-top: solid 1px ${colors.middleGrey};
  border-bottom: solid 1px ${colors.middleGrey};
`;

export const ItemsWrapper: any = styled.div`
  height: 330px;
  width: 100%;
  background: ${colors.uiFills};
`;

export const Item: any = styled(Flex)`
  ${textStyles.subhead};
  flex-direction: row;
  width: 100%;
  border-top: solid 1px ${colors.middleGrey};
  border-bottom: solid 1px ${colors.middleGrey};
  align-items: center;
  background: ${colors.uiFills};
  margin-top: -1px;
  height: 68px;
  cursor: ${(props: any): any => props.disabled ? 'normal' : 'pointer'};
  background: ${colors.uiFills};
  color: ${colors.floomMidnightBlue};
  pointer-events: ${(props: any): any => props.disabled || props.edit ? 'none' : 'auto'};

  &:hover {
    background: ${colors.lightGrey};
  }
`;

export const ItemMedia: any = css`
  height: 46px;
  width: 46px;
  margin: 10px 10px 10px 23px;
`;

export const ItemTitle: any = styled.div`
  margin-left: 20px;
`;

export const ItemDetails: any = styled.div`
  margin-left: 10px;
  font-weight: 400;
`;

export const ItemIcon: any = styled.div`
  height: 46px;
  width: 46px;
  margin: 22px 10px 10px 23px;
  > svg {
    height: 20px;
    width: 46px;
  }
`;
