import styled from '@emotion/styled';
import { Flex, Box, Text } from 'rebass';

import {
  breakpoints,
  colors,
  textStyles,
  borderRadius
} from 'utils/rebass-theme';

export const Header = styled(Flex)`
  ${textStyles.footnote};
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 15px 20px 17px;
  color: ${colors.floomMidnightBlue};
  background: ${colors.lightGrey};
`;

export const CutOffHeader = styled(Flex)`
  margin: 10px;
  background: ${colors.offWhite};
  border-radius: ${borderRadius.large};
  padding: 10px;
  font-size: 14px;
  justify-content: space-between;
`;

export const CutOffCountdown = styled(Box)<{ colour: string }>`
  font-weight: 700;
  color: ${({ colour }): string => colour};
`;

export const CutOffUnitCount = styled(Box)`
  color: ${colors.grey};
`;

export const Notification = styled(Box)<{ colour: string }>`
  ${textStyles.body}

  background: ${({ colour }): string => colour};
  color: ${colors.floomMidnightBlue};
  border-radius: ${borderRadius.small};
  padding: 10px 15px 12px;
  line-height: 19px;
`;

export const NoItemsWrapper = styled(Flex)`
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
`;

export const NoItemsImg = styled.img`
  width: 100%;
  max-width: 68px;
`;

export const Container = styled(Box)`
  width: 100%;
`;

export const Totals = styled(Box)`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${colors.middleGrey};
  position: sticky;
  bottom: 0;
  align-self: flex-end;
  background: ${colors.uiFills};
`;

export const SubCopy = styled(Box)`
  color: ${colors.shade40};
  font-size: 12px;
  margin-top: 2px;
`;

export const TotalRow = styled(Flex)<{ hasDivider?: boolean }>`
  justify-content: space-between;

  ${(props): any => {
    if (props.hasDivider) {
      return `
        margin-top: 20px;
        border-top: 1px solid ${colors.middleGrey};
      `;
    }
  }}
`;

export const SubTotalHeading = styled(Flex)`
  ${textStyles.h4}
  font-weight: 100;
`;

export const TotalHeading = styled(Flex)`
  ${textStyles.h4}
  font-size: 16px;
`;

export const SubTotalValue = styled(Text)`
  ${textStyles.body}
`;

export const TotalValue = styled(Text)`
  ${textStyles.body}
  font-size: 16px;
  font-weight: 700;
`;

export const BodyScroll = styled(Box)`
  overflow-y: auto;
  height: calc(100vh - 245px);

  @media (max-width: ${breakpoints.medium}) {
    height: calc(100% - 110px);
  }
`;

export const BodyInner = styled(Box)`
  padding: 0 20px;

  > div:first-of-type {
    border: none;
  }
`;
