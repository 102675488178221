import { FC, useEffect, ReactElement, useRef } from 'react';

import { CatalogItemHit, NavPages } from 'global.types';
import { connectHits, connectStateResults } from 'react-instantsearch-core';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { useWindowScroll } from 'hooks/useWindowScroll/useWindowScroll';

import ModalTrigger from 'components/modal-trigger';

import { WholesaleShopListItem } from '../wholesale-shop-list-item/wholesale-shop-list-item';

import {
  Wrapper,
  ItemWrapper
} from './wholesale-shop-list.styles';

const Hits = connectHits<CatalogItemHit>(({
  hits
}): any => {
  return hits.map((hit, index) => {
    return (
      <ItemWrapper
        key={hit.objectID}
        data-testid={`${TEST_IDS.WholesaleShop.catalogListItem}-${index + 1}`}
      >
        <ModalTrigger
          to={`${NavPages.WholesaleItem}${hit.objectID}`}
          modalType="wholesale-shop-listing"
          id={hit.objectID}
          preventScroll={true}
        >
          <WholesaleShopListItem
            key={hit.objectID}
            item={hit}
            index={index}
          />
        </ModalTrigger>
      </ItemWrapper>
    );
  });
});

const ScrollToTop: FC<{searchResults: any; children: ReactElement}> =
  ({ searchResults,
     children
  }: {searchResults: any; children: ReactElement}) => {
    const numberHits = useRef();
    const firstItem = useRef<CatalogItemHit>();
    const scrollPosition = useWindowScroll();

    useEffect(() => {
      if (scrollPosition > 0 && isSearchStateChanged()) {
        window?.scrollTo?.({
          top: 0
        });
      }

      if (searchResults) {
        numberHits.current = searchResults.nbHits;
        firstItem.current = searchResults.hits[0];
      }
    }, [searchResults]);

    const isSearchStateChanged = (): boolean => {
      return !!numberHits.current && !!firstItem.current && !!searchResults
        && (searchResults.nbHits !== numberHits.current || searchResults.hits?.[0].objectID !== firstItem.current?.objectID);
    };

    return children;
  };

const ScrollTo = connectStateResults(ScrollToTop);

export const WholesaleShopList: FC = () => {
  return (
    <Wrapper data-testid={TEST_IDS.WholesaleShop.catalogList}>
      <ScrollTo>
        <Hits />
      </ScrollTo>
    </Wrapper>
  );
};
