import { ReactNode } from 'react';

import { ModalType } from 'stores/modal-store/modal-store.types';

import { CatalogExplorerModal } from '../modals/catalog-explorer-modal/catalog-explorer-modal';
import { CatalogFiltersModal } from '../modals/catalog-filters-modal/catalog-filters-modal';
import ConfirmationModal from '../modals/confirmation-modal';
import ConversationContactListModal from '../modals/conversation-contact-list-modal/conversation-contact-list-modal';
import ConversationContactNewModal from '../modals/conversation-contact-new-modal';
import CourierCancelConfirmation from '../modals/courier-cancel-confirmation-modal';
import CourierStatusModal from '../modals/courier-status-modal';
import CustomSiteConfigModal from '../modals/custom-site-config-modal';
import DateRangeModal from '../modals/date-range-modal';
import EditDeliveryConfigModal from '../modals/delivery-config-modal';
import DeliveryCoverageModal from '../modals/delivery-coverage-modal';
import EntitySelector from '../modals/entity-selector-modal';
import FieldEditModal from '../modals/field-edit-modal';
import { ListDetailsModal } from '../modals/list-details-modal';
import { ListItemDetailsModal } from '../modals/list-item-details-modal';
import ListSettingsSidebar from '../modals/list-settings-sidebar';
import OrderNotesModal from '../modals/order-notes-modal';
import OrderingRulesSidebar from '../modals/ordering-rules-sidebar';
import PlanShiftModal from '../modals/plan-shift-model';
import PlanUpgradeModal from '../modals/plan-upgrade-modal';
import { PreOrderConfirmationModal } from '../modals/pre-order-confirmation-modal/pre-order-confirmation-modal';
import StageShiftModal from '../modals/stage-shift-modal';
import SubscriptionPaymentModal from '../modals/subscription-payment-modal';
import SupplierIntegrationModal from '../modals/supplier-integration-modal';
import TagSelectionModal from '../modals/tag-selection-modal';
import TypeFormSelectModal from '../modals/typeform-select-modal';
import WholesaleTierModal from '../modals/wholesale-tier-modal';

type ModalComponents = { [key in ModalType]: ReactNode };

export const MODAL_COMPONENTS: ModalComponents = {
  confirmation: ConfirmationModal,
  courierCancelConfirmation: CourierCancelConfirmation,
  courierStatus: CourierStatusModal,
  customSiteConfig: CustomSiteConfigModal,
  dateRange: DateRangeModal,
  deliveryCoverage: DeliveryCoverageModal,
  deliveryConfig: EditDeliveryConfigModal,
  entitySelector: EntitySelector,
  fieldEdit: FieldEditModal,
  listItemDetails: ListItemDetailsModal,
  listSettingsSidebar: ListSettingsSidebar,
  orderNotes: OrderNotesModal,
  orderingRulesSidebar: OrderingRulesSidebar,
  planShift: PlanShiftModal,
  planUpgrade: PlanUpgradeModal,
  preOrderConfirmation: PreOrderConfirmationModal,
  stageShift: StageShiftModal,
  catalogExplorer: CatalogExplorerModal,
  catalogFilters: CatalogFiltersModal,
  subscriptionPayment: SubscriptionPaymentModal,
  tagSelection: TagSelectionModal,
  wholesaleTier: WholesaleTierModal,
  conversationContactList: ConversationContactListModal,
  conversationContactNew: ConversationContactNewModal,
  listDetails: ListDetailsModal,
  supplierIntegration: SupplierIntegrationModal,
  typeFormSelect: TypeFormSelectModal
};
