// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-features-marketing-pages-page-page-tsx": () => import("./../../../src/features/marketing/pages/page/page.tsx" /* webpackChunkName: "component---src-features-marketing-pages-page-page-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-add-ons-index-tsx": () => import("./../../../src/pages/add-ons/index.tsx" /* webpackChunkName: "component---src-pages-add-ons-index-tsx" */),
  "component---src-pages-conversations-index-tsx": () => import("./../../../src/pages/conversations/index.tsx" /* webpackChunkName: "component---src-pages-conversations-index-tsx" */),
  "component---src-pages-courier-index-tsx": () => import("./../../../src/pages/courier/index.tsx" /* webpackChunkName: "component---src-pages-courier-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-learning-index-tsx": () => import("./../../../src/pages/learning/index.tsx" /* webpackChunkName: "component---src-pages-learning-index-tsx" */),
  "component---src-pages-lists-index-tsx": () => import("./../../../src/pages/lists/index.tsx" /* webpackChunkName: "component---src-pages-lists-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-merchant-index-tsx": () => import("./../../../src/pages/merchant/index.tsx" /* webpackChunkName: "component---src-pages-merchant-index-tsx" */),
  "component---src-pages-my-plan-index-tsx": () => import("./../../../src/pages/my-plan/index.tsx" /* webpackChunkName: "component---src-pages-my-plan-index-tsx" */),
  "component---src-pages-onboarding-index-tsx": () => import("./../../../src/pages/onboarding/index.tsx" /* webpackChunkName: "component---src-pages-onboarding-index-tsx" */),
  "component---src-pages-orders-index-tsx": () => import("./../../../src/pages/orders/index.tsx" /* webpackChunkName: "component---src-pages-orders-index-tsx" */),
  "component---src-pages-overview-index-tsx": () => import("./../../../src/pages/overview/index.tsx" /* webpackChunkName: "component---src-pages-overview-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-settings-index-tsx": () => import("./../../../src/pages/settings/index.tsx" /* webpackChunkName: "component---src-pages-settings-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-wholesale-index-tsx": () => import("./../../../src/pages/wholesale/index.tsx" /* webpackChunkName: "component---src-pages-wholesale-index-tsx" */)
}

