import { Component, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import { Heading, Flex, Box } from 'rebass';

import { MerchantService, NavService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import { OrdersStore } from 'stores/orders/orders-store.types';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import OrderPauseBanner from 'features/order-pause-banner';
import { OrderListConfig } from 'features/orders/graphql/helpers/order-group.config';

import Icon from 'components/icon';

import * as Styles from './list-heading.styles';

interface OrdersListHeadingProps {
  ordersStore?: OrdersStore;
  merchantStore?: MerchantStore;
  config: OrderListConfig;
}

class OrderListHeading extends Component<OrdersListHeadingProps> {
  private buildSubheading = (subHeading: string): string => {
    if (RegExp(/\[COUNT\]/g).test(subHeading)) {
      return subHeading
        .replace(/\[COUNT\]/g, this.calculateOpenOrderCount());
    }

    return subHeading;
  };

  private calculateOpenOrderCount = (): any => {
    return Object.keys(this.props.ordersStore!.orderList).reduce((accGroupCount, currGroup) => {
      if (!['delivered', 'cancelled'].includes(currGroup)) {
        return accGroupCount + this.props.ordersStore!.orderList[currGroup].aggregate.count;
      }

      return accGroupCount;
    }, 0);
  };

  render(): JSX.Element {
    return(
      <Fragment>
        {MerchantService.hasOrderPauseSupport(this.props.merchantStore!.merchant) && (
          <Container>
            <Box m="20px 0 0">
              <OrderPauseBanner />
            </Box>
          </Container>
        )}
        <Styles.ListHeading>
          <Container>
            <Flex
              as="button"
              mb={['15px', '15px', '20px', '20px']}
              alignItems="center"
              onClick={(): void => NavService.orders()}
              css={textStyles.headline}
            >
              <Flex mr="10px">
                <Icon
                  iconName="arrow-full-left"
                  styles={Styles.BackArrow}
                />
              </Flex>
              <Box css={textStyles.subhead}>
                Orders Home
              </Box>
            </Flex>
            <Heading
              as="h1"
              css={textStyles.headline}
            >
              {this.props.config.heading}
            </Heading>
            { this.props.config.subHeading && (
              <Heading
                as="h2"
                mt="10px"
                css={textStyles.title}
              >
                {this.buildSubheading(this.props.config.subHeading)}
              </Heading>
            )}
          </Container>
        </Styles.ListHeading>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  merchantStore: stores.merchantStore
}))(observer(OrderListHeading));
