import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import {
  colors,
  borderRadius,
  textStyles
} from 'utils/rebass-theme';

export const Title = styled(Flex)`
  ${textStyles.h3};
  align-items: flex-start;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  font-size: 16px;
  justify-content: space-between;
`;

export const Wrapper = styled(Flex)`
  flex-direction: row;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${colors.middleGrey};
`;

export const Image = styled.img`
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: ${borderRadius.large};
`;

export const Body = styled(Flex)`
  font-size: 14px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

export const Meta = styled.div`
  ${textStyles.body}
  color: ${colors.shade40};
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
`;

export const Timer = styled.span`
  color: ${colors.shade40};
  font-size: 12px;
`;

export const Prices = styled(Flex)`
  ${textStyles.footnote};
  font-size: 14px;
  margin-bottom: 4px;
  align-items: flex-end;
  justify-content: space-between;
  color: ${colors.darkGrey};
`;

export const PriceDetails = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
`;

export const UnitPrice = styled.div`
  margin-top: 2px;
  font-size: 10px;
  font-weight: 100;
`;

export const EditContainer = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
`;

export const EditMode = styled(Flex)<{ isVisible: boolean; disabled: boolean }>`
  display: ${({ isVisible }): string => isVisible ? 'flex' : 'none'};
  cursor: ${({ disabled }): string => disabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ disabled }): number => disabled ? .7 : 1};
  color: ${colors.floomRed};
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  align-items: center;

  svg {
    width: 12px;
    margin-right: 4px;
    margin-top: 2px;

    path {
      fill: ${colors.floomRed};
    }
  }
`;

export const Error = styled(Box)`
  width: 100%;
`;
