import { OrderItem, Channel, Order, Maybe, OrderDeliveryConfig } from 'generated-types';
import moment from 'moment';

import { PermissionsService, TimeService } from 'lib';

import {
  FLORIST_STATUS_SLUGS,
  ORDER_PERMISSIONS,
  SAME_DAY_DELIVERY_CUTOFF
} from 'features/orders/orders.constants';
import { OrderPermissionOption, OrderStatusSlug, ExtendedOrder } from 'features/orders/orders.types';

class OrderHelpers {
  public shortenOrderNumber = (orderNo: string): string => orderNo.slice(0, 7);

  public orderItemTitle = (item: OrderItem): string => `${item.quantity}x ${this.getProductTitle(item.productSnapshot)}`;

  public orderItemTitleVariation = (item: OrderItem): string => `${item.quantity}x ${this.getProductTitle(item.productSnapshot)} (${this.getProductVariationTitle(item.productSnapshot)})`;

  public multipleItemsTitle = (orderItems: OrderItem[]): string => this.hasMultipleItems(orderItems)
    ? `${orderItems.length} Products`
    : this.orderItemTitle(orderItems[0]);

  public hasMultipleItems = (orderItems: OrderItem[]): boolean => orderItems.length > 1;

  public getProductTitle = (snap: any): string => snap.product ? snap.product.title : snap.title;

  public hasVariation = (snap: any): boolean => {
    return !!snap.variation;
  };

  public getProductImageUrl = (snap: any): string => {
    if (snap.imageUrl) {
      return snap.imageUrl;
    } else if (snap.product) {
      return snap.product.imageUrl;
    }

    return this.getVariationImageUrl(snap);
  };

  public getVariationImageUrl = (snap: any): string => {
    if (this.hasVariation(snap)) {
      return snap.variation.media[0].src;
    } else if (!!snap?.product?.variation) {
      return snap.product.variation.media[0].src;
    }

    return '';
  };

  public getProductVariationTitle = (snap: any): string => snap.variation ? snap.variation.title : '';

  public addOnsBuilder = (orderItems: OrderItem[]): any => {
    let totalCount = 0;
    const addOns = [];

    for (const item of orderItems) {
      if (item.addOnsSnapshot && item.addOnsSnapshot.length) {
        for (const addOn of item.addOnsSnapshot) {
          addOns.push(addOn);
          totalCount += addOn.quantity;
        }
      }
    }

    return {
      items: addOns,
      totalCount: totalCount
    };
  };

  public getStatusesAfterCurrent = (
    currentStatus: OrderStatusSlug,
    shouldReturnCurrent: boolean = true,
    statusesToFilter: OrderStatusSlug[] = []
  ): string[] => {
    let hasStatusMatch = false;

    return FLORIST_STATUS_SLUGS.filter(statusSlug => {
      if (statusSlug === currentStatus) {
        hasStatusMatch = true;

        return shouldReturnCurrent;
      }

      if (hasStatusMatch && !statusesToFilter.includes(statusSlug)) return true;

      return false;
    });
  };

  public hasCustomWebsitePermissions = (
    permissionOption: OrderPermissionOption,
    orderChannel: Channel
  ): boolean => {
    return orderChannel === 'Website'
      && ORDER_PERMISSIONS[permissionOption].floristChannelRoles
        .includes(PermissionsService.getUserRole());
  };

  public isAfterSameDayCutoff = (order: Order): boolean => {
    return TimeService.isTimeGreaterThan(
      order.orderedAt,
      SAME_DAY_DELIVERY_CUTOFF,
      order.merchant.timezone
    );
  };

  public isSameDay = (order: Order): boolean => {
    const deliverAtDate = moment(order.deliverAt).format('DD-MM-YYYY');
    const orderedAtDate = moment(order.orderedAt).tz(order.merchant.timezone).format('DD-MM-YYYY');

    return deliverAtDate === orderedAtDate;
  };

  public isDelivered = (order: Order): boolean => order.status.slug === OrderStatusSlug.Delivered;

  public hasFloomXDeliverySnap = (deliveryConfig: Maybe<OrderDeliveryConfig> = null): boolean => {
    return !!deliveryConfig?.deliveryConfigSnapshot?.deliveryTiming;
  };

  public getDeliveryTimingCutoffHour = (order: Order): number => {
    if (!this.hasFloomXDeliverySnap(order.orderDeliveryConfig)) return 14;

    return order.orderDeliveryConfig?.deliveryConfigSnapshot?.deliveryTiming?.cutOffHour === 13
      ? 14
      : order.orderDeliveryConfig!.deliveryConfigSnapshot.deliveryTiming.cutOffHour;
  };

  public getDeliveryTimeRange = (order: Order): string => {
    const cutoff = this.getDeliveryTimingCutoffHour(order);

    return `${moment(cutoff + 4, 'HH').format('h')}`;
  };

  public renderDeliveryTime = (order: Order): string => {
    const orderTime = this.getOrderedAt(order);
    const shouldCalculate = parseInt(orderTime) >= 13 && order.orderDeliveryConfig && order.orderDeliveryConfig.sameDay;

    return moment(order.deliverAt).utc().format(`Do MMM [by ${shouldCalculate ? this.getDeliveryTimeRange(order) : 6}pm]`);
  };

  public getOrderedAt = (order: Order): string => moment.tz(order.orderedAt, order.merchant!.timezone).format('H');

  public setDeliveryDate = (order: Order): any => {
    const orderTime = this.getOrderedAt(order);
    const shouldCalculate = orderTime.length && parseInt(orderTime) >= 14 && order.orderDeliveryConfig && order.orderDeliveryConfig.sameDay;
    const deliveryTime = shouldCalculate ? `10am and ${this.getDeliveryTimeRange(order)}pm` : '10am and 6pm';
    const deliveryDate = moment.utc(order.deliverAt).format('YYYY-MM-DD');

    return `${TimeService.humanDateMonthYear(deliveryDate)} between ${deliveryTime}`;
  };

  public isOrderInactive = (order: ExtendedOrder): boolean => [
    OrderStatusSlug.Delivered,
    OrderStatusSlug.Cancelled,
    OrderStatusSlug.FailedDelivery
  ]
    .includes(order.status.slug);
}

export default new OrderHelpers();
