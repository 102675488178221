import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import { InjectedMobxStores } from 'stores';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { FieldSelect } from 'components/field-select/field-select';

import {
  Container,
  DropdownContainer,
  Label
} from './wholesale-shop-supplier-dropdown.styles';

export interface DropdownOption {
  label: string;
  value: string;
}

const defaultOption: DropdownOption = {
  label: 'All',
  value: 'all'
};

export const WholesaleShopSupplierDropdown: FC<Pick<InjectedMobxStores,
| 'wholesaleShopStore'
>> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  wholesaleShopStore
}) => {
  const getOptions = (): DropdownOption[] => {
    const dropdownOptions = wholesaleShopStore?.merchantWholesaleSuppliers.map(merchantWholesaleSupplier => ({ label: merchantWholesaleSupplier?.supplier!.name, value: merchantWholesaleSupplier?.supplier!.name })) || [];

    return [defaultOption, ...dropdownOptions];
  };

  const onChange = (supplierName: any): void => {
    const foundSupplier = wholesaleShopStore!.merchantWholesaleSuppliers.find(merchantWholesaleSupplier => merchantWholesaleSupplier?.supplier?.name === supplierName);
    wholesaleShopStore!.setSelectedSupplier(foundSupplier?.supplier || null);
  };

  if (wholesaleShopStore!.merchantWholesaleSuppliers?.length < 2) return null;

  return (
    <Container>
      <Label>Supplier:</Label>
      <DropdownContainer data-testid={TEST_IDS.WholesaleShop.supplierDropdown}>
        <FieldSelect<DropdownOption>
          onSelect={onChange}
          selectProps={{
            inputId: 'supplier',
            placeholder: 'All',
            isSearchable: false,
            menuPlacement: 'bottom',
            menuPortalTarget: document.body,
            closeMenuOnScroll: true,
            value: {
              label: wholesaleShopStore?.selectedSupplier?.name || defaultOption.label,
              value: wholesaleShopStore?.selectedSupplier?.name || defaultOption.value
            }
          }}
          options={getOptions()}
        />
      </DropdownContainer>
    </Container>
  );
}));
