import {
  FC,
  ComponentType,
  Fragment,
  useEffect
} from 'react';

import { css, Global, ThemeProvider } from '@emotion/react';
import { WrapRootElementBrowserArgs } from 'gatsby';
import { GlobalStyles } from 'global.styles';
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { inject, observer, Provider as StoreProvider } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';
import stores, { InjectedMobxStores } from 'stores';

import { PermissionsService } from 'lib';

import theme from 'utils/rebass-theme';

import RootErrorBoundary from './components/root-error-boundary';

import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js';

/**
 * @desc Enforce an explicit anonyous user ID, the purpose
 * is to group all anonymous users as we are not targeting
 * experiments on them. This reduces our number of anonymous
 * Monthly active users (MAUs)
 *
 * {@link https://docs.launchdarkly.com/sdk/features/user-config#designating-anonymous-users}
 */
const SHARED_LD_ANONYMOUS_KEY = 'cl1ugdz8i000005jt70bggd2f';

const LDIdentify: FC<Pick<InjectedMobxStores, 'userStore'>> = inject((componentStores: FxStores): InjectedFxStores => ({
  userStore: componentStores.userStore
}))(observer(({ userStore, children }) => {
  const client = useLDClient();

  const hjid = parseInt(process.env.HOTJAR_ID || '');
  const hjsv = parseInt(process.env.HOTJAR_SV || '');

  if (!PermissionsService.isInternalRole() && hjid && hjsv) {
    hotjar.initialize(hjid, hjsv);
  }

  useEffect(() => {
    if (!!userStore!.user && !!client) {
      client?.identify({
        key: userStore!.user.id,
        firstName: userStore!.user.givenName,
        lastName: userStore!.user.familyName || '',
        email: userStore!.user.email
      });
    }
  }, [userStore!.user, client]);

  return (
    <Fragment>
      {children}
    </Fragment>
  );
}));

/**
 * @description root entry point of application. provides a few things:
 * - MobX Stores
 * - LaunchDarkly client. initialisation is deferred if the CLIENT ID doesn't exist
 * in environment variables. Shouldn't happen due to a pre-build check looking for the
 * variable.
 * - Global styles - CSS declarations shared across the platform.
 * - Theme provider - Rebass uses this
 */
const wrapRootElement: FC<WrapRootElementBrowserArgs> = ({ element }) => {
  const RootElement: ComponentType = withLDProvider({
    clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID || '',
    deferInitialization: !process.env.LAUNCH_DARKLY_CLIENT_ID,
    user: {
      // Assigns all anonymous users with the same ID
      // We will only be targeting logged-in users for experimentation
      key: SHARED_LD_ANONYMOUS_KEY
    }
  })(() => {
    return (
      <StoreProvider {...stores}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        <Fragment>
          <Global styles={css(GlobalStyles)} />
          <RootErrorBoundary>
            <ThemeProvider theme={theme}>
              <LDIdentify>
                {element}
              </LDIdentify>
            </ThemeProvider>
          </RootErrorBoundary>
        </Fragment>
      </StoreProvider>
    );
  });

  return (
    <RootElement />
  );
};

export default wrapRootElement;
