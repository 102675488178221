import {
  Product,
  ProductType,
  ProductCategory
} from 'generated-types.d';

export enum DiscountType {
  Percent = 'Percent',
  Fixed = 'Fixed',
  FreeShipping = 'FreeShipping'
}

export enum DiscountApplication {
  EntireOrder = 'EntireOrder',
  Products = 'Products',
  ProductTypes = 'ProductTypes',
  ProductCategories = 'ProductCategories'
}

export interface DiscountFormSchema {
  code: string;
  isActive: boolean;
  description: string;
  startsAt: string | null;
  endsAt: string | null;
  usageLimit: string;
  perUserLimit: boolean;
  discountType: DiscountType;
  amount: string;
  minBasketValue: string;
  appliesTo: DiscountApplication;
  selectedProducts: Product[];
  productTypes: ProductType[];
  productCategories: ProductCategory[];
  oncePerOrder: boolean;
}
