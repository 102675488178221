import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Box,
  Flex
} from 'rebass';

import {
  colors
} from 'utils/rebass-theme';

export const HideOnSmall = css`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const NavContainer = styled(Flex)`
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 4.5em;
  z-index: 1000;
  background-color: ${colors.white};

  @media (min-width: 1000px) {
    padding: 0 2em;
    font-size: 18px;
    height: 5em;
  }
`;

export const Link = styled(Box)`
  &:hover {
    color: orange;
    transition: color 300ms ease-in-out;
  }
`;

export const Button = styled(Box)`
  padding: 0 1em;
  font-size: 14px;
  line-height: 3em;
  text-align: center;
  color: ${colors.white};
  background-color: ${colors.floomOrange};
  border-radius: 4px;

  @media (min-width: 400px) {
    padding: 0 1.5em;
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    padding: 0 2em;
    font-size: 18px;
  }
`;

export const Logo = styled.button`
  width: 7em;

  @media (max-width: 1000px) {
    padding-left: 1.2em;
    margin-right: auto;
    width: 6em;
    margin-top: -4px;
  }
`;

const buttonWidth = 24;
const strokeWidth = 2;

export const HamburgerWrapper = styled(Box)`
  position: relative;
  display: none;
  width: ${buttonWidth}px;
  height: ${strokeWidth * 3 + strokeWidth * 6}px;
  cursor: pointer;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const getLinesCommon = `
  width: ${buttonWidth}px;
  height: ${strokeWidth}px;
  border-radius: 1px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`;

export const Lines = styled(Box)`
  ${getLinesCommon};

  display: block;
  top: 50%;
  margin-top: ${-buttonWidth * 0.05}px;
  background-color: ${({ color }): string => color};

  &::before,
  &::after {
    background-color: ${({ color }): string => color};
    ${getLinesCommon};

    content: '';
    display: block;
  }

  &::before {
    top: ${strokeWidth * 3}px;
  }

  &::after {
    bottom: ${strokeWidth * 3}px;
  }
`;

const active = `
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const activeBefore = `
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
`;

const activeAfter = `
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

export const LineSpin = styled(Lines)<{ color: string }>`
  background-color: ${({ color }): string => color};
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${({ isActive }): any => isActive && active};

  &::before {
    background-color: ${({ color }): string => color};
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;

    ${({ isActive }): any => isActive && activeBefore};
  }

  &::after {
    background-color: ${({ color }): string => color};
    transition: bottom 0.1s 0.25s ease-in,
                transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    ${({ isActive }): any => isActive && activeAfter};
  }
`;
