import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';

import { textStyles, colors, breakpoints } from 'utils/rebass-theme';

import Icon from 'components/icon';

export const OrderDetailHeader: any = styled(Flex)`
  ${textStyles.subhead};
  background: ${({ config }: any): any => colors[config.headingBg]};
  color: ${({ config }: any): any => config.color};
  min-height: 70px;
  padding: 10px 30px;
`;

export const ReceiptLink: any = styled(Text)`
  display: none;

  @media (min-width: ${breakpoints.medium}) {
    display: block;
  }
`;

export const StatusControls: any = styled(Box)`
  display: none;
  margin-left: 40px;

  @media (min-width: ${breakpoints.medium}) {
    display: block;
  }
`;

export const StatusControlsMobile: any = styled(Text)`
  display: block;

  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const StatusIcon: any = styled(Icon)`
  path {
    fill: ${({ color }: any): any => color};
  }
`;

export const HeaderActions: any = styled(Flex)`
  position: relative;
  padding-right: 20px;
  margin-right: 25px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -5px;
    bottom: -5px;
    width: 1px;
    background: ${({ color }: any): any => color};
  }
`;
