import {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';

import { css } from '@emotion/react';
import moment from 'moment';
import { Box } from 'rebass';

import {
  WholesaleOrderLineItem
} from 'generated-types.d';

import { colors } from 'utils/rebass-theme';
import { TEST_IDS } from 'utils/test/data-test-ids';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import {
  WholesaleCheckoutHelpers
} from 'features/wholesale/helpers/wholesale-helpers';

import Icon from 'components/icon';

import {
  Notification
} from '../wholesale-shop-order-summary/wholesale-shop-order-summary.styles';

import { getCatalogueItemKeyAttributeValue } from './getCatalogueItemKeyAttributeValue';
import {
  Image,
  Body,
  Title,
  Wrapper,
  Meta,
  Timer,
  Prices,
  EditMode,
  Error,
  EditContainer,
  PriceDetails,
  UnitPrice
} from './wholesale-shop-order-summary-item.styles';
import {
  WholesaleShopOrderSummaryItemProps,
  WholesaleShopOrderSummaryItemState
} from './wholesale-shop-order-summary-item.types';

export const WholesaleShopOrderSummaryItem: FC<WholesaleShopOrderSummaryItemProps> = memo(({
  item,
  currency,
  onRemove,
  isEditing = false,
  isDisabled,
  supplier,
  orderId
}) => {
  const oldestItem = useMemo((): WholesaleOrderLineItem => {
    return [...item.lineItems].sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)[0];
  }, [item.lineItems]);

  const calculateTimeLeft = useCallback((createdAt: string): number => {
    const cancellationWindowInMinutes = supplier?.itemCancellationWindowInMinutes || 13;
    const secondsRemaining = moment.utc(createdAt).diff(moment.utc().subtract(cancellationWindowInMinutes, 'minutes'), 'seconds');

    return secondsRemaining <= 0 ? 0 : secondsRemaining;
  }, [
    oldestItem.createdAt,
    supplier
  ]);

  const [state, updateState] = useState<WholesaleShopOrderSummaryItemState>({
    timeLeft: calculateTimeLeft(oldestItem.createdAt),
    isSaving: false,
    errorCopy: ''
  });

  const isCancelled = useRef(false);

  useEffect(() => {
    return () => {
      isCancelled.current = true;
    };
  });

  const handleRemove = async (): Promise<void> => {
    if (isDisabled) return;

    updateState(prevState => ({
      ...prevState,
      isSaving: true,
      errorCopy: ''
    }));

    try {
      for (const lineItem of item.lineItems) {
        await onRemove(lineItem.id, lineItem.snapshot?.title || `Item ${lineItem.id.substring(0, 7)}`, orderId);
      }

      if (!isCancelled.current) {
        updateState(prevState => ({
          ...prevState,
          isSaving: false
        }));
      }
    } catch (error: any) {
      updateState(prevState => ({
        ...prevState,
        errorCopy: error
      }));
    }

    if (!isCancelled.current) {
      updateState(prevState => ({
        ...prevState,
        isSaving: false
      }));
    }
  };

  useEffect(() => {
    if (state.timeLeft <= 0) return;

    updateState(prevState => ({
      ...prevState,
      timeLeft: calculateTimeLeft(oldestItem.createdAt)
    }));
  }, [item]);

  const minutesRemaining = Math.floor(state.timeLeft / 60);
  const canEdit = state.timeLeft > 0;
  const formattedTimeRemaining = minutesRemaining === 0 && canEdit
    ? 'Less than 1 min to cancel'
    : `${minutesRemaining} min${minutesRemaining > 1 ? 's' : ''} left to cancel`;

  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  return (
    <Fragment>
      <Wrapper>
        <Image src={oldestItem.snapshot?.image} />
        <Body>
          <Title>
            {
              wsFriendlyNames.isActive ? (
                oldestItem.snapshot?.primary_name || oldestItem.snapshot?.title
              ) : (
                oldestItem.snapshot?.title
              )
            }
            <Meta>
              {getCatalogueItemKeyAttributeValue(oldestItem.snapshot)}
            </Meta>
          </Title>
          <EditContainer>
            { canEdit && (
              <Timer data-testid={TEST_IDS.WholesaleShopOrderSummary.timeRemaining}>
                {formattedTimeRemaining}
              </Timer>
            )}
            <EditMode
              isVisible={!!isEditing && canEdit}
              as="button"
              disabled={state.isSaving || isDisabled || !canEdit}
              onClick={handleRemove}
              data-testid={TEST_IDS.WholesaleShopOrderSummary.cancelButton}
            >
              <Box>
                <Icon iconName="bin" />
              </Box>
              <Box>
                {state.isSaving ? 'Removing...' : 'Cancel item'}
              </Box>
            </EditMode>
          </EditContainer>
          <Prices>
            <Box>
              {item.quantity}x
            </Box>
            <PriceDetails>
              <div>{WholesaleCheckoutHelpers.renderWholesalePrice(item.totalPrice || 0, currency)}</div>
              <UnitPrice>{WholesaleCheckoutHelpers.renderWholesalePrice(oldestItem.unitPrice || 0, currency)} per unit</UnitPrice>
            </PriceDetails>
          </Prices>
        </Body>
      </Wrapper>
      {!!state.errorCopy?.length && (
        <Error>
          <Notification
            colour={colors.errorBg}
            css={css` margin: 10px 0 0 0; `}
          >
            {state.errorCopy}
          </Notification>
        </Error>
      )}
    </Fragment>
  );
});
