import React, { Component } from 'react';

import { Router } from '@reach/router';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';

import CategoryModal from 'features/add-ons/components/modals/category-modal';
import NoMerchantModal from 'features/add-ons/components/modals/no-merchant-modal';
import AddOnCreate from 'features/add-ons/pages/add-on-create';
import AddOnList from 'features/add-ons/pages/add-on-list';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

class AddOns extends Component<any> {
  onCategoryModalClose = (): void => {
    this.props.addOnEditStore.toggleRestrictedCategoryModal();
  };

  render(): JSX.Element {
    return (
      <>
        <AppLayout>
          {/*
          // @ts-ignore */}
          <Helmet>
            <title>Add-Ons</title>
          </Helmet>
          <Router>
            <AuthedRoute
              path="/products/add-ons/"
              component={AddOnList}
            />
            <AuthedRoute
              path="/products/add-ons/list/"
              component={AddOnList}
            />
            <AuthedRoute
              path="/products/add-ons/create/:addOnId/"
              component={AddOnCreate}
            />
            <AuthedRoute
              path="/products/add-ons/create/"
              component={AddOnCreate}
            />
            <AuthedRoute
              path="/products/add-ons/edit/:addOnId/"
              component={AddOnCreate}
            />
          </Router>
        </AppLayout>
        {/* @TODO change these modals to use the new modal store once Wholesale code is merged */}
        <CategoryModal
          isOpen={this.props.addOnEditStore!.isRestrictedCategoryModalOpen}
          closeAction={this.props.addOnEditStore!.toggleRestrictedCategoryModal}
        />
        <NoMerchantModal
          isOpen={this.props.addOnEditStore!.isNoMerchantModalOpen}
          closeAction={this.props.addOnEditStore!.toggleNoMerchantModal}
        />
      </>
    );
  }
}

export default inject('addOnEditStore')(observer(AddOns));
