import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { UserRole, OrderNote } from 'generated-types.d';

import { Analytics, PermissionsService, HTMLService } from 'lib';

import { OrderNotesModalData } from 'features/modal-dialogue/components/modals/order-notes-modal/order-notes-modal.types';
import { OrderStatusSlug, ExtendedOrderStatus } from 'features/orders/orders.types';
import { OrderListService, OrderAnalytics } from 'features/orders/services';

import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType, TableLayoutEntityConfigProps } from 'components/entity-list/table-layout-entity.types';
import Icon from 'components/icon';
import DeliveryTimeMeta from 'components/item-meta/delivery-time-meta';
import LocationMeta from 'components/item-meta/location-meta';
import ProductTitleMeta from 'components/item-meta/product-title-meta';
import StyledLink from 'components/styled-link';

import OrderItemHeader from './item-header';
import {
  statusConfig,
  OrderStatusConfigType
} from './order-item.config';
import * as Styles from './order-item.styles';
import * as Types from './order-item.types';
import StatusQuickSwitch from './status-quick-switch';

class OrderItemTable extends Component<Types.OrderItemProps> {
  state: Types.OrderItemState = {
    isUpdatingStatus: false
  };

  static getDerivedStateFromProps(props: Types.OrderItemProps): Partial<Types.OrderItemState> | null {
    const storeStatus = props?.ordersStore?.changedOrderStatuses[props.hit.orderNo];

    if (storeStatus && storeStatus.slug === props.hit.status.slug) {
      props!.ordersStore!.addChangedStatus(props.hit.orderNo, null);
    }

    return null;
  }

  private checkStatus(): OrderStatusSlug {
    const storeStatus = this.props?.ordersStore?.changedOrderStatuses[this.props.hit.orderNo];

    if (!storeStatus) {
      return this.props.hit.status.slug;
    }

    return storeStatus.slug;
  }

  private onStatusUpdate = async (statusSlug: OrderStatusSlug): Promise<void> => {
    try {
      this.setState({ isUpdatingStatus: true });
      const updatedOrder = await OrderListService.updateStatusFromList(statusSlug, this.props.hit);

      OrderAnalytics.onEditStatus(
        Analytics.OrderEditEntry.OrderList,
        statusSlug,
        this.props.hit
      );

      this.setState({ isUpdatingStatus: false });

      this.props!.ordersStore!.addChangedStatus(this.props.hit.orderNo, updatedOrder.status as ExtendedOrderStatus);
      this.props.onRefresh();
    } catch (error) {
      this.setState({ isUpdatingStatus: false });
    }
  };

  private onEditOrderNote = (note: OrderNote): void => {
    this.props.modalStore!.triggerModal<OrderNotesModalData>({
      modalType: 'orderNotes',
      data: {
        activeNoteContent: this.canEditNote(note) ? HTMLService.stripTagsAndAddNewLine(note.content) : ' ',
        notes: this.props.hit.orderNotes || [],
        orderNo: this.props.hit.orderNo,
        onClose: () => {
          this.props.onRefresh();
        }
      }
    });
  };

  private cellConfig = (config: OrderStatusConfigType): TableLayoutEntityConfigProps[] => {
    return [
      {
        cellType: CellType.Custom,
        flexGrow: '4',
        flexBasis: '130px',
        customInnerElement: (
          <ProductTitleMeta
            data={this.props.hit.orderItems}
            layout="table"
          />
        )
      },
      {
        cellType: CellType.Text,
        restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
        flexGrow: '1',
        flexBasis: '90px',
        props: {
          text: this.props.hit.merchant.title,
          extraSubtitles: [this.props.hit.channel]
        }
      },
      {
        cellType: CellType.Custom,
        flexGrow: '1',
        flexBasis: '100px',
        customInnerElement: (
          <LocationMeta
            data={this.props.hit.shippingAddress}
            status={this.props.hit.status.slug}
            layout="table"
          />
        )
      },
      {
        cellType: CellType.Custom,
        flexGrow: '1',
        flexBasis: '110px',
        customInnerElement: (
          <DeliveryTimeMeta
            data={this.props.hit}
            layout="table"
          />
        )
      },
      {
        cellType: CellType.Custom,
        flexGrow: '2',
        flexBasis: '150px',
        customInnerElement: (
          <Flex justifyContent="flex-end">
            <StatusQuickSwitch
              config={config}
              groupName={this.props.groupName}
              currentStatus={this.checkStatus()}
              orderNo={this.props.hit.orderNo}
              onChange={this.onStatusUpdate}
              isLoading={this.state.isUpdatingStatus}
              orderChannel={this.props.hit.channel}
              layout="table"
            />
          </Flex>
        )
      }
    ];
  };

  private canEditNote = (note: OrderNote): boolean => {
    switch (true) {
      case note.floom && PermissionsService.isInternalRole(): return true;

      case !note.floom && !PermissionsService.isInternalRole(): return true;

      default: return false;
    }
  };

  private renderNoteCopy = (note: OrderNote): ReactNode => {
    return (
      <>
        <b>
          {!!note.floom ? 'Floom: ' : 'Florist: '}
        </b>
        <span
          dangerouslySetInnerHTML={{
            __html: HTMLService.sanitize(note.content).replace(/\s?(<br\s?\/?>)\s?/g, '\r\n')
          }}
        />
      </>
    );
  };

  private shouldDisplayReply = (note: OrderNote): boolean => {
    return this.props.hit.orderNotes!.length === 1 && !this.canEditNote(note);
  };

  private shouldHideOrderNotes = (): boolean => {
    return (
      !!this.props.hit.orderNotes
        && !this.props.hit.orderNotes.length
    );
  };

  private renderOrderNotes = (): ReactNode => {
    if (this.shouldHideOrderNotes()) return null;

    return (
      <Styles.OrderItemTableNoteContainer
        containsMultipleNotes={this.props.hit.orderNotes!.length > 1}
      >
        {this.props.hit.orderNotes!.map(note => {
          return (
            <Styles.OrderItemTableNote
              isFloom={note.floom}
              canEdit={this.canEditNote(note)}
              key={note.id}
              justifyContent="space-between"
              alignItems="center"
              onClick={(): void => this.onEditOrderNote(note)}
            >
              <div>
                {this.renderNoteCopy(note)}
              </div>
              { this.canEditNote(note) && (
                <Box height="24px">
                  <Icon iconName="edit" />
                </Box>
              )}
              { this.shouldDisplayReply(note) && (
                <StyledLink>
                  Reply
                </StyledLink>
              )}
            </Styles.OrderItemTableNote>
          );
        })}
      </Styles.OrderItemTableNoteContainer>
    );
  };

  render(): ReactNode {
    const config: OrderStatusConfigType = statusConfig[this.checkStatus()];

    return (
      <EntityListRow
        key={this.props.hit.orderNo}
        style={{
          width: '100%'
        }}
      >
        <Flex alignItems="stretch">
          <Flex
            width="130px"
            alignItems="stretch"
          >
            <OrderItemHeader
              layout="table"
              config={config}
              orderRoute={this.props?.ordersStore?.listLayout || 'today'}
              data={this.props.hit}
              currentStatus={this.checkStatus()}
            />
          </Flex>
          <Box
            width="100%"
            p="10px"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
            >
              <TableLayoutEntity config={this.cellConfig(config)} />
            </Flex>
            <Box mt="10px">
              {this.renderOrderNotes()}
            </Box>
          </Box>
        </Flex>
      </EntityListRow>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  modalStore: stores.modalStore
}))(observer(OrderItemTable as any));
