import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { breakpoints, colors, fontSizes, fontStacks } from 'utils/rebass-theme';

import { stackedSearchBreakpoint } from 'features/wholesale/wholesale.constants';

import {
  Container,
  Item,
  Header
} from 'components/button-dropdown/button-dropdown.styles';

export const Wrapper = styled(Flex)<{ sortDirection: string }>`
  cursor: pointer;
  z-index: 3;

  @media only screen and (max-width: ${breakpoints.small}) {
    padding-left: 0;
  }

  ${Container} {
    background: ${colors.lightGrey};
    cursor: default;
    overflow-y: hidden;
    margin-top: 0;
    padding: 0;
    transform: translate(-75%, 15px);
    width: 195px;

    @media only screen and (max-width: ${breakpoints.small}) {
      width: max-content;
    }
  }

  ${Item} {
    ${Header} {
      font-size: ${fontSizes.base}px !important;
      font-weight: 700;
    }

    background: ${({ isSelected }: any): any => isSelected ? colors.floomMidnightBlue : colors.lightGrey};
    color: ${({ isSelected }: any): any => isSelected ? colors.uiFills : colors.floomMidnightBlue};
    cursor: pointer;
    padding: 8px 16px;
    border-top: none !important;

    &:hover {
      background: ${colors.background};
      color: ${colors.floomMidnightBlue};
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
`;

export const ActiveSortLabel = styled(Box)`
  font-weight: bold;
  margin-left: 10px;
  font-size: ${fontSizes.base}px;

  @media only screen and (max-width: ${stackedSearchBreakpoint}) {
    display: none;
  }
`;

export const Label = styled(Flex)`
  display: none;
  font-family: ${fontStacks.secondary};
  font-size: 14px;
  color: ${colors.floomMidnightBlue};
  align-items: center;

  @media only screen and (max-width: ${stackedSearchBreakpoint}) {
    display: flex;
    font-size: 13px;
    margin-right: 5px;
  }
`;
