import {
  FC,
  Fragment,
  memo,
  useState
} from 'react';

import { css } from '@emotion/react';
import {
  connectSortBy
} from 'react-instantsearch-core';

import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';

import { stackedSearchBreakpoint, stackedSearchBreakpointPx } from 'features/wholesale/wholesale.constants';

import { ButtonDropdown } from 'components/button-dropdown/button-dropdown';
import Icon from 'components/icon';

import {
  Wrapper,
  ActiveSortLabel,
  Label
} from './wholesale-shop-sort.styles';

export interface SortByItem {
  label: string;
  value: string;
}

interface SortByProps {
  items: SortByItem[];
  refine: any;
}

type SortDirection = 'ascTitle' | 'ascPrice';

const SORT_LABELS: { [key in SortDirection]: string  } = {
  ascTitle: 'Title (A - Z)',
  ascPrice: 'Price (Low to High)'
};

const findDirection = (value: string): SortDirection => {
  if (value.includes('price')) return 'ascPrice';

  return 'ascTitle';
};

const getLeftOffset = (sortDirection: SortDirection, width: number | undefined): number => {
  if (width && width <= stackedSearchBreakpointPx) {
    return 8;
  }

  if (sortDirection === 'ascTitle') {
    return 94;
  }

  return 154;
};

export const WholesaleShopSortComponent = connectSortBy(memo(({ items, refine }: SortByProps) => {
  const [sortDirection, setSortDirection] = useState<SortDirection>('ascTitle');
  const { width } = useWindowSize();

  const onOptionClick = (value: string): void => {
    setSortDirection(findDirection(value));
    refine(value);
  };

  const sortOptions = items.map(item => {
    const isSelected = findDirection(item.value) === sortDirection;

    return (
      {
        title: isSelected ? `${item.label} •` : item.label,
        onClick: () => onOptionClick(item.value)
      }
    );
  });

  return (
    <Wrapper >
      <ButtonDropdown
        customDropdownContainerStyles={css`
          left: ${getLeftOffset(sortDirection, width)}px;
        `}
        options={sortOptions}
        buttonCopy=""
        heading="SORT BY"
        renderButton={() => (
          <Fragment>
            <Label>Sort</Label>
            <Icon
              iconName="sort"
              styles={css`
                @media only screen and (max-width: ${stackedSearchBreakpoint}) {
                  height: 20px;
                  width: 20px;
                  vertical-align: middle;
                }
              `}
            />
            <ActiveSortLabel>
              {SORT_LABELS[sortDirection]}
            </ActiveSortLabel>
          </Fragment>
        )}
      />
    </Wrapper>
  );
}));

export const WholesaleShopSort: FC = () => {
  return (
    <WholesaleShopSortComponent
      defaultRefinement={process.env.ALGOLIA_CATALOG_INDEX!}
      items={[
        { value: `${process.env.ALGOLIA_CATALOG_INDEX!}`, label: SORT_LABELS['ascTitle'] },
        { value: `${process.env.ALGOLIA_CATALOG_INDEX!}_price_asc`, label: SORT_LABELS['ascPrice'] }
      ]}
    />
  );
};
