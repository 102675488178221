import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { mq } from 'theme';

import {
  Card,
  Container
} from 'utils/css-mixins';
import {
  borderRadius,
  breakpoints,
  colors,
  shadows
} from 'utils/rebass-theme';

import { stackedSearchBreakpoint } from 'features/wholesale/wholesale.constants';

export const OrderSummaryWrapper = styled(Box)`
  ${Card}
  box-shadow: ${shadows.xSmall};
  border-radius: ${borderRadius.xLarge};
  flex: 0 0 360px;
  margin: 10px 0 0 10px;
  overflow: hidden;
  align-self: flex-start;
  position: sticky;
  height: calc(${(props: {windowHeight: number}) => props.windowHeight}px - 60px - 60px - 16px);
  top: 70px;
  bottom: 0;
  z-index: 3;

  @media (max-width: ${breakpoints.medium}) {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    align-self: center;
    top: 0;
    width: 100%;
    height: auto;
    margin: 15px 0;
    width: 100%;
    max-height: calc(${(props: {windowHeight: number}) => props.windowHeight}px - 60px - 60px - 16px);
  }
`;

export const ContainerStyles = styled(Container)`
  max-width: 1920px;
  padding: 0 10px;

  ${mq(640)} {
    padding: 0 !important;
  };
`;

export const Mask = styled(Box)`
  width: 100%;
  height: 15px;
  position: sticky;
  z-index: 3;
  top: 0;
  margin-top: -15px;
  background-color: ${colors.floomCream};

  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const MaskInner = styled(Box)`
  background-color: ${colors.floomCream};
  height: 90px;
`;

export const InnerContainer = styled(Flex)`
  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column-reverse;
  }
`;

export const InlineFiltersWrapper = styled(Box)`
  z-index: 10;
  display: block;
  position: sticky;
  top: 70px;
  flex: 0 0 230px;
  margin: 10px 10px 0 0;
  padding: 0 0 15px;
  height: calc(100vh - 80px);
  overflow: auto;
  background: ${colors.uiFills};
  box-shadow: 0px 2px 6px rgb(0 0 0 / 20%);
  border-radius: ${borderRadius.large};

  @media only screen and (max-width: ${stackedSearchBreakpoint}) {
    display: none;
  }
`;
