import _get from 'lodash.get';

type KeyAttribute = 'stem_length' | 'pot_size' | 'weight';
type ProductType = 'flower' | 'plant' | 'sundry' | 'decoration' | 'other';

export type ItemAttributes = { type: ProductType } & Record<KeyAttribute, string | number>;

const itemTypeKeyAttributeMappings: Record<ProductType, KeyAttribute[]> = {
  flower: ['stem_length'],
  plant: ['pot_size'],
  sundry: ['weight'],
  decoration: [],
  other: ['stem_length', 'pot_size', 'weight']
};

const attributeUnitMapping: Record<KeyAttribute, string | null> = {
  stem_length: 'cm',
  pot_size: null,
  weight: null
};

const getKeyAttributeTuple = (attributes: ItemAttributes, keyAttributes: KeyAttribute[] = []): [KeyAttribute, string | number] | null => {
  if (!keyAttributes.length) return null;

  const attr = keyAttributes[0];

  if (!attr) return null;

  const value = attributes[attr];

  if (!value) return getKeyAttributeTuple(attributes, keyAttributes.slice(1));

  return [attr, value];
};

export const getCatalogueItemKeyAttributeValue = (attributes: ItemAttributes): string | null => {
  const productType = _get(attributes, 'type', 'unknown').toLocaleLowerCase();
  const keyAttributes = itemTypeKeyAttributeMappings[productType];

  const keyAttribute = getKeyAttributeTuple(attributes, keyAttributes);

  if (!keyAttribute) return null;

  const units = attributeUnitMapping[keyAttribute[0]] || '';

  return `${keyAttribute[1]}${units}`;
};
