import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, textStyles, breakpoints } from 'utils/rebass-theme';

import { ButtonWrapper } from 'components/button/button.styles';

export const OrderItemBlock = styled(Box)`
  margin: 15px;
  padding: 10px;
`;

export const BlockRow = styled(Flex)`
  flex-direction: row;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

export const BlockCol = styled(Flex)`
  flex-direction: column;
  padding: ${(props: any): number => props.padding || 0};
`;

export const Placeholder = styled.span`
  color: ${colors.shade40};
  font-weight: bold;
`;

export const imagePlaceholder = styled.div`
  height: 150px;
  width: 100%;
  background-color: ${colors.middleGrey};
  color: #fff;
  text-align: center;
  line-height: 150px;
  font-size: 14px;
`;

export const AddOnsCol = styled(Box)<any>`
  margin-left: 20px;
  margin-bottom: 20px;
  flex: 0 0 35%;
  border: ${colors.middleGrey} 2px solid;
  max-height: ${(props: any): string => props.isSingular ? '190px' : 'auto'};
  border-radius: 3px;
  padding: 10px;
  overflow: scroll;
  position: relative;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 0;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.floomMidnightBlue};
    border-radius: 5px;
  }

  @media (max-width: ${breakpoints.small}) {
    margin-left: 0;
  }
`;

export const GiftMessageCol = styled(BlockCol)`
  font-size: 14px;
  line-height: 20px;
  flex: 1 1 auto;
  max-height: 190px;
  min-height: 190px;
`;

export const UniqueProductBlock = styled(Flex)`
  flex-direction: row;
  flex: 1 1 auto;
  margin-bottom: 20px;
`;

export const ProductTitle = styled.div`
  ${textStyles.title};
  padding: 20px 0;

  > .quantity {
    color: ${colors.middleGrey};
    margin-right: 6px;
  }

  > .variation {
    font-weight: normal;
  }
`;

export const ProductDetailImgBlock = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  margin-right: 20px;
  flex: 0 0 auto;

  img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

export const RecipeActionButton = styled.button`
  ${ButtonWrapper} {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    padding: unset;
    font-size: 14px;
    line-height: 33px;
    height: 40px;
    background-color: ${colors.floomMidnightBlue};
    color: #fff;
  }
`;

export const VariationImageStyle = styled.div`
  width: 204px;
  height: 204px;
  margin: 15px 22px 0 0;
`;
