import {
  FC,
  Fragment,
  ReactNode,
  useMemo
} from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import { InjectedMobxStores } from 'stores';

import {
  LocalisationService,
  MerchantService,
  PermissionsService
} from 'lib';

import { colors } from 'utils/rebass-theme';

import CheckboxIcon from 'components/checkbox-icon';
import Notification from 'components/notification';
import {
  NotificationType
} from 'components/notification/notification.types';
import StyledLink from 'components/styled-link';

const Heading: FC = () => {
  return (
    <Box
      as="strong"
      color={colors.shade40}
    >
      Only floomers can see this
    </Box>
  );
};

export const WholesaleShopFloomerInstructions: FC<Pick<InjectedMobxStores,
  | 'merchantStore'
  | 'wholesaleShopStore'
>> = inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  merchantStore,
  wholesaleShopStore
}) => {
  const isWholesaleEnabled = !!merchantStore!.merchant?.wholesaleActive;
  const hasSuppliersConfigured = !!wholesaleShopStore!.merchantWholesaleSuppliers?.length && !wholesaleShopStore!.isLoading;
  const hasDeliveryConfig = !!merchantStore!.merchant?.wholesaleDeliveryConfigs?.length;
  const hasActiveDeliveryConfig = !!merchantStore!.merchant?.wholesaleDeliveryConfigs?.[0]?.active;
  const hasHubs = !!merchantStore!.merchant?.wholesaleDeliveryConfigs?.[0]?.hubs?.length;
  const hasActiveSubscription = MerchantService.hasActiveSubscription(merchantStore!.merchant);
  const subscriptionStatusConfig = LocalisationService.localiseSubscriptionStatus(merchantStore!.merchant?.subscription?.status);

  const isMissingConfiguration = useMemo<boolean>(() => {
    return !hasSuppliersConfigured
      || !hasDeliveryConfig
      || !hasHubs
      || !isWholesaleEnabled
      || !hasActiveDeliveryConfig
      || !hasActiveSubscription;
  }, [
    wholesaleShopStore!.merchantWholesaleSuppliers,
    wholesaleShopStore!.isLoading,
    merchantStore!.wholesaleDeliveryConfig,
    merchantStore!.merchant
  ]);

  const config = useMemo(() => [
    {
      isCompleted: !!hasSuppliersConfigured,
      link: `${NavPages.Settings}wholesale`,
      heading: 'Integrate the merchant with the supplier',
      instructions: 'Head to wholesale -> settings with the merchant selected, and create a supplier integration. Make sure it matches the delivery hubs.'
    },
    {
      isCompleted: !!hasHubs,
      link: `${NavPages.Settings}wholesale`,
      heading: 'Select a supplier delivery hub',
      instructions: 'Find this selection on their delivery config in wholesale -> settings. Make sure to select the supplier(s) they want to shop with.'
    },
    {
      isCompleted: !!hasActiveDeliveryConfig,
      link: `${NavPages.Settings}wholesale`,
      heading: 'Enable the delivery config',
      instructions: `Make sure to set the delivery cofig to 'active' in settings. It's our way of knowing the address has been sent to the supplier.`
    },
    {
      isCompleted: !!hasDeliveryConfig,
      link: `${NavPages.Settings}wholesale`,
      heading: 'Create a delivery config',
      instructions: `We need an address to send the items they purchase. On wholesale -> settings you can add this and enter their address.`
    },
    {
      isCompleted: !!isWholesaleEnabled,
      link: `${NavPages.Settings}merchants/${merchantStore!.merchant?.id}`,
      heading: 'Enable wholesale on their account',
      instructions: 'To do this, go to merchant settings and set Wholesale active on their account settings.'
    },
    {
      isCompleted: !!hasActiveSubscription,
      link: `${NavPages.Settings}merchants/${merchantStore!.merchant?.id}`,
      heading: 'Ensure merchant has an active subscription',
      instructions: `Florists require an active subscription to shop on the wholesale marketplace. Their subscription has a status of "${subscriptionStatusConfig.title}" currently, meaning that "${subscriptionStatusConfig.reason.toLowerCase()}". More information can be found in settings -> merchants.`
    }
  ], [
    isWholesaleEnabled,
    hasActiveDeliveryConfig,
    hasHubs,
    hasSuppliersConfigured
  ]);

  const copy = useMemo((): ReactNode => {
    switch (true) {
      case !merchantStore!.merchant: {
        return (
          <Fragment>
            <Heading />
            <Box>
              {`You're browsing the wholesale shop, but you don't have a merchant selected at the moment. Please select a merchant to see the shop.`}
            </Box>
          </Fragment>
        );
      }

      case isMissingConfiguration: {
        return (
          <Fragment>
            <Heading />
            <Box
              mb="20px"
              fontSize="16px"
              fontWeight="bold"
            >
              Before {merchantStore!.merchant?.title} can shop on wholesale, a few things need to be set-up on their account
            </Box>
            {config.sort((a, b) => a.isCompleted && !b.isCompleted ? 0 : -1).map((configItem, index) => {
              return (
                <Box
                  mt="10px"
                  key={index}
                >
                  <Link to={configItem.link}>
                    <Flex>
                      <Box
                        mt="2px"
                        flex="0 0 auto"
                      >
                        <CheckboxIcon
                          isSelected={configItem.isCompleted}
                        />
                      </Box>
                      <Box>
                        {!configItem.isCompleted ? (
                          <StyledLink>
                            {configItem.heading}
                          </StyledLink>
                        ) : configItem.heading}
                        {!configItem.isCompleted && (
                          <Box mt="5px">
                            {configItem.instructions}
                          </Box>
                        )}
                      </Box>
                    </Flex>
                  </Link>
                </Box>
              );
            })}
          </Fragment>
        );
      }

      default:
        return null;
    }
  }, [merchantStore!.merchant]);

  if (!PermissionsService.isInternalRole() || !copy) return null;

  return (
    <Box mt="20px">
      <Notification
        type={NotificationType.Banner}
        hasIcon={false}
        hasClose={false}
        textAlign="left"
        copy={copy}
        iconColor={colors.floomMidnightBlue}
      />
    </Box>
  );
}));
