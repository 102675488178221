import { FC } from 'react';

import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import Button from 'components/button';

import { TypeformButtonProps } from './button.types';

export const TypeformButton: FC<TypeformButtonProps> = inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore
}))(observer(({
  modalStore,
  configs,
  buttonCopy,
  modalCopy
}) => {
  const onFormSelect = (formId: string): void => {
    const formConfig = configs.find(config => config.formId === formId);
    const formOptions = {
      hidden: { ...formConfig?.hiddenFields },
      autoClose: 1000
    };
    const { toggle } = createPopup(formConfig!.formId, formOptions);
    toggle();
  };

  const openModal = (): void => {
    modalStore!.triggerModal<any>({
      modalType: 'typeFormSelect',
      data: {
        formConfigs: configs,
        onFormSelect: onFormSelect,
        title: modalCopy
      }
    });
  };

  return (
    <Box
      as="button"
      onClick={openModal}
    >
      <Button
        copy={buttonCopy}
        appearance="primary"
        size="small"
      />
    </Box>
  );
}));
