import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import * as Styles from './checkbox-toggle.styles';
import * as Types from './checkbox-toggle.types';

export default class CheckboxToggle extends Component<Types.CheckboxToggleProps, Types.CheckboxToggleState> {
  static defaultProps = {
    switchBgActive: colors.floomMidnightBlue,
    switchBgInactive: colors.floomMidnightBlue,
    activeCopy: 'Yes',
    inactiveCopy: 'No',
    isDisabled: false,
    id: ''
  };

  state = {
    isChecked: false
  };

  private onChange = (event: React.ChangeEvent<any>): any => {
    if (!this.props.isDisabled) {
      if (this.props.controlValue) {
        this.props.onChange(event.target.checked);
      } else {
        this.updateInternalState(event.target.checked);
      }
    }
  };

  private updateInternalState = (checked: boolean): void => this.setState({ isChecked: checked });

  private isChecked = (): boolean | undefined => this.props.controlValue ? this.props.isChecked : this.state.isChecked;

  private getToggleBg = (): string => this.isChecked() ? this.props.switchBgActive : this.props.switchBgInactive;

  render(): ReactNode {
    return(
      <Styles.CheckboxToggleLabel isDisabled={this.props.isDisabled}>
        <input
          type="checkbox"
          checked={this.isChecked()}
          onChange={this.onChange}
          css={Styles.HideNativeInput}
          id={this.props.id}
        />
        <Styles.ToggleSlider
          isDisabled={this.props.isDisabled}
          switchBgActive={colors.floomMidnightBlue}
          switchBgInactive={colors.floomMidnightBlue}
          css={css`
            &:before {
              background-color: ${this.getToggleBg()};
            }
          `}
        >
          <Box
            color={this.isChecked() ? colors.uiFills : colors.middleGrey}
            pr="8px"
          >
            {this.props.activeCopy}
          </Box>
          <Box
            color={this.isChecked() ? colors.middleGrey : colors.uiFills}
            pl="8px"
          >
            {this.props.inactiveCopy}
          </Box>
        </Styles.ToggleSlider>
      </Styles.CheckboxToggleLabel>
    );
  }
}
