import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { TimeService, HTMLService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import FxTextArea from 'components/field-text-area';
import SectionHeading from 'components/section-heading';

import { NoteType } from '../order-notes-modal.types';

import * as Styles from './notes-modal-content.styles';
import * as Types from './notes-modal-content.types';

class NotesModalContent extends Component<Types.OrderNotesContentProps> {
  private isFloom = (): boolean => this.props.noteToEdit === NoteType.Floom;

  private buildStaticCopy = (type: NoteType): string => this.props.getNoteField(type) || '-';

  private generateTimestamp = (type: NoteType): string => {
    const noteField = this.props.getNoteField(type, 'updatedAt');

    return noteField ? TimeService.longFormatWithoutDate(noteField) : '';
  };

  render(): JSX.Element {
    return(
      <>
        <Box
          p="30px 20px 0 20px"
          width="100%"
        >
          <Flex
            flexWrap={this.isFloom() ? 'wrap-reverse' : 'wrap'}
            width="100%"
          >
            <Styles.NoteSection width="100%">
              <SectionHeading
                title="Floom's Note"
                hasSpacing={false}
              />
              <Styles.Timestamp>
                {this.generateTimestamp(NoteType.Floom)}
              </Styles.Timestamp>
              <Box mt="10px">
                { this.props.isEditing && this.isFloom() ? (
                  <FxTextArea
                    value={this.props.editedNote}
                    placeholder="Copy"
                    rows={5}
                    autoFocus={true}
                    onChange={this.props.onChange}
                    onKeyDown={this.props.onKeyDown}
                  />
                )
                  : (
                    <Box
                      css={textStyles.body}
                      dangerouslySetInnerHTML={{
                        __html: HTMLService.newLineToBreak(this.buildStaticCopy(NoteType.Floom))
                      }}
                    />
                  )}
              </Box>
            </Styles.NoteSection>
            <Styles.NoteSection width="100%">
              <SectionHeading
                title={this.isFloom() ? 'Florist Note' : 'Your note'}
                hasSpacing={false}
              />
              <Styles.Timestamp>
                {this.generateTimestamp(NoteType.Florist)}
              </Styles.Timestamp>
              <Box mt="10px">
                { this.props.isEditing && !this.isFloom() ? (
                  <FxTextArea
                    value={this.props.editedNote}
                    placeholder="Copy"
                    rows={5}
                    autoFocus={true}
                    onChange={this.props.onChange}
                    onKeyDown={this.props.onKeyDown}
                  />
                )
                  : (
                    <Box
                      css={textStyles.body}
                      dangerouslySetInnerHTML={{
                        __html: HTMLService.newLineToBreak(this.buildStaticCopy(NoteType.Florist))
                      }}
                    />
                  )}
              </Box>
            </Styles.NoteSection>
          </Flex>
        </Box>
      </>
    );
  }
}

export default inject('ordersStore')(observer(NotesModalContent));
