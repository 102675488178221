import React, { Component } from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { CookieSchemas } from 'lib/services/cache/cache.service.types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Text } from 'rebass';

import {
  LocalisationService,
  CacheService,
  PermissionsService
} from 'lib';

import { fontSizes } from 'utils/rebass-theme';

import potIcon from 'assets/images/plant-pot-small.svg';

import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import * as Styles from './order-cap-banner.styles';
import * as Types from './order-cap-banner.types';

class OrderCapBanner extends Component<Types.OrderCapBannerProps, Types.OrderCapBannerState> {
  state: Types.OrderCapBannerState = {
    isHidden: false
  };

  private renderDaysLeft = (daysLeft: number): string => {
    const isPlural = (daysLeft > 1);

    return `day${isPlural ? 's' : ''}`;
  };

  private onClose = (): void => {
    this.setState({ isHidden: true });

    CacheService.storeCookieObject({
      name: 'orderCapBannerDismissed',
      data: { isDismissed: true }
    });
  };

  private getOrderCapDaysLeft = (): number => {
    const expiryDate = this.props.userStore?.userMerchant?.subscription?.currentPeriodEndsAt;
    const expiryDay = moment(expiryDate).utc();
    const currentDay = moment().utc();

    return expiryDay.diff(currentDay, 'days');
  };

  private shouldDisplay = (): boolean => {
    const bannerCookie = CacheService.retrieveCookie<CookieSchemas.OrderCapBannerDismissed>('orderCapBannerDismissed');
    const hasBannerBeenDismissed = !!bannerCookie?.content?.data?.isDismissed;

    return !!this.props.merchantStore!.merchant?.areProductsDisabledOnFloom
      && !hasBannerBeenDismissed
      && !this.state.isHidden
      && !PermissionsService.isInternalRole();
  };

  private renderCopy = (daysLeft: number, merchantPlan: any): React.ReactNode => {
    const upgradeLink = (
      <Link
        to={`${NavPages.Overview}#overview-upgrade-plan`}
        css={Styles.SupportLink}
      >
        upgrade your plan
      </Link>
    );

    return (
      <>
        <Text
          fontWeight="bold"
          fontSize={fontSizes.small}
          mb="5px"
        >
          You have reached your <Styles.OrderHighlight>{LocalisationService.localisePlanName(merchantPlan)}</Styles.OrderHighlight> plan order cap for this billing period.
        </Text>
        <Text fontSize={fontSizes.small}>
          Your cap will reset in {daysLeft} {this.renderDaysLeft(daysLeft)}. Can&apos;t wait? You can {upgradeLink} to increase your order cap.
        </Text>
      </>
    );
  };

  render(): React.ReactNode {
    if (!this.shouldDisplay()) return null;

    const daysLeft = this.getOrderCapDaysLeft();
    const merchantPlan = this.props.merchantStore!.merchant?.plan?.type;

    return (
      <Notification
        type={NotificationType.Banner}
        hasIcon={true}
        hasClose={true}
        onClose={this.onClose}
        customIcon={potIcon}
        textAlign="left"
        copy={this.renderCopy(daysLeft, merchantPlan)}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  userStore: stores.userStore
}))(observer(OrderCapBanner));
