import { action, observable, makeObservable } from 'mobx';
import { TestMessageParams } from 'yup';

import {
  Merchant
} from 'generated-types.d';

import { MerchantLocationKey } from 'stores/merchant-onboarding/merchant-onboarding-store.types';

import * as Types from './merchant-detail-store.types';

export default class MerchantDetailStore {
  constructor() {
    makeObservable(this, {
      selectedMerchant: observable,
      isLoadingSelectedMerchant: observable,
      merchantDetailFieldValues: observable,
      validationErrors: observable,
      hasWholesaleEnabled: observable,
      country: observable,
      setWholesaleEnabled: action,
      clearForm: action,
      setErrors: action,
      setSelectedMerchant: action,
      resetSelectedMerchant: action,
      handleFieldChange: action,
      mapMerchantToFields: action
    });

    this.setLocaleData();
  }

  public selectedMerchant: Merchant | null = null;

  public isLoadingSelectedMerchant: boolean = true;

  public merchantDetailFieldValues: Types.MerchantDetailFieldValues = MerchantDetailStore.setFieldDefaults();

  public validationErrors: TestMessageParams[] = [];

  public country: string = '';

  public hasWholesaleEnabled: boolean = false;

  public setSelectedMerchant = (merchant: Merchant): void => {
    this.selectedMerchant = merchant;
    this.mapMerchantToFields(merchant);
    this.setWholesaleEnabled(!!merchant.wholesaleActive);
    this.isLoadingSelectedMerchant = false;
  };

  public setWholesaleEnabled = (active: boolean): void => {
    this.hasWholesaleEnabled = active;
  };

  public resetSelectedMerchant = (): void => {
    this.selectedMerchant = null;
    MerchantDetailStore.setFieldDefaults();
    this.isLoadingSelectedMerchant = true;
    this.country = '';
  };

  public setErrors = (errors: TestMessageParams[] = []): void => {
    this.validationErrors = errors;
  };

  public clearForm = (category: Types.EditFieldsConfigKey): void => {
    this.merchantDetailFieldValues[category].isDirty = false;
    this.validationErrors = [];
  };

  private setLocaleData = (location: MerchantLocationKey = 'uk'): void => {
    switch (true) {
      case location === 'uk':
        this.country = 'United Kingdom';

        break;

      case location !== 'uk':
        this.country = 'United States';

        break;
    }
  };

  private static setFieldDefaults = (): Types.MerchantDetailFieldValues => {
    return {
      basicMerchantDetails: {
        isDirty: false,
        fields: {
          givenName: '',
          familyName: '',
          email: '',
          phone: ''
        }
      },
      company: {
        isDirty: false,
        fields: {
          businessName: '',
          tradingNo: '',
          address1: '',
          address2: '',
          city: '',
          postalCode: ''
        }
      },
      businessAddress: {
        isDirty: false,
        fields: {
          businessName: '',
          address1: '',
          address2: '',
          city: '',
          postalCode: ''
        }
      }
    };
  };

  public handleFieldChange = (value: any, name: any, key: Types.EditFieldsConfigKey): void => {
    this.merchantDetailFieldValues[key].isDirty = true;
    this.merchantDetailFieldValues[key].fields[name] = value;
    this.validationErrors = this.validationErrors?.filter(error => error.path !== name);
  };

  public mapMerchantToFields = (merchant: Merchant): void => {
    this.merchantDetailFieldValues = {
      basicMerchantDetails: {
        isDirty: false,
        fields: {
          givenName: merchant.createdBy?.givenName || '',
          familyName: merchant.createdBy?.familyName || '',
          email: merchant.createdBy?.email || '',
          phone: merchant.createdBy?.phone || ''
        }
      },
      company: {
        isDirty: false,
        fields: {
          businessName: merchant.address?.businessName || '',
          tradingNo: merchant.tradingNo || '',
          address1: merchant.address?.address1 || '',
          address2: merchant.address?.address2 || '',
          city: merchant.address?.city || '',
          postalCode: merchant.address?.postalCode || ''
        }
      },
      businessAddress: {
        isDirty: false,
        fields: {
          businessName: merchant.registeredAddress?.businessName || '',
          address1: merchant.registeredAddress?.address1 || '',
          address2: merchant.registeredAddress?.address2 || '',
          city: merchant.registeredAddress?.city || '',
          postalCode: merchant.registeredAddress?.postalCode || ''
        }
      }
    };
  };
}
