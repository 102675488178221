import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card } from 'utils/css-mixins';
import { breakpoints, colors } from 'utils/rebass-theme';

import { stackedSearchBreakpoint, stackedSearchBreakpointCompact } from 'features/wholesale/wholesale.constants';

const getWidth = (isCompact: boolean): string => {
  return isCompact ? stackedSearchBreakpointCompact : stackedSearchBreakpoint;
};

export const Wrapper = styled(Box)<{
  isCompact: boolean;
}>`
  ${Card};
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  position: sticky;
  top: 70px;
  z-index: 9;

  ${({ isCompact }) => {
    return css`
      @media only screen and (max-width: ${getWidth(isCompact)}) {
        padding: 15px;
      }

      @media only screen and (max-width: ${breakpoints.small}) {
        border-radius: 0;
        padding: 10px;
        top: 60px;
      }
    `;
  }}
`;

export const Container = styled(Flex)<{
  isCompact: boolean;
}>`
  align-items: center;

  ${({ isCompact }) => {
    return css`
      @media only screen and (max-width: ${getWidth(isCompact)}) {
        flex-direction: column-reverse;
      };
    `;
  }}
`;

export const DropdownContainer = styled(Flex)`
  align-items: center;

  ${({ isCompact }) => {
    return css`
      @media only screen and (max-width: ${getWidth(isCompact)}) {
        width: 100%;
      };
    `;
  }}
`;

export const FiltersContainer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

export const Divider = styled.span`
  background-color: ${colors.shade40};
  width: 1px;
  height: 25px;
  margin: 0 9px;

  @media only screen and (min-width: ${stackedSearchBreakpoint}) {
    display: none;
  }
`;
