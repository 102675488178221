import onClientEntry from './gatsby/browser/onClientEntry';
import onRouteUpdate from './gatsby/browser/onRouteUpdate';
import shouldUpdateScroll from './gatsby/browser/shouldUpdateScroll';
import wrapRootElement from './gatsby/browser/wrapRootElement';
import wrapPageElement from './gatsby/wrapPageElement';

import './src/utils/reset-css.css';

export {
  wrapRootElement,
  onClientEntry,
  wrapPageElement,
  shouldUpdateScroll,
  onRouteUpdate
};
