import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { breakpoints, colors, fontStacks } from 'utils/rebass-theme';

import { stackedSearchBreakpoint } from 'features/wholesale/wholesale.constants';

export const Wrapper = styled(Flex)`
  z-index: 3;
  cursor: pointer;

  @media only screen and (min-width: ${stackedSearchBreakpoint}) {
    display: none;
  }

  @media only screen and (max-width: ${breakpoints.small}) {
    margin-right: 0;
  }
`;

export const Label = styled(Flex)`
  display: none;
  font-family: ${fontStacks.secondary};
  font-size: 14px;
  color: ${colors.floomMidnightBlue};
  align-items: center;

  @media only screen and (max-width: ${stackedSearchBreakpoint}) {
    display: flex;
    margin-right: 5px;
    font-size: 13px;
  }
`;
