import React, { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { AddOnsGroupOption } from 'stores/add-ons-store/add-ons-store.types';

import { Container } from 'utils/css-mixins';

import AddOnsServices from 'features/add-ons/services';

import LoadMoreButton from 'components/load-more-button';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import TableLayoutGroup from './table-layout-group';
import { TableLayoutProps } from './table-layout.types';

class TableLayout extends Component<TableLayoutProps> {
  AddOnsListService = AddOnsServices.AddOnsListService;

  componentWillUnmount = (): void => {
    this.props.addOnsStore!.resetAddOns();
  };

  getGroupKeys = (): any[] => {
    return Object.keys(this.props.items);
  };

  getGroupCount = (productGroup: string): number =>  {
    return this.props.items[productGroup].edges.length;
  };

  onLoadMore = (): void => this.AddOnsListService.loadMoreAddOns();

  hasItems = (): number => Object
    .keys(this.props.items)
    .reduce((acc, curr): number => this.props.items[curr].edges.length + acc, 0);

  render(): React.ReactNode {
    const groupKeys: AddOnsGroupOption[] = this.getGroupKeys();

    return (
      <>
        <Container>
          <WithLoading
            isLoading={this.props.isLoading
              && !groupKeys.length
              && !this.hasItems()}
            hasNoResults={!this.hasItems()}
            renderNoResults={(): ReactNode => (
              <NoResultsGeneric
                heading="No results"
                copy="There are no add-ons for this merchant."
              />
            )}
          >
            <Box
              mt="50px"
              mb="30px"
            >
              {
                groupKeys.map((addOnGroup: AddOnsGroupOption) => (
                  <Fragment key={addOnGroup}>
                    <TableLayoutGroup
                      group={this.props.items[addOnGroup]}
                      groupName={addOnGroup}
                      showHeading={false}
                    />
                    <LoadMoreButton
                      shouldDisplay={this.props.items[addOnGroup].pageInfo.hasNextPage}
                      groupName={addOnGroup}
                      onLoadMore={this.onLoadMore}
                      isLoading={this.props.isLoading}
                    />
                  </Fragment>
                ))
              }
            </Box>
          </WithLoading>
        </Container>
      </>
    );
  }
}

export default inject('addOnsStore')(observer(TableLayout));
