import styled from '@emotion/styled';
import { Box } from 'rebass';
import { mq } from 'theme';

import { colors } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;

  ${mq(1300)} {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  };

  ${mq(640)} {
    display: block;
    margin-top: 0;
  };
`;

export const ItemWrapper = styled(Box)<any>`
  + ${(): any => ItemWrapper} {
    ${mq(640)} {
      border-top: 1px solid ${colors.middleGrey};
    };
  }
`;
