import { DiscountType, DiscountApplication } from 'stores/discount-create-edit/discount-create-edit-store.types';

import { DiscountTypeOption, DiscountApplicationOption } from './discount-create-edit-layout.types';

export const DISCOUNT_TYPES: DiscountTypeOption[] = [
  {
    title: 'Percentage',
    value: DiscountType.Percent
  },
  {
    title: 'Fixed amount',
    value: DiscountType.Fixed
  },
  {
    title: 'Free shipping',
    value: DiscountType.FreeShipping
  }
];

export const DISCOUNT_APPLICATIONS: DiscountApplicationOption[] = [
  {
    title: 'All products',
    value: DiscountApplication.EntireOrder
  },
  {
    title: 'Specific products',
    value: DiscountApplication.Products
  },
  {
    title: 'Specific product types',
    value: DiscountApplication.ProductTypes
  },
  {
    title: 'Specific product occasions',
    value: DiscountApplication.ProductCategories
  }
];
