import React, { Component, Fragment, memo, ReactNode } from 'react';

import {
  NavPages
} from 'global.types';
import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';
import {
  Configure,
  StateResultsProvided
} from 'react-instantsearch-core';
import {
  connectStateResults,
  connectSearchBox,
  connectHits,
  Index,
  InstantSearch
} from 'react-instantsearch-dom';
import { Heading, Box } from 'rebass';

import {
  OrderEdge
} from 'generated-types.d';

import {
  NavService,
  Analytics
} from 'lib';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import OrderNavItem from 'features/orders/components/order-nav-item';
import { ORDER_GROUP_OPTIONS, ORDER_LIST_PAGE_LAYOUTS } from 'features/orders/graphql/helpers/order-group.config';
import { ORDER_NAV } from 'features/orders/orders.constants';
import { ExtendedOrder } from 'features/orders/orders.types';

import FieldSearch from 'components/field-search';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import PageCornerBackground from 'components/page-corner-background';

import * as Styles from './orders-home.styles';
import type * as Types from './orders-home.types';

const SearchBox = connectSearchBox(({
  hits,
  value,
  onChange,
  onLoadMore,
  onClear,
  onSelect,
  isSearchStalled,
  refine
}: Types.SearchBoxProps): any => {
  return (
    <FieldSearch
      value={value}
      isLoading={isSearchStalled}
      results={(hits && !!value.length) ? hits.map(hit => ({ node: hit })) as OrderEdge[] : []}
      onLoadMore={onLoadMore}
      onSearch={refine}
      onChange={(changeVal: string): void => {
        onChange(changeVal);
      }}
      onClear={(): void => {
        onClear();
        refine();
      }}
      onResultSelect={onSelect}
      displayNoResults={true}
    />
  );
});

class OrdersHome extends Component<Types.OrdersHomeProps> {
  state = {
    refresh: false
  };

  interval = setInterval(
    () => {
      this.setState({ refresh: true }, () => {
        this.setState({
          refresh: false
        });
      });
    },
    10000
  );

  componentDidMount(): void {
    Analytics.page(Analytics.FxPageEvent.Orders);
  }

  componentWillUnmount = (): void => {
    clearInterval(this.interval);
  };

  private onLoadMore = (): void => {
    NavService.ordersList('search');
    this.props.ordersStore!.resetQuickSearch();
  };

  private onResultSelect = (orderNo: string): void => {
    NavService.orderDetail(orderNo, NavPages.OrdersHome);
    this.props.ordersStore!.resetQuickSearch();
  };

  private renderSearchBox = connectHits<ExtendedOrder>(memo((data: any): JSX.Element => {
    return (
      <SearchBox
        value={this.props.ordersStore!.searchFilterValue}
        onChange={this.props.ordersStore!.setListSearchFilter}
        onLoadMore={this.onLoadMore}
        onClear={this.props.ordersStore!.resetQuickSearch}
        onSelect={this.onResultSelect}
        hits={data?.hits}
      />
    );
  }));

  private renderGroupContent = connectStateResults(memo((data: Types.ButtonWithStateResults): JSX.Element | null => {
    return (
      <OrderNavItem
        item={data.item}
        total={data.searchResults?.nbHits || 0}
      />
    );
  }));

  private renderWarningNotification = connectStateResults(memo((data: StateResultsProvided): any => {
    if (!data?.searchResults?.hits?.length) return null;

    const pastActiveOrdersCopy = data?.searchResults?.nbHits
      && `${data?.searchResults?.nbHits} ${pluralize('past order', data?.searchResults?.nbHits)} need your attention.`;

    return (
      <Box mb={['15px', '10px', '35px']}>
        <Notification
          type={NotificationType.Error}
          hasIcon={true}
          hasClose={false}
          icon="exclamation-circle"
          copy={pastActiveOrdersCopy}
          textAlign="left"
          onClick={(): void => NavService.ordersList('past')}
        />
      </Box>
    );
  }));

  render(): ReactNode {
    if (!this.props.ordersStore!.searchToken) return null;

    const searchClient = this.props.ordersStore!.getSearchClient();
    const merchantTimezone = this.props.merchantStore?.merchant?.timezone;

    return(
      <InstantSearch
        indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
        searchClient={searchClient}
        refresh={this.state.refresh}
      >
        <Container maxWidth="610px">
          <PageCornerBackground />
          <Styles.Wrapper>
            <Box m="auto">
              <Heading
                as="h1"
                css={textStyles.headline}
              >
                Orders
              </Heading>
              <Index
                indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
                indexId="orders-home-page-search-box"
              >
                <Box
                  mt={['20px', '30px', '40px']}
                  mb={['20px', '20px', '30px']}
                >
                  <this.renderSearchBox
                    // @ts-ignore
                    searchValue={this.props.ordersStore!.searchFilterValue}
                  />
                </Box>
              </Index>
              <Index
                indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
                indexId="past-orders-notification-banner"
              >
                <Configure
                  filters={ORDER_GROUP_OPTIONS.pastActiveOrders.filter.trim()}
                  numericFilters={ORDER_LIST_PAGE_LAYOUTS.past.numericFilter(merchantTimezone).trim()}
                />
                <this.renderWarningNotification />
              </Index>
              <Styles.OrderNav>
                {ORDER_NAV.map(item => (
                  <Fragment key={item.id}>
                    <Index
                      indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
                      indexId={item.id}
                    >
                      <Configure
                        filters={item.filters}
                        numericFilters={item.numericFilters(merchantTimezone).trim()}
                      />
                      <this.renderGroupContent
                        refresh={this.state.refresh}
                        item={item}
                      />
                    </Index>
                  </Fragment>
                ))}
              </Styles.OrderNav>
            </Box>
          </Styles.Wrapper>
        </Container>
      </InstantSearch>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  merchantStore: stores.merchantStore
}))(observer(OrdersHome));
