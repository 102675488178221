import {
  FC
} from 'react';

import { inject, observer } from 'mobx-react';

import { WholesaleShopFilters } from '../wholesale-shop-filters/wholesale-shop-filters';
import { WholesaleShopSearchBox } from '../wholesale-shop-search-box/wholesale-shop-search-box';
import { WholesaleShopSort } from '../wholesale-shop-sort/wholesale-shop-sort';
import { WholesaleShopSupplierDropdown } from '../wholesale-shop-supplier-dropdown/wholesale-shop-supplier-dropdown';

import {
  Wrapper,
  Container,
  DropdownContainer,
  FiltersContainer,
  Divider
} from './wholesale-shop-list-controls.styles';
import {
  WholesaleShopListControlsProps
} from './wholesale-shop-list-controls.types';

export const WholesaleShopListControls: FC<WholesaleShopListControlsProps> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  openFiltersModal,
  wholesaleShopStore
}) => {
  const isOrderSidebarVisible = wholesaleShopStore!.isDisplayingOrder;

  return (
    <Wrapper isCompact={isOrderSidebarVisible}>
      <Container isCompact={isOrderSidebarVisible}>
        <WholesaleShopSearchBox
          isCompact={isOrderSidebarVisible}
        />
        <DropdownContainer isCompact={isOrderSidebarVisible}>
          <WholesaleShopSupplierDropdown />
          <FiltersContainer>
            <WholesaleShopFilters
              openFiltersModal={openFiltersModal}
            />
            <Divider />
            <WholesaleShopSort />
          </FiltersContainer>
        </DropdownContainer>
      </Container>
    </Wrapper>
  );
}));
