import styled from '@emotion/styled';
import { Box } from 'rebass';

import { borderRadius, colors, textStyles } from 'utils/rebass-theme';

export const DetailsColumn = styled(Box)<any>`
  width: 50%;
`;

export const GreyBox = styled(Box)<any>`
  ${textStyles.body};
  padding: 20px 25px;
  background: ${colors.lightGrey};
  border-radius: ${borderRadius.medium}
`;

export const WhiteBox = styled(Box)<any>`
  ${textStyles.body};
  padding: 20px 25px;
`;

export const IconBox = styled(Box)<any>`
  flex-basis: 24px;
  min-width: 24px;
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const MetaSubCopy = styled(Box)<any>`
  ${textStyles.subhead};

  color: ${colors.shade40};
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 7px;
`;

export const ReIndexInstructions = styled(Box)`
  ${textStyles.subhead};

  color: ${colors.shade40};
  font-size: 12px;
`;

export const FeatureToggleFlag = styled(Box)<any>`
  ${textStyles.body};
  margin-top: 20px;
`;

export const SubCopy = styled(Box)`
  ${textStyles.body}
  color: ${colors.shade60};
  margin-top: 8px;
  font-size: 12px;
`;
