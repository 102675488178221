import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const OrderPauseBanner: any = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const OrderPauseNotification: any = styled(Box)`
  flex: 1;

  @media (max-width: 1000px) {
    margin-top: 10px;
  }
`;

export const OrderPauseCheckbox: any = styled(Flex)`
  align-items: center;
  cursor: pointer;
  padding-right: 30px;

  @media (max-width: 1000px) {
    padding-right: 0;
  }
`;

export const NotificationCopy: any = styled.span`
  button {
    color: ${colors.uiFills};
    text-decoration: underline;
  }
`;
