import {
  FC,
  Fragment,
  useContext,
  useEffect,
  useState
} from 'react';

import { css } from '@emotion/react';
import { useMatch, useLocation } from '@reach/router';
import { PageProps } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import {
  SearchState
} from 'react-instantsearch-core';
import {
  Configure,
  connectStateResults,
  connectCurrentRefinements,
  connectRefinementList,
  InstantSearch
} from 'react-instantsearch-dom';
import { Box } from 'rebass';

import { CatalogItemType } from 'generated-types.d';

import {
  CatalogService,
  MerchantService,
  NavService
} from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import WholesaleShopStore from 'stores/wholesale/wholesale-shop-store/wholesale-shop-store';

import { Container } from 'utils/css-mixins';
import { breakpoints } from 'utils/rebass-theme';
import { TEST_IDS } from 'utils/test/data-test-ids';

import NoResultsGif from 'assets/images/wholesale/no-results.gif';

import { WholesaleContext } from 'pages/wholesale';

import { useWindowScroll } from 'hooks/useWindowScroll/useWindowScroll';
import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';

import { CatalogFiltersModal } from 'features/modal-dialogue/components/modals/catalog-filters-modal/catalog-filters-modal';
import { CatalogFilterCategory } from 'features/modal-dialogue/components/modals/catalog-filters-modal/catalog-filters-modal.types';
import { CATALOG_ITEM_FACET_CONFIG } from 'features/modal-dialogue/components/modals/catalog-filters-modal/catalog-filters.constants';
import { CatalogFiltersBody } from 'features/modal-dialogue/components/modals/catalog-filters-modal/components/catalog-filters-body/catalog-filters-body';
import { FiltersModalHeader, ResultsCount } from 'features/modal-dialogue/components/modals/catalog-filters-modal/components/catalog-filters-header/catalog-filters-header';
import { WholesaleShopFloomerInstructions } from 'features/wholesale/components/wholesale-shop-floomer-instructions/wholesale-shop-floomer-instructions';
import { WholesaleShopHeader } from 'features/wholesale/components/wholesale-shop-header/wholesale-shop-header';
import { WholesaleShopListControls } from 'features/wholesale/components/wholesale-shop-list-controls/wholesale-shop-list-controls';
import { WholesaleShopListPagination } from 'features/wholesale/components/wholesale-shop-list-pagination/wholesale-shop-list-pagination';
import { WholesaleShopList } from 'features/wholesale/components/wholesale-shop-list/wholesale-shop-list';
import { WholesaleShopOrderSummary } from 'features/wholesale/components/wholesale-shop-order-summary/wholesale-shop-order-summary';
import { stackedSearchBreakpointPx } from 'features/wholesale/wholesale.constants';

import AccountChangeNotification from 'components/account-change-notification';
import ComingSoon from 'components/coming-soon/coming-soon';
import { LoadingImage } from 'components/loading-image/loading-image';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import {
  ContainerStyles,
  InlineFiltersWrapper,
  InnerContainer,
  Mask,
  MaskInner,
  OrderSummaryWrapper
} from './wholesale-shop.styles';

const searchClient = CatalogService.createCatalogAlgoliaClient();
const REFRESH_TIMEOUT = 2 * 60 * 1000; // 2 minutes

const NotFound: FC = () => {
  return (
    <Container maxWidth={1940}>
      <Box>
        <WholesaleShopFloomerInstructions />
        <ComingSoon
          title="Want wholesale access?"
          copy="Get in touch with Floom and we'll get you set up!"
        />
      </Box>
    </Container>
  );
};

const SubscriptionErrorDialog: FC<{
  merchantStore?: MerchantStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(({
  merchantStore
}) => {
  const hasActiveSubscription = MerchantService.hasActiveSubscription(merchantStore!.merchant);

  return (
    <AccountChangeNotification
      context="wholesale"
      wrapElement={children => {
        if (!merchantStore!.merchant || hasActiveSubscription) return null;

        return (
          <Box mt="10px">
            {children}
          </Box>
        );
      }}
    />
  );
}));

const OrderSidebar: FC<{
  wholesaleShopStore?: WholesaleShopStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  wholesaleShopStore
}) => {
  const { height, width } = useWindowSize();

  const scrollPosition = useWindowScroll();
  const currentLocation = useLocation();

  useEffect(() => {
    const currentPath = `${currentLocation?.pathname}/`;

    if (width && width <= 880 &&
      scrollPosition > 0 &&
      wholesaleShopStore!.isDisplayingOrder &&
      currentPath === NavPages.WholesaleItem) {
      window?.scrollTo?.({
        top: 0
      });
    }
  }, [wholesaleShopStore!.isDisplayingOrder, currentLocation]);

  if (!wholesaleShopStore!.isDisplayingOrder) return null;

  return (
    <OrderSummaryWrapper
      data-testid={TEST_IDS.WholesaleShop.basket.container}
      as="aside"
      windowHeight={height}
    >
      <WholesaleShopOrderSummary />
    </OrderSummaryWrapper>
  );
}));

const RenderNoResults = connectStateResults((props): JSX.Element | null => {
  if (props.searchResults?.nbHits !== 0) return null;

  return (
    <Box mt="20px">
      <NoResultsGeneric
        image={NoResultsGif}
        heading="No results found"
        copy="Try refining your search, filters, or reset all filters"
        imageStyles={css`
          width: 180px;

          @media (max-width: ${breakpoints.small}) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        `}
      />
    </Box>
  );
});

const VirtualRefinementList = connectRefinementList(() => null);
const VirtualCurrentRefinements = connectCurrentRefinements(() => null);

export const CatalogVirtualFilters: FC<{
  selectedCategory: CatalogFilterCategory;
  refinementsState: any;
}> = ({
  selectedCategory,
  refinementsState
}) => {
  return (
    <Fragment>
      <VirtualRefinementList
        attribute="type"
        defaultRefinement={refinementsState?.type || []}
      />
      {CATALOG_ITEM_FACET_CONFIG[selectedCategory]?.map?.((config, index) => {
        return (
          <Fragment key={index}>
            <VirtualRefinementList
              attribute={config.refinement}
              defaultRefinement={refinementsState?.[config.refinement] || []}
            />
          </Fragment>
        );
      })}
      <VirtualCurrentRefinements />
    </Fragment>
  );
};

export const WholesaleShop: FC<PageProps & {
  wholesaleShopStore?: WholesaleShopStore;
  merchantStore?: MerchantStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopStore: stores.wholesaleShopStore,
  merchantStore: stores.merchantStore
}))(observer(({
  wholesaleShopStore,
  merchantStore
}) => {
  const {
    hasWholesaleShopAccess
  } = useContext(WholesaleContext);

  const urlMatch = useMatch(`${NavPages.WholesaleItem}:catalogItemId/`);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<CatalogFilterCategory>(CatalogItemType.Flower);
  const [refinementsState, setRefinementsState] = useState<SearchState>({});
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [refreshTime, setRefreshTime] = useState<number>(Date.now());
  const { width } = useWindowSize();

  useEffect(() => {
    fetchData();
    navigateToDetail();

    return () => {
      wholesaleShopStore!.hideOrderSidebar();
    };
  }, []);

  const fetchData = async (): Promise<void> => {
    wholesaleShopStore!.fetchSupplierDataForMerchant({
      merchantId: merchantStore!.merchant?.id || '',
      shouldRehydrate: false
    });
  };

  const navigateToDetail = (): void => {
    if (!!urlMatch?.catalogItemId) {
      NavService.wholesaleItemDetail(urlMatch.catalogItemId!, true);
    }
  };

  const handleSearchStateChange = (state: SearchState): void => {
    if (
      (refinementsState?.type?.length !== state?.refinementList?.type?.length
      || refinementsState?.subType?.length !== state?.refinementList?.subType?.length)
      && (Date.now() - refreshTime) >= REFRESH_TIMEOUT
    ) {
      // clear algolia search results cache
      setRefreshSearch(true);
      setRefreshTime(Date.now());

      setTimeout(() => {
        setRefreshSearch(false);
      }, 1000);
    }

    const isFilterSidebarShowed = width && width > stackedSearchBreakpointPx;

    if ((isOpen || isFilterSidebarShowed) && state?.refinementList) {
      if (isOpen) {
        setRefinementsState(prevprops => {
          return {
            ...prevprops,
            ...state.refinementList
          };
        });
      } else {
        setRefinementsState(state?.refinementList);
      }
    }
  };

  const closeFiltersModal = (): void => {
    setIsOpen(false);
  };

  const openFiltersModal = (): void => {
    setIsOpen(true);
  };

  const getFilters = (): string => {
    if (wholesaleShopStore!.selectedSupplier) {
      const supplierId = wholesaleShopStore!.selectedSupplier.id;

      return `isAvailable.supplier:${supplierId}`;
    }

    const allSuppliers = wholesaleShopStore!.merchantWholesaleSuppliers.map(merchantWholesaleSupplier => {
      const supplierId = merchantWholesaleSupplier?.supplier?.id;

      return `isAvailable.supplier:${supplierId}`;
    }).join(' OR ');

    return allSuppliers;
  };

  return (
    <InstantSearch
      indexName={process.env.ALGOLIA_CATALOG_INDEX!}
      searchClient={searchClient}
      onSearchStateChange={handleSearchStateChange}
      refresh={refreshSearch}
    >
      <Configure
        hitsPerPage={120}
        filters={getFilters()}
      />
      <WholesaleShopHeader
        shouldDisplay={{
          deliveryDate: hasWholesaleShopAccess,
          orderButton: hasWholesaleShopAccess
        }}
      />
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        isLoading={!!wholesaleShopStore!.isLoading}
        hasNoResults={(!wholesaleShopStore!.isLoading && !wholesaleShopStore!.canDeliver) || !hasWholesaleShopAccess}
        renderNoResults={() => (
          <NotFound />
        )}
        renderLoading={() => (
          <LoadingImage
            text="Loading shop..."
            imageSrc={NoResultsGif}
            imageAlt="plants"
          />
        )}
      >
        <Mask>
          <MaskInner />
        </Mask>
        <ContainerStyles>
          <SubscriptionErrorDialog />
          <InnerContainer>
            <InlineFiltersWrapper>
              <CatalogFiltersBody
                refinementsState={refinementsState}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                renderHeader={() => {
                  return (
                    <Fragment>
                      <FiltersModalHeader />
                      <ResultsCount />
                    </Fragment>
                  );
                }}
              />
            </InlineFiltersWrapper>
            <Box
              flex="1"
              mb="4"
            >
              <WholesaleShopListControls
                openFiltersModal={openFiltersModal}
              />
              <RenderNoResults />
              <WholesaleShopList />
              <WholesaleShopListPagination />
              <CatalogVirtualFilters
                selectedCategory={selectedCategory}
                refinementsState={refinementsState}
              />
              <CatalogFiltersModal
                isOpen={isOpen}
                closeModal={closeFiltersModal}
                refinementsState={refinementsState}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </Box>
            <OrderSidebar />
          </InnerContainer>
        </ContainerStyles>
      </WithLoading>
    </InstantSearch>
  );
}));
