import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { mq } from 'utils/css-mixins/css-mixins';
import {
  textStyles,
  colors,
  breakpoints
} from 'utils/rebass-theme';

import {
  SelectWrap,
  Select,
  DropdownLabel
} from 'components/dropdown-native/dropdown-native.styles';

export const Wrapper = styled(Box)`
  display: grid;
  grid-template-areas: 'search filters loader . sort layout';
  grid-template-rows: auto;
  align-items: center;
  grid-template-columns: auto max-content auto auto auto max-content;
  grid-gap: 24px;
  margin: 40px 0 20px 0;

  @media (max-width: ${breakpoints.large}) {
    grid-template-columns: min-content;
    grid-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas:
      'search search sort sort'
      'filters loader loader layout';
  }
`;

export const SortDropdown = css`
  grid-area: sort;
  min-width: max-content;
  justify-self: end;

  ${mq.lessThan('medium')} {
    ${SelectWrap}, ${Select}  {
      width: 100%;
    }

    ${DropdownLabel}  {
      display: none;
    }
  }
`;

interface FilterStyleProps {
  isOpen: boolean;
}

export const FilterCount = styled(Box)`
  border-radius: 100px;
  background: ${colors.uiFills};
  border: 2px solid ${colors.floomMidnightBlue};
  color: ${colors.floomMidnightBlue};
  width: 35px;
  height: 35px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterButton = styled(Flex)<FilterStyleProps>`
  height: 51px;
  background: ${({ isOpen }): string => isOpen ? colors.uiFills : colors.floomMidnightBlue};
  border: 2px solid ${colors.floomMidnightBlue};
  border-radius: 3px;
  padding: 0 20px;
`;

export const FilterCopy = styled(Box)<FilterStyleProps>`
  ${textStyles.subhead}
  color: ${({ isOpen }): string => isOpen ? colors.floomMidnightBlue : colors.uiFills};
  margin-top: -4px;
  padding-right: 10px;
`;

export const FilterIcon = (isOpen: boolean): any => css`
  transform: scale(0.8);
  margin-top: 2px;

  path {
    fill: ${isOpen ? colors.floomMidnightBlue : colors.uiFills};
  }
`;

export const DownloadButton = styled.div`
  display: inline-block;
  align-self: flex-end;
`;
