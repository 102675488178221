import { Order, OrderStatus, OrderItem, Customer, UserRole } from 'generated-types';

import { InputType } from 'components/field-text/field-text.types';

export type OrderGroupOption =
  | 'withFlorist'
  | 'prepared'
  | 'onTheWay'
  | 'delivered'
  | 'failedDelivery'
  | 'cancelled'
  | 'today'
  | 'tomorrow'
  | 'followingMonth'
  | 'pastOrders'
  | 'pastActiveOrders'
  | 'allResults'
  | 'searchResults';

export enum OrderStatusSlug {
  Open = 'open',
  Prepared = 'prepared',
  OnTheWay = 'onTheWay',
  Delivered = 'delivered',
  FailedDelivery = 'failedDelivery',
  Cancelled = 'cancelled'
}

export enum DeliveryType {
  Nationwide = 'nationwide',
  Local = 'local'
}

export enum AddressType {
  Commercial = 'commercial',
  Residential = 'residential'
}

export type OrderPageRoute =
  | 'today'
  | 'tomorrow'
  | 'upcoming'
  | 'past'
  | 'search';

export type OrderSortOptionKey =
  | 'orderedAt_DESC'
  | 'orderedAt_ASC'
  | 'new_ASC'
  | 'new_DESC'
  | 'deliverAt_DESC'
  | 'deliverAt_ASC';

export type OrderFormModalType =
  | 'giftMessage'
  | 'deliveryDate'
  | 'failedDelivery'
  | 'deliveryInstructions'
  | 'deliverySafePlace'
  | 'billingAddress'
  | 'deliveryAddress'
  | 'senderDetails';

export type OrderFormFieldID =
  | 'businessName'
  | 'recipientFullName'
  | 'phone'
  | 'address1'
  | 'address2'
  | 'city'
  | 'country'
  | 'postalCode'
  | 'region'
  | 'giftMessage'
  | 'deliverAt'
  | 'deliveryFailedReason'
  | 'deliveryInstructions'
  | 'deliverySafePlace'
  | 'familyName'
  | 'givenName'
  | 'email';

export type OrderFormFieldType =
  | 'text'
  | 'textarea'
  | 'datepicker'
  | 'select'
  | 'region';

export type OrderPermissionOption =
  | 'status'
  | 'deliverAt'
  | 'giftMessages'
  | 'deliveryInstructions'
  | 'deliverySafePlace'
  | 'shippingAddress'
  | 'billingAddress'
  | 'emailAddress'
  | 'changeNewStatus'
  | 'floomNote'
  | 'floristNote'
  | 'senderDetails';

export type OrderPermissions = {
  [key in OrderPermissionOption]: {
    restrictedRoles: UserRole[];
    floristChannelRoles: UserRole[];
  };
};

export interface BaseField {
  fieldId: string;
  fieldType: OrderFormFieldType;
  label?: string;
  shouldDisplay?: (value: any) => boolean;
}

export interface TextareaField extends BaseField {
  placeholder: string;
}

export interface SelectField extends BaseField {
  options: ((value: any) => any[]) | any[];
  titleField: string;
  valueField: string;
  formatValue: any;
  placeholder?: string;
}

export interface TextField extends BaseField {
  inputType: InputType;
  value: string | number;
  placeholder?: string;
  isSpellCheckEnabled: boolean;
}

export type OrderFormField =
  | TextareaField
  | SelectField
  | TextField;

export type OrderFormModalConfig = {
  [key in OrderFormModalType]: {
    title: string;
    buttonText: string;
    action: any;
    fields: OrderFormField[];
  }
};

export interface OrderFormModalDataItem {
  id: OrderFormFieldID;
  value: string;
}

export interface OrderFormModalMetadata {
  orderNo: string;
  itemId?: string;
  index?: number;
}

export interface ExtendedOrderStatus extends OrderStatus {
  slug: OrderStatusSlug;
}

export interface ExtendedOrder extends Order {
  objectID: string;
  status: ExtendedOrderStatus;
  orderItems: OrderItem[];
  customerUser: Customer;
}

export interface OrderItemAddonSnapshot {
  id: string;
  quantity: number;
  price: number;
  title: string;
  brand?: string;
  image: string;
}
