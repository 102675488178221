import {
  connectSearchBox,
  SearchBoxProvided
} from 'react-instantsearch-core';

import { TEST_IDS } from 'utils/test/data-test-ids';

import Icon from 'components/icon';
import IconButton from 'components/icon-button';

import {
  ClearIconWrapper,
  InputIconWrapper,
  InputWrapper,
  Input
} from './wholesale-shop-search-box.styles';

export const WholesaleShopSearchBox = connectSearchBox(({
  currentRefinement,
  refine,
  isCompact
}: SearchBoxProvided & {
  isCompact: boolean;
}): any => {
  return (
    <InputWrapper isCompact={isCompact}>
      <InputIconWrapper>
        <Icon iconName="wholesale-search" />
      </InputIconWrapper>
      <Input
        type="search"
        placeholder="Search by flower name"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        data-testid={TEST_IDS.WholesaleShop.searchBar}
      />
      {!!currentRefinement.length && (
        <ClearIconWrapper>
          <IconButton
            onClick={() => refine('')}
            iconName="cross-small"
          />
        </ClearIconWrapper>
      )}
    </InputWrapper>
  );
});
