import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import moment from 'moment-timezone';
import { Flex, Box } from 'rebass';

import { Discount } from 'generated-types.d';

import { CurrencyService, NavService } from 'lib';

import { colors } from 'utils/rebass-theme';

import DiscountService from 'features/settings/components/discounts/services';

import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType, TableLayoutEntityConfigProps } from 'components/entity-list/table-layout-entity.types';
import Icon from 'components/icon';
import StyledLink from 'components/styled-link/styled-link';

import * as Styles from './discount-list-item.styles';
import * as Types from './discount-list-item.types';

export default class DiscountListItem extends Component<Types.DiscountListItemProps, Types.DiscountListItemState> {
  state: Types.DiscountListItemState = {
    isOpen: false,
    isLoadingAnalytics: true,
    analyticsData: null,
    optimisticallyActive: false,
    isActive: false
  };

  private toggleItem = (): void => {
    if (!this.state.isOpen) {
      this.fetchDiscountAnalytics();
    }

    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  private navigateToDiscount = (): void => {
    NavService.discountCreateEdit('edit', this.props.item.id);
  };

  private fetchDiscountAnalytics = async (): Promise<void> => {
    if (!this.state.analyticsData) {
      try {
        const analytics = await DiscountService.fetchDiscountAnalytics(this.props.item);

        this.setState({
          isLoadingAnalytics: false,
          analyticsData: analytics
        });
      } catch (error) {
        this.setState({ isLoadingAnalytics: false });
      }
    }
  };

  private renderAnalytics = (analyticsProp: keyof Pick<Discount, 'totalRevenue' | 'amountDiscounted'>): string => {
    switch (true) {
      case this.state.isLoadingAnalytics:

      default:
        return 'Loading...';

      case !this.state.isLoadingAnalytics && !this.state.analyticsData:
        return 'Failed to load';

      case !!this.state.analyticsData:
        return CurrencyService.formatPrice(this.state.analyticsData?.[analyticsProp] || 0, this.props.item.merchant!.currency!);
    }
  };

  private toggleActiveState = (isChecked: boolean): void => {
    try {
      DiscountService.toggleIsActive(this.props.item.id, isChecked);

      this.setState({
        optimisticallyActive: true,
        isActive: isChecked
      });
    } catch (error) {
      this.setState({ isActive: !isChecked });
    }
  };

  private cellConfig = (): TableLayoutEntityConfigProps[] => {
    return [
      {
        cellType: CellType.CheckboxToggle,
        fixedWidth: '90px',
        props: {
          onChange: (value: any): any => this.toggleActiveState(value),
          isChecked: this.state.optimisticallyActive ? this.state.isActive : this.props.item.isActive,
          switchActiveCopy: 'Yes',
          switchInactiveCopy: 'No',
          switchBgActive: colors.validationText,
          switchBgInactive: colors.errorText
        }
      },
      {
        cellType: CellType.LinkTitle,
        flexGrow: '1',
        customCss: css`
          color: ${colors.shade60};
        `,
        props: {
          linkTitle: {
            text: this.props.item.code,
            onClick: (e: React.MouseEvent<HTMLElement>): void => {
              e.stopPropagation();
              this.navigateToDiscount();
            }
          },
          subtitle: this.props.item.description
        }
      },
      {
        cellType: CellType.Text,
        fixedWidth: '115px',
        props: {
          text: !!this.props.item.startsAt ? moment(this.props.item.startsAt).format('DD/MM/YYYY') : '-'
        }
      },
      {
        cellType: CellType.Text,
        fixedWidth: '115px',
        props: {
          text: !!this.props.item.endsAt ? moment(this.props.item.endsAt).format('DD/MM/YYYY') : '-'
        }
      },
      {
        cellType: CellType.Text,
        fixedWidth: '115px',
        props: {
          text: !!this.props.item.lastEditedAt ? moment(this.props.item.lastEditedAt).format('DD/MM/YYYY') : '-'
        }
      },
      {
        cellType: CellType.LinkTitle,
        fixedWidth: '50px',
        props: {
          linkTitle: {
            text: 'Edit',
            onClick: (e: React.MouseEvent<HTMLElement>): void => {
              e.stopPropagation();
              this.navigateToDiscount();
            }
          }
        }
      },
      {
        cellType: CellType.Custom,
        fixedWidth: '150px',
        customInnerElement: (
          <Flex alignItems="center">
            <Box onMouseOver={this.fetchDiscountAnalytics}>
              <StyledLink>View insights</StyledLink>
            </Box>
            <Box
              pl="10px"
              pt="10px"
            >
              <Icon iconName={this.state.isOpen ? 'expand-open' : 'expand-closed'} />
            </Box>
          </Flex>
        )
      }
    ];
  };

  private detailCellConfig = (): TableLayoutEntityConfigProps[] => {
    return [
      {
        cellType: CellType.Text,
        fixedWidth: '90px',
        props: {
          title: 'Insights:'
        }
      },
      {
        cellType: CellType.Text,
        customCss: css`
          border-right: ${colors.middleGrey} 1px solid;
          padding-right: 30px;
          min-width: 250px;
        `,
        props: {
          title: 'Discount used',
          subtitle: `${this.props.item.usageCount || 0} times ${!!this.props.item.usageLimit ? `(${this.props.item.usageLimit - (this.props.item.usageCount || 0)} remaining)` : ''}`
        }
      },
      {
        cellType: CellType.Text,
        customCss: css`
          border-right: ${colors.middleGrey} 1px solid;
          padding: 0 30px;
          min-width: 175px;
        `,
        props: {
          title: 'Order value placed',
          subtitle: this.renderAnalytics('totalRevenue')
        }
      },
      {
        cellType: CellType.Text,
        customCss: css`
          padding-left: 30px;
          min-width: 175px;
        `,
        props: {
          title: 'Amount discounted',
          subtitle: this.renderAnalytics('amountDiscounted')
        }
      }
    ];
  };

  render(): ReactNode {
    if (!this.props.item) return null;

    return (
      <EntityListRow
        key={this.props.item.id}
        removeOverflow={true}
      >
        <Flex
          width="100%"
          key={this.props.item.id}
          p="18px 10px"
          alignItems="center"
          onClick={this.toggleItem}
          css={css`
            cursor: pointer;
          `}
        >
          <TableLayoutEntity config={this.cellConfig()} />
        </Flex>
        <Styles.ItemBody
          isOpen={this.state.isOpen}
          role="row"
        >
          <Flex
            alignItems="center"
          >
            <TableLayoutEntity config={this.detailCellConfig()} />
          </Flex>
        </Styles.ItemBody>
      </EntityListRow>
    );
  }
}
