import { ApolloError } from '@apollo/client';
import { splitSearchableValue } from 'lib/helpers/graphql/graphql-helpers';
import { SearchState } from 'react-instantsearch-core';
import store from 'stores';

import {
  Order,
  ExportOrdersJsonResponse,
  OrderExport,
  OrderWhereInput,
  QueryExportOrdersListArgs,
  OrderOrderByInput
} from 'generated-types.d';

import {
  CacheService,
  GraphQL,
  PermissionsService
} from 'lib';

import {
  OrdersListLayout
} from 'stores/orders/orders-store.types';

import { ORDER_LIST_PAGE_LAYOUTS } from 'features/orders/graphql/helpers/order-group.config';
import { ORDER_EXPORT_QUERY } from 'features/orders/graphql/queries/order-queries';
import {
  OrderPageRoute,
  OrderStatusSlug,
  ExtendedOrder
} from 'features/orders/orders.types';

import {
  OrdersAPIService,
  OrderEditService
} from '..';

class OrderListService {
  public fetchOrder = async (id: string): Promise<any> => {
    store.ordersStore.setDetailLoading();

    return OrdersAPIService.fetchOrder(id)
      .then((order: ExtendedOrder): any => {
        if (!PermissionsService.isInternalRole() && !!store.userStore.merchantId && order.merchant.id !== store.userStore.merchantId) {
          return Promise.reject();
        }

        store.ordersStore.setOrder(order);
      })
      .catch(() => {
        store.ordersStore.setDetailLoading(false);
      });
  };

  public updateStatusFromList = async (statusSlug: OrderStatusSlug, order: Order): Promise<Order> => {
    return OrderEditService.updateOrderStatus(statusSlug, order)
      .then(resp => {
        store.toasterStore.popSuccessToast(`Order status for ${order.orderNo.slice(0, 7)}`, 'change');

        return resp;
      })
      .catch((error: ApolloError) => {
        store.toasterStore.popErrorToast(`order status (${order.orderNo.slice(0, 7)})`, 'change');
        store.ordersStore.setError('orderList', error);

        return Promise.reject(error);
      });
  };

  public updateLayout = (layoutType: OrdersListLayout): void => {
    store.ordersStore.setLayout(layoutType);
  };

  public setListLayout = (listLayout: OrderPageRoute): void => {
    store.ordersStore.setListLayout(listLayout);
  };

  public cacheListFilters = (filters: SearchState): void => {
    if (!!filters?.refinementList) {
      CacheService.storeCookieObject({
        name: 'orderFilters',
        data: filters.refinementList
      });
    }
  };

  public fetchListFilterCache = (): SearchState['refinementList'] => {
    const cache = CacheService.retrieveCookie<any>('orderFilters');

    return cache?.content?.data || {};
  };

  public isSearchPage = (listLayout: OrderPageRoute): boolean => listLayout === 'search';

  private buildOrderExportWhereArgs = (variables: SearchState = {}): OrderWhereInput => {
    const whereInput: OrderWhereInput = {
      OR: [{
        AND: []
      }]
    };

    const addFilterGroup = (input: OrderWhereInput[]): void => {
      whereInput.OR![0].AND! = [
        ...whereInput.OR![0].AND!,
        {
          OR: input
        }
      ];
    };

    if (!variables.refinementList) return whereInput;

    Object.keys(variables.refinementList || {}).forEach(key => {
      if (!variables.refinementList?.[key] || !Array.isArray(variables.refinementList?.[key])) return;

      const selectedItems = variables.refinementList[key];

      switch (key) {
        case 'channel': {
          addFilterGroup(selectedItems.map((item: any) => {
            return {
              channel: item
            };
          }));

          break;
        }

        case 'commercial': {
          addFilterGroup(selectedItems.map((item: any): OrderWhereInput => {
            if (item === 'false') {
              return {
                OR: [
                  { commercial: false },
                  { commercial: null }
                ]
              };
            } else if (item === 'true') {
              return {
                commercial: true
              };
            }

            return {};
          }));

          break;
        }

        case 'currency': {
          addFilterGroup(selectedItems.map((item: any): OrderWhereInput => {
            return {
              currency: item
            };
          }));

          break;
        }

        case 'nationwide': {
          addFilterGroup(selectedItems.map((item: any): OrderWhereInput => {
            if (item === 'false') {
              return {
                OR: [
                  { nationwide: false },
                  { nationwide: null }
                ]
              };
            } else if (item === 'true') {
              return {
                nationwide: true
              };
            }

            return {};
          }));

          break;
        }

        case 'status.title': {
          addFilterGroup(selectedItems.map((item: any): OrderWhereInput => {
            return {
              status: {
                title: item
              }
            };
          }));

          break;
        }
      }
    });

    return whereInput;
  };

  public fetchOrdersForExport = async (searchState: SearchState = {}): Promise<ExportOrdersJsonResponse> => {
    const searchableFilters: OrderWhereInput[] = !!searchState?.query?.length
      ? splitSearchableValue(searchState.query).map(value => {
        return {
          searchable_contains: value
        };
      })
      : [];

    const where: OrderWhereInput = {
      deletedAt: null,
      merchant: {
        id: store.merchantStore?.merchant?.id
      },
      ...this.buildOrderExportWhereArgs(searchState),
      AND: [
        ...searchableFilters,
        ORDER_LIST_PAGE_LAYOUTS[store.ordersStore.listLayout].numericFilterGraphQL(store.merchantStore?.merchant?.timezone)
      ]
    };

    const first = 50;
    let skip = 0;
    let orders: OrderExport[] = [];
    let hasNextPage = true;

    const variables: QueryExportOrdersListArgs = {
      orderBy: OrderOrderByInput.OrderedAtDesc,
      where: where,
      first: first,
      skip: skip
    };

    while (hasNextPage) {
      try {
        variables.first = first;
        variables.skip = skip;

        const result = await GraphQL.query(ORDER_EXPORT_QUERY, variables, 'no-cache');

        skip = skip + first;
        hasNextPage = result.data?.exportOrdersList?.data?.length === first;

        if (result.data?.exportOrdersList?.data?.length) {
          orders = [
            ...orders,
            ...result.data.exportOrdersList.data
          ];
        }
      } catch (error) {
        hasNextPage = false;
        store.ordersStore.setError('orderList', error);
        store.toasterStore.popErrorToast('your orders', 'retrieve');

        return Promise.reject(error);
      }
    }

    return {
      data: orders
    };
  };
}

export default new OrderListService();
