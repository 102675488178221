import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { breakpoints, colors } from 'utils/rebass-theme';

import {
  stackedSearchBreakpoint,
  stackedSearchBreakpointCompact
} from 'features/wholesale/wholesale.constants';

import {
  getPlaceholderStyle,
  inputReset
} from 'components/field-text/field-text.styles';

const getWidth = (isCompact: boolean): string => {
  return isCompact ? stackedSearchBreakpointCompact : stackedSearchBreakpoint;
};

export const InputWrapper = styled.div<{
  isCompact: boolean;
}>`
  position: relative;
  flex-grow: 1;
  margin-right: 20px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    margin-right: 10px;
  }

  ${({ isCompact }) => {
    return css`
      @media only screen and (max-width: ${getWidth(isCompact)}) {
        margin-top: 10px;
        margin-right: 0;
        width: 100%;
      }
    `;
  }}
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  pointer-events: none;
`;

export const ClearIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding-left: 40px;
  padding-right: 35px;
  background: ${colors.lightGrey};
  font-size: 14px;
  color: ${colors.floomMidnightBlue};
  border-radius: 8px;

  &:focus {
    outline: none;
  }

  ${inputReset}
  ${getPlaceholderStyle()};
`;
