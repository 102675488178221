import { DocumentNode, gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

import { DiscountQueryVars } from 'features/settings/components/discounts/discounts.types';

import { DISCOUNT_FRAGMENT } from '../fragments/discount.fragments';

export const discountGroups = (vars: DiscountQueryVars): string => {
  return `
    discountsConnection(
      orderBy: ${vars.orderBy}
      first: ${vars.first}
      skip: ${vars.skip}
      where: {
        deletedAt: null
        channels_some: {
          channel: Website
        }
        ${GraphQLHelpers.addFilter(!vars.displayActive, 'isActive', true)}
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        AND: [${GraphQLHelpers.searchableContainsValues(vars.search)}]
      }
    ) {
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
      edges {
        node {
          ...DiscountFragment
        }
      }
    }
  `;
};

export const SINGLE_DISCOUNT = gql`
  query SingleDiscount($where: DiscountWhereUniqueInput!) {
    discount(where: $where) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const DISCOUNT_ANALYTICS = gql`
  query DiscountAnalytics($where: DiscountWhereUniqueInput!) {
    discount(where: $where) {
      totalRevenue
      amountDiscounted
    }
  }
`;

export const DISCOUNTS = (vars: DiscountQueryVars): DocumentNode => gql`
  query Discounts ${vars.merchantId ? `(
    ${GraphQLHelpers.addVariable(Boolean(vars.merchantId), 'merchantId', 'ID!')}
  )` : ''} {
    ${discountGroups(vars)}
  }
  ${DISCOUNT_FRAGMENT}
`;
