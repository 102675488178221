import { PlanType, Currency } from 'generated-types';

export type PlanConfig = {
  [key in Currency]: {
    [featureKey in PlanType]: string[];
  };
};

export const ASSOCIATED_FEES = {
  Floom: {
    detail: 'Floom.com transaction fees',
    fee: '20% + stripe fee*'
  },
  Website: {
    detail: 'Custom website transation fees',
    fee: '0.99% + stripe fee*'
  },
  stripeWeb: {
    USD: 'https://stripe.com/pricing',
    GBP: 'https://stripe.com/gb/pricing'
  }
};
