import React, { Component, ReactNode } from 'react';

import GenericModal from 'components/generic-modal';
import GenericSelectList from 'components/generic-select-list';

import * as Styles from './field-item-selector.styles';
import * as Types from './field-item-selector.types';
import SelectedItemList from './selected-item-list';

export default class FieldItemSelector extends Component<Types.FieldItemSelectorProps, Types.FieldItemSelectorState> {
  static defaultProps: Partial<Types.FieldItemSelectorProps> = {
    isLoading: false,
    noResultsHeading: 'No items found',
    noResultsCopy: 'We couldn\'t find any items for you to select'
  };

  state: Types.FieldItemSelectorState = {
    modalOpen: false,
    modalSelectedItemIds: []
  };

  private openModal = (): void => {
    this.setState({
      modalOpen: true,
      modalSelectedItemIds: this.props.selectedItemIds.slice()
    });
  };

  private closeModal = (): void => {
    this.setState({
      modalOpen: false
    });
  };

  private toggleModalItem = (selectedID: string): void => {
    const selectedIds: Set<string> = new Set(this.state.modalSelectedItemIds);

    if (selectedIds.has(selectedID)) {
      selectedIds.delete(selectedID);
    } else {
      selectedIds.add(selectedID);
    }

    this.setState({
      modalSelectedItemIds: Array.from(selectedIds)
    });
  };

  private handleItemRemove = (itemId: string): void => {
    this.props.onItemRemove(itemId);
  };

  private submitModal = (): void => {
    this.props.setSelectedItemIds(this.state.modalSelectedItemIds);

    this.setState({
      modalOpen: false
    });
  };

  private renderModal = (): ReactNode => {
    return (
      <GenericModal
        width={430}
        confirmButtonAction={this.submitModal}
        confirmButtonText={this.props.addButtonCopy}
        title={this.props.modalHeadingCopy}
        modalOpen={this.state.modalOpen}
        closeModal={this.closeModal}
        confirmButtonDisabled={false}
        innerComponent={(
          <GenericSelectList
            listObjects={this.props.items}
            onSelect={this.toggleModalItem}
            multiSelect={this.props.isMultiSelect}
            noSearch={!this.props.hasSearch}
            alreadySelectedIds={this.state.modalSelectedItemIds}
          />
        )}
      />
    );
  };

  render(): JSX.Element {
    return (
      <Styles.Content>
        {this.renderModal()}
        <SelectedItemList
          isLoading={this.props.isLoading}
          hasItems={!!this.props.items.length}
          items={this.props.items.filter(item => this.props.selectedItemIds.includes(item.id))}
          openModal={this.openModal}
          onItemRemove={this.handleItemRemove}
          addButtonCopy={this.props.addButtonCopy}
          noResultsHeading={this.props.noResultsHeading}
          noResultsCopy={this.props.noResultsCopy}
        />
      </Styles.Content>
    );
  }
}
