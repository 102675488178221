import styled from '@emotion/styled';
import posed from 'react-pose';

import { colors, textStyles } from 'utils/rebass-theme';

import { TooltipXPosition, TooltipYPosition } from './tooltip.types';

export type TooltipXConfig = {
  [key in TooltipXPosition]: {
    xTranslate: string;
    arrowLeft: string;
  }
};

export type TooltipYConfig = {
  [key in TooltipYPosition]: {
    yTranslate: string;
    arrowBottom: string;
    arrowTop: string;
    arrowRotate: string;
  }
};

export interface TooltipProps {
  positionX: TooltipXPosition;
  positionY: TooltipYPosition;
  tooltipStyles: any;
}

export const TooltipTransition = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 100,
    transition: {
      duration: 250
    }
  },
  exit: {
    y: 5,
    opacity: 0,
    transition: {
      duration: 150
    }
  }
});

export const TooltipWrapper = styled.span`
  position: relative;
`;

const config: TooltipXConfig | TooltipYConfig = {
  left: {
    xTranslate: '-20px',
    arrowLeft: '21px'
  },
  right: {
    xTranslate: 'calc(-100% + 20px)',
    arrowLeft: 'calc(100% - 21px)'
  },
  center: {
    xTranslate: '-50%',
    arrowLeft: '50%'
  },
  top: {
    yTranslate: '-102%',
    arrowBottom: '1px',
    arrowTop: 'auto',
    arrowRotate: '0'
  },
  bottom: {
    yTranslate: '20px',
    arrowBottom: 'auto',
    arrowTop: '-8px',
    arrowRotate: '180deg'
  }
};

export const Tooltip: any = styled.span<TooltipProps>`
  min-width: 120px;
  max-width: 500px;
  position: absolute;
  z-index: 10;
  padding-bottom: 9px;
  transform: ${({ positionX, positionY }): string => {
    return `translate(${config[positionX].xTranslate}, ${config[positionY].yTranslate})`;
  }};

  &:after {
    content: '';
    position: absolute;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid ${colors.floomMidnightBlue};
    bottom: ${({ positionY }): string => config[positionY].arrowBottom};
    top: ${({ positionY }): string => config[positionY].arrowTop};
    left: ${({ positionX }): string => config[positionX].arrowLeft};
    transform: ${({ positionY }): string => {
    return `translateX(-50%) rotate(${config[positionY].arrowRotate})`;
  }};
  }

  ${({ tooltipStyles }): string => tooltipStyles}
`;

export const TooltipTrigger = styled.div`
  display: inline-block;
`;

export const TooltipMessage = styled.div`
  ${textStyles.label};
  text-transform: uppercase;
  background: ${colors.floomMidnightBlue};
  border-radius: 3px;
  color: ${colors.uiFills};
  padding: 10px 20px 12px;
  text-align: center;
`;
