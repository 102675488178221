import React, { FC, Fragment, useEffect, useState } from 'react';

import { Location } from '@reach/router';
import { navigate } from 'gatsby';
import {
  DividerType,
  RouteType
} from 'global.types';
import { inject, observer } from 'mobx-react';

import { PlanFeature } from 'generated-types.d';
import { ROUTES } from 'global.constants';

import {
  Analytics,
  MerchantService
} from 'lib';

import { TEST_IDS } from 'utils/test/data-test-ids';

import usePageVisibility from 'hooks/usePageVisibility/usePageVisibility';

import { OrdersAPIService } from 'features/orders/services';

import Icon from 'components/icon';

import * as NavLinkStyles from '../nav-link/nav-link.styles';

import { NAV_COMPONENT } from './nav-list.constants';
import * as Styles from './nav-list.styles';
import * as Types from './nav-list.types';

export const NavList: FC<Types.NavListProps> = inject((stores: FxStores): InjectedFxStores => ({
  chatWidgetStore: stores.chatWidgetStore,
  uiStore: stores.uiStore,
  merchantStore: stores.merchantStore,
  conversationsStore: stores.conversationsStore,
  ordersStore: stores.ordersStore
}))(observer(({
  chatWidgetStore,
  uiStore,
  merchantStore,
  conversationsStore,
  ordersStore
}) => {
  const [routes, setRoutes] = useState<(RouteType | DividerType)[]>(ROUTES);

  const onRouteChange = (
    route: RouteType,
    isSubNav: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    section: string,
    url: string
  ): void => {
    const isMobile = uiStore!.currentBreakpoint === 'small';

    if (isMobile && (isSubNav || !route.children?.length)) {
      uiStore!.toggleMenu();
    }

    Analytics.track(Analytics.FxEventName.ClickNavigation, {
      event_version: 1,
      item: route.itemName,
      section: route.sectionName,
      is_sub_item: isSubNav
    });

    if (url.includes('http') || url.includes('www')) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  // for some reason all the Mobx binding are broken here and changes will not be observed.
  const overrideNavListItem = (item: any): RouteType | DividerType => {
    switch (item.identifier) {
      case 'custom-site':
        if (merchantStore) {
          return {
            ...item,
            url: merchantStore!.customSiteURLRaw,
            name: merchantStore!.customSiteUrlDisplay || 'Custom Website',
            isDisabled: merchantStore!.customSiteUrlDisplay,
            isExternal: merchantStore!.customSiteEditable
          };
        }

      case 'conversations':
        return {
          ...item,
          hasNotifications: conversationsStore!.hasUnreadMessages,
          notificationType: 'info'
        };

      case 'products':
        if (merchantStore) {
          const children = item.children.map((child: any) => {
            switch (child.id) {
              case 'products-create':
              case 'add-ons-list':
                return {
                  ...child,
                  isHidden: MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, merchantStore!.merchant!)
                };

              default:
                return child;
            }
          });

          return {
            ...item,
            children
          };
        }

      case 'orders':
        return {
          ...item,
          hasNotifications: ordersStore!.activePastordersCount > 0 && merchantStore!.merchant?.id,
          notificationType: 'warning'
        };

      default:
        return item;
    }
  };

  useEffect(() => {
    setRoutes(routes.map(item => overrideNavListItem(item)));
  }, [
    merchantStore!.customSiteURLRaw,
    merchantStore!.customSiteUrlDisplay,
    merchantStore!.customSiteEditable,
    conversationsStore!.hasUnreadMessages,
    ordersStore!.activePastordersCount
  ]);

  useEffect(() => {
    conversationsStore?.fetchContacts(merchantStore?.merchant?.id);
  }, []);

  const isPageVisible = usePageVisibility();

  useEffect(() => {
    let refreshInterval:  NodeJS.Timeout | null = null;

    if (isPageVisible) {
      OrdersAPIService.fetchActiveOrdersCount(merchantStore?.merchant);

      refreshInterval = setInterval(() => {
        OrdersAPIService.fetchActiveOrdersCount(merchantStore?.merchant);
      }, 1 * 60 * 1000);
    }

    return () => {
      if (refreshInterval) clearInterval(refreshInterval);
    };
  }, [isPageVisible]);

  const renderSupportChatNavItem = (): React.ReactNode | null => {
    return (
      <Fragment key="support-chat-item">
        <NavLinkStyles.NavLinkParent
          id="support-chat"
          data-testid={TEST_IDS.Menu.supportChat}
          key="support-chat"
          title="Support Chat"
          onClick={(): any => chatWidgetStore!.enableChatWidget()}
          css={chatWidgetStore!.isChatWidgetOpen ? NavLinkStyles.NavLinkToggleActive : null}
        >
          <Icon
            iconName="account"
            styles={NavLinkStyles.Icon}
          />
          <NavLinkStyles.NavLinkText>Support Chat</NavLinkStyles.NavLinkText>
        </NavLinkStyles.NavLinkParent>
      </Fragment>
    );
  };

  return (
    <Location>
      {({ location }): JSX.Element => (
        <Styles.NavList data-testid={TEST_IDS.Menu.menuList}>
          {ROUTES.map((item: RouteType | DividerType, i): React.ReactNode => (
            <Fragment key={i}>
              {
                NAV_COMPONENT![item.type]({
                  route: overrideNavListItem(item),
                  location: location.pathname,
                  onRouteChange: onRouteChange
                })
              }
            </Fragment>
          )).concat([renderSupportChatNavItem()])}
        </Styles.NavList>
      )}
    </Location>
  );
}));
