import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import {
  mq
} from 'theme';

import {
  colors,
  borderRadius,
  textStyles,
  fontStacks
} from 'utils/rebass-theme';

export const Wrapper = styled(Flex)`
  border-radius: ${borderRadius.xLarge};
  background-color: ${colors.uiFills};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: transform .2s ease-in-out;
  height: 100%;
  flex-direction: column;

  ${mq(640)} {
    flex-direction: row;
    align-items: flex-start;
    border-radius: 0;
    box-shadow: none;
  };

  &:hover {
    transform: translateY(-2px);

    ${mq(640)} {
      transform: unset;
    };
  }
`;

export const Image: any = styled.div`
  ${mq(640)} {
    position: relative;
    background-size: cover;
    background-position: center;
    background-color: ${colors.middleGrey};
    transition: opacity .2s ease-in-out;
    opacity: 0;
    z-index: 2;
    flex: 0 0 80px;
    padding-top: 0;
    min-height: auto;
    height: 80px;
    margin: 10px;
    border-radius: 8px;
  };

  &.lazyloaded {
    opacity: 1;
  }
`;

export const ImageDesktop: any = styled.div`
  padding-top: 50%;
  min-height: 140px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-color: ${colors.middleGrey};
  transition: opacity .2s ease-in-out;
  opacity: 0;
  z-index: 2;

  ${mq(1300)} {
    min-height: 120px;
  };

  ${mq(640)} {
    display: none;
  };

  &.lazyloaded {
    opacity: 1;
  }
`;

export const ImageContainer = styled(Box)`
  position: relative;
  display: block;

  ${mq(640)} {
    display: none;
  };
`;

export const LozengeContainerDesktop = styled(Box)`
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 10px;
`;

export const LozengeContainerMobile = styled.div`
  display: none;

  ${mq(640)} {
    display: block;
  };
`;

export const Content = styled(Flex)`
  padding: 12px 12px 14px;
  flex: 1 1 100%;
  flex-direction: column;

  ${mq(640)} {
    flex: 1;
    padding-left: 4px;
  };
`;

export const Metadata = styled(Flex)`
  margin-top: auto;
  align-items: center;
`;

export const PriceWrapper = styled(Flex)`
  align-items: flex-end;
`;

export const Price = styled(Flex)`
  ${textStyles.body};
  margin-left: 5px;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.floomMidnightBlue};
  line-height: 1;
`;

export const PricePrefix = styled(Box)`
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colors.middleGrey};
`;

export const Title: any = styled.h4`
  ${textStyles.h3};
  font-size: 16px;

  ${mq(640)} {
    font-size: 15px;
  };
`;

export const SubTitle = styled.h6`
  line-height: 1.5;
  font-weight: 600;
  margin-top: 1px;
  font-family: ${fontStacks.primary};
  font-size: 13px;
  color: ${colors.middleGrey};

  ${mq(640)} {
    margin-top: 1px;
  };
`;
