import { FeatureListConfigItem } from 'components/feature-list/feature-list.types';

export const PAYMENT_DETAILS_COPY: FeatureListConfigItem[] = [
  {
    icon: 'grid',
    copy: 'FloomX Plan subscription payments.'
  },
  {
    icon: 'wholesale',
    copy: 'Wholesale shop purchases (only if you subscribe to use our wholesale shop)'
  },
  {
    icon: 'delivery-succeeded',
    copy: 'FloomX Courier charges (only if you opt-in to use our courier)'
  }
];
