import { css, keyframes } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  borderRadius,
  colors,
  gridGutter,
  containerWidth,
  shadows,
  breakpoints,
  space
} from 'utils/rebass-theme';

import { ThemeBreakpointOption } from '../rebass-theme/rebass-theme.types';

export const mq = {
  between: (smallKey: ThemeBreakpointOption, largeKey: ThemeBreakpointOption): string => {
    return `@media (min-width: ${breakpoints[smallKey]}) and (max-width: ${breakpoints[largeKey]})`;
  },
  greaterThan: (key: ThemeBreakpointOption): string => {
    return `@media (min-width: ${breakpoints[key]})`;
  },
  lessThan: (key: ThemeBreakpointOption): string => {
    return `@media (max-width: ${breakpoints[key]})`;
  }
};

export const AbsoluteCover = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const BodyLink = css`
  text-decoration: underline;
  font-weight: bold;
`;

export const Container: any = styled(Box)`
  margin: 0 auto;
  width: 100%;
  padding: 0 ${gridGutter}px;
  max-width: ${(props: any): any => props.maxWidth || `${containerWidth}px`};

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding: 0 ${space[2]}px;
  }
`;

export const Card = css`
  box-shadow: ${shadows.small};
  background: ${colors.uiFills};
  border-radius: ${borderRadius.medium};
`;

export const CardButton = styled(Flex)`
  ${Card};
  border-bottom: 5px solid ${({ isActive }: any): any => isActive ? colors.floomMidnightBlue : colors.middleGrey};
`;

export const ShakeKeyframe = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const ShakeIt = css`
  animation: ${ShakeKeyframe} 0.52s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
`;

export const PulseKeyframes = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
`;

export const PulseAnimation = css`
  animation: ${PulseKeyframes} 2s infinite ease-in-out;
`;

export const LogoutButtonIcon: StyledComponent<any, any, any> = styled(Box)`
  box-sizing: border-box;
  background: ${colors.pink};
  width: ${({ size }: any): any => size ? size : '30px'};
  height: ${({ size }: any): any => size ? size : '30px'};
  text-align: center;
  white-space: nowrap;
  font-size: ${({ fontSize }: any): any => fontSize ? fontSize : '11px'};
  padding-top: 10px;
  border-radius: 100%;
  font-weight: bold;
  color: ${colors.floomMidnightBlue};
  text-transform: uppercase;
  cursor: pointer;
`;

export const ActivityToggle = styled.span<{ isActive: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 100px;
  margin-left: 5px;
  background: ${({ isActive }): string => isActive ? colors.emeral : colors.middleGrey};
`;

export const ColourDot: any = styled.span<{
  hex?: string;
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: ${(props): string => props.hex ? props.hex : 'transparent'};
  border: 1px solid ${(props): string => !!props.hex?.toLowerCase?.()?.includes('fff') ? colors.middleGrey : 'transparent'};
`;
