import { FC, useMemo } from 'react';

import moment from 'moment';
import { Box } from 'rebass';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { ORDER_TYPEFORM } from 'features/orders/orders.constants';
import { ExtendedOrder } from 'features/orders/orders.types';

import { TypeformButton } from 'components/typeform';

interface OrderHelpProps {
  order: ExtendedOrder;
}

const OrderHelp: FC<OrderHelpProps> = ({
  order
}) => {
  const {
    flags: {
      orderHelp
    }
  } = useFeatureFlags();

  const typeformConfigs = useMemo(() => {
    const orderDetails = {
      order_no: order.orderNo?.slice(0, 7),
      merchant: order.merchant?.title,
      deliver_at: moment(order.deliverAt).format('DD/MM/YYYY'),
      ordered_at: moment(order.orderedAt).format('DD/MM/YYYY - h:mma'),
      customer_name: order.billingAddress?.recipientFullName || '',
      customer_email: order.customerUser?.email || '',
      customer_telephone: order.billingAddress?.phone || ''
    };

    return ORDER_TYPEFORM.map(config => {
      return {
        ...config,
        hiddenFields: { ...orderDetails }
      };
    });
  }, [order]);

  return orderHelp.isActive ? (
    <Box
      mt="auto"
      bg="lightGrey"
      p="30px"
      style={{ position: 'sticky', bottom: '0' }}
    >
      <TypeformButton
        configs={typeformConfigs}
        buttonCopy='Report issue'
        modalCopy='Report issue'
      />
    </Box>
  ) : null;
};

export default OrderHelp;
