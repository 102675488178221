import { Component } from 'react';

import { PoseGroup } from 'react-pose';

import * as Styles from './tooltip.styles';
import * as Types from './tooltip.types';

export default class Tooltip extends Component<Types.TooltipProps, Types.TooltipState> {
  // Determine when tooltip should be positioned away from the bounds of the
  // Browser window to avoid it overflowing out of the browser frame.
  windowXBoundary = 100;

  windowYBoundary = 200;

  static defaultProps = {
    styles: null,
    tooltipStyles: null
  };

  state: Types.TooltipState = {
    isVisible: false,
    elementTop: 0,
    elementCenter: 0,
    tooltipPositionX: 'center',
    tooltipPositionY: 'top'
  };

  private setXPosition = (rect: ClientRect): Types.TooltipXPosition => {
    if ((window.innerWidth - (rect.left + rect.width)) < this.windowXBoundary) {
      return 'right';
    } else if ((0 + rect.left) < this.windowXBoundary) {
      return 'left';
    }

    return 'center';
  };

  private setYPosition = (rect: ClientRect): Types.TooltipYPosition => {
    if (rect.top < this.windowYBoundary) {
      return 'bottom';
    }

    return 'top';
  };

  private enableTooltip = (event: any): void => {
    if (!this.props.message) return;

    const rect: ClientRect = event.target.getBoundingClientRect();
    const positionY = this.setYPosition(rect);

    this.setState({
      isVisible: true,
      elementTop: positionY === 'bottom' ? rect.bottom : rect.top,
      elementCenter: rect.left + (rect.width / 2),
      tooltipPositionX: this.setXPosition(rect),
      tooltipPositionY: positionY
    });
  };

  private disableTooltip = (): void => {
    this.setState({
      isVisible: false
    });
  };

  render(): JSX.Element {
    return (
      <Styles.TooltipWrapper onMouseLeave={this.disableTooltip}>
        <div
          style={{
            top: this.state.elementTop,
            left: this.state.elementCenter,
            position: 'fixed'
          }}
        >
          <PoseGroup>
            { this.state.isVisible && [
              <Styles.TooltipTransition key="tooltip">
                <Styles.Tooltip
                  positionX={this.state.tooltipPositionX}
                  positionY={this.state.tooltipPositionY}
                  tooltipStyles={this.props.tooltipStyles}
                >
                  <Styles.TooltipMessage>
                    {this.props.message || ''}
                  </Styles.TooltipMessage>
                </Styles.Tooltip>
              </Styles.TooltipTransition>
            ]}
          </PoseGroup>
        </div>
        <Styles.TooltipTrigger
          onMouseMove={this.enableTooltip}
          css={this.props.styles}
        >
          {this.props.children}
        </Styles.TooltipTrigger>
      </Styles.TooltipWrapper>
    );
  }
}
