import { FC } from 'react';

import css from '@emotion/css';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import hex2rgba from 'hex2rgba';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Currency } from 'generated-types.d';

import { PermissionsService } from 'lib';

import { colors } from 'utils/rebass-theme';

import BannerImageDesktop from 'assets/images/wholesale/banner-desktop.png';

import Button from 'components/button';
import Icon from 'components/icon';

import * as Styles from './wholesale-banner.styles';
import * as Types from './wholesale-banner.types';

type CopyForFeatures = {
  title: string;
  ctaPrimary: {
    label: string;
    link: NavPages;
  };
  ctaSecondary?: {
    label: string;
    link: NavPages;
  };
  listItems: string[];
};

export const WholesaleBanner: FC<Types.WholesaleBannerProps> = inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(({
  merchantStore
}) => {
  const shouldDisplayBanner = (): boolean => {
    return (PermissionsService.isInternalRole() || !!merchantStore!.merchant?.wholesaleDeliveryConfigs);
  };

  if (!shouldDisplayBanner() || !merchantStore!.merchant) return null;

  const copy = ((): CopyForFeatures | null => {
    switch (true) {
      case (merchantStore!.merchant?.currency === Currency.Usd && merchantStore!.merchant?.promotedListsActive): {
        return {
          title: 'Discover Wholesale Special Offers',
          ctaPrimary: {
            label: 'Browse Special Offers',
            link: NavPages.WholesaleSpecialOffers
          },
          listItems: [
            'Regularly updated offers from local suppliers',
            'Request flowers and we’ll source them for you!'
          ]
        };
      }

      case (merchantStore!.merchant?.currency === Currency.Gbp && merchantStore!.merchant?.promotedListsActive): {
        return {
          title: 'Discover Wholesale on Floom',
          ctaPrimary: {
            label: 'Wholesale Shop',
            link: NavPages.WholesaleShop
          },
          ctaSecondary: {
            label: 'Special Offers',
            link: NavPages.WholesaleSpecialOffers
          },
          listItems: [
            'Buy from 3000+ growers, from 50+ countries on Wholesale Shop',
            'Next day delivery available in selected locations',
            'Regularly updated offers from local suppliers',
            'Request flowers and we’ll source them for you!'
          ]
        };
      }

      case (merchantStore!.merchant?.currency === Currency.Gbp && !merchantStore!.merchant?.promotedListsActive): {
        return {
          title: 'Discover Floom’s Wholesale Shop',
          ctaPrimary: {
            label: 'Browse Wholesale Shop',
            link: NavPages.WholesaleShop
          },
          listItems: [
            'Buy from 3000+ growers, from 50+ countries on Wholesale Shop',
            'Next day delivery available in selected locations',
            'Over 10000+ products available (flowers, plants, sundries)'
          ]
        };
      }

      default: {
        return null;
      }
    }
  })();

  if (!copy) {
    return null;
  }

  return (
    <Styles.Container>
      <Styles.BlobLeft
        fill={hex2rgba(colors.floomCyan, 1)}
      />
      <Styles.BlobRight
        fill={hex2rgba(colors.floomOrange, 1)}
      />
      <Styles.Content>
        <Styles.ContentLeft>
          <Styles.Title>
            <h3>
              {copy.title}
            </h3>
          </Styles.Title>
          <Styles.List>
            {copy.listItems.map((listItem, index) => {
              return (
                <Styles.ListItem key={index}>
                  <Icon
                    iconName="tick-circle"
                    pathFill={colors.floomMidnightBlue}
                    size="normal"
                    styles={css`
                      min-width: 24px
                    `}
                  />
                  <Box
                    ml={2}
                    mt="-2px"
                  >
                    {listItem}
                  </Box>
                </Styles.ListItem>
              );
            })}
          </Styles.List>
          <Styles.ButtonsWrapper>
            <Styles.ButtonContainer>
              <Link
                to={copy.ctaPrimary.link}
                aria-label={copy.ctaPrimary.label}
                css={css`
                  width: 100%;
                `}
              >
                <Button
                  size="normal"
                  copy={copy.ctaPrimary.label}
                  isParentWidth={true}
                  appearance="primary"
                />
              </Link>
            </Styles.ButtonContainer>
            {!!copy.ctaSecondary && (
              <Styles.ButtonContainer>
                <Link
                  to={copy.ctaSecondary.link}
                  aria-label={copy.ctaSecondary.label}
                  css={css`
                    width: 100%;
                  `}
                >
                  <Button
                    size="normal"
                    copy={copy.ctaSecondary.label}
                    isParentWidth={true}
                    appearance="secondary"
                  />
                </Link>
              </Styles.ButtonContainer>
            )}
          </Styles.ButtonsWrapper>
        </Styles.ContentLeft>
        <Styles.ImageDesktop src={BannerImageDesktop} />
      </Styles.Content>
    </Styles.Container>
  );
}));
