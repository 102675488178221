import React, { ReactNode } from 'react';

import {
  StateResultsProvided,
  connectStateResults
} from 'react-instantsearch-core';

import { OrdersListLayout } from 'stores/orders/orders-store.types';

import { ORDER_GROUP_OPTIONS } from 'features/orders/graphql/helpers/order-group.config';
import { OrderGroupOption } from 'features/orders/orders.types';

import SectionHeading from 'components/section-heading';

import { OrderListPageInfo } from './order-list-page-info';

interface OrderSectionHeadingProps extends StateResultsProvided {
  groupName: OrderGroupOption;
  layoutOption: OrdersListLayout;
  shouldRefresh: boolean;
}

const shouldDisplayHeading = (groupName: OrderGroupOption): boolean => {
  return !!ORDER_GROUP_OPTIONS?.[groupName]?.title;
};

export const OrderSectionHeading = connectStateResults(({
  groupName,
  searchResults
}: OrderSectionHeadingProps): JSX.Element | null => {
  if (!shouldDisplayHeading(groupName) || !searchResults?.nbHits) return null;

  return (
    <SectionHeading
      title={ORDER_GROUP_OPTIONS[groupName].title}
      count={searchResults?.nbHits || 0}
      renderMetadata={(): ReactNode => {
        return (
          <OrderListPageInfo />
        );
      }}
    />
  );
});
