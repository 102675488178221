import camelCase from 'lodash.camelcase';

import { GraphQLHelpers } from 'lib';

import { ProductGroupOption } from 'stores/products-store';

import { STOCK_VALUES } from 'features/products/products.constants';

const addFilter = (shouldAdd: boolean, filterName: string, value: string): string => {
  if (!shouldAdd) return '';

  return `${filterName}: ${value}`;
};

export const addRootQueryParams = (vars: any): string => {
  return `
    $orderBy: ProductOrderByInput
    ${GraphQLHelpers.addVariable(vars.selectedTypes.length, 'selectedTypes', '[String!]')}
    ${GraphQLHelpers.addVariable(vars.selectedCategories.length, 'selectedCategories', '[String!]')}
    ${GraphQLHelpers.addVariable(vars.selectedChannels.length, 'selectedChannels', '[Channel!]')}
    ${GraphQLHelpers.addVariable(vars.showActiveOnly, 'showActiveOnly', 'Boolean!')}
    ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
  `;
};

export const addRootArguments = (vars: any): string => {
  return `
    orderBy: $orderBy
    first: ${vars.first}
  `;
};

export const addTypeWhereFilter = (types: string[]): string => {
  if (!types.length) return '';

  return `
    type_every: {
      slug_in: $selectedTypes
    }
  `;
};

export const addChannelWhereFilter = (channels: string[]): string => {
  if (!channels.length) return '';

  return `
    channels_some: {
      channel_in: $selectedChannels
    }
  `;
};

export const addCategoryWhereFilter = (categories: string[]): string => {
  if (!categories.length) return '';

  return `
    categories_some: {
      slug_in: $selectedCategories
    }
  `;
};

export const addRootWhereArguments = (vars: any): string => {
  return `
    ${vars.showActiveOnly && vars.isCollectionMerchant ? `
      collectionSellerProductConfigs_some: {
        active: $showActiveOnly,
        merchant: {
          id: "${vars.merchantId}"
        }
      }
    ` : ''}
    ${addFilter(vars.showActiveOnly && !vars.isCollectionMerchant, 'active', '$showActiveOnly')}
  `;
};

export const buildProductGroupQuery = (type: ProductGroupOption, groups: string[], vars: any): string => {
  return groups.reduce((acc: string, currGroup: string) => {
    return `
      ${acc}
      ${camelCase(currGroup)}: productsConnection(
        ${addRootArguments(vars)}
        where: {
          ${addRootWhereArguments(vars)}
          ${addTypeWhereFilter(vars.selectedTypes)}
          ${buildGroupFilters(currGroup, type, vars)}
          ${addCategoryWhereFilter(vars.selectedCategories)}
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        }
      ) {
        pageInfo {
          hasNextPage
        }
        aggregate {
          count
        }
        edges {
          node {
            ...ProductListFragment
            collectionSellerProductConfigs(
              first: 1
              where: {
                ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
              }
            ) {
              id
              active
              productVariationConfigs {
                id
                stock
                sourceProductVariation {
                  id
                  type
                }
              }
            }
          }
        }
      }
    `;
  }, '');
};

export const buildGroupFilters = (filter: any, type: ProductGroupOption, vars: any): string => {
  switch (type) {
    case 'stock':
      return buildStockGroupFilter(filter, vars);

    default:
      return `${GraphQLHelpers.searchableContains(vars.search)}`;
  }
};

export const buildStockGroupFilter = (filter: string, vars: any): string => {
  switch (camelCase(filter)) {
    case 'outOfStock':
      return `
        variations_some: {
          stock: ${STOCK_VALUES.outOfStock}
          default: true
        }
        ${GraphQLHelpers.searchableContains(vars.search)}
      `;

    case 'lowStock':
      return `
        AND: [
          {
            variations_some: {
              stock_gt: ${STOCK_VALUES.outOfStock}
              default: true
            }
          }
          {
            variations_some: {
              stock_lte: ${STOCK_VALUES.lowStockMax}
              default: true
            }
          }
          ${GraphQLHelpers.searchableContainsValues(vars.search)}
        ]
      `;

    case 'highStock':
      return `
        variations_some: {
          stock_gt: ${STOCK_VALUES.lowStockMax}
          default: true
        }
        ${GraphQLHelpers.searchableContains(vars.search)}
      `;

    default:
      return '';
  }
};
