import {
  useEffect,
  useState
} from 'react';

export const useWindowScroll = (): number => {
  const [scrollPosition, setScrollPosition] = useState<number>(window.scrollY);

  const handleScroll = (): void => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPosition;
};
